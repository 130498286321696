import { Alert, Typography } from "@mui/material";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext/KcMessagesContext";
import { KcAlertProps } from "./KcAlert.d";

export default function KcAlert(props: KcAlertProps) {
  const {
    kcContext,
    children,
    message,
    titleClassName,
    typographyProps,
    ...others
  } = props;
  const { kcSanitize } = useKcMessagesContext();

  const getColor = (type: string) => {
    switch (type) {
      case "error":
        return "cobalt.red";
      case "warning":
        return "cobalt.orange";
      case "success":
        return "cobalt.green";
      case "info":
        return "cobalt.dodgerBlue";
      default:
        return "";
    }
  };

  if (!message?.type || !message?.summary) {
    return <></>;
  }

  if (
    kcContext.pageId === "delete-account-confirm.ftl" &&
    message.type !== "warning"
  ) {
    return <></>;
  }

  const summary = message.summary.replaceAll(/{[0-9]+}/g, "");

  switch (kcContext.pageId) {
    case "info.ftl":
    case "error.ftl":
      return <></>;
    default:
      return (
        <Alert
          severity={message.type}
          elevation={2}
          {...others}
          sx={{
            width: "100%",
            backgroundColor: `${getColor(message.type)}Background`,
            ...props.sx,
          }}
        >
          {children ? (
            children
          ) : (
            <Typography
              className={titleClassName}
              ml={2}
              color={`${getColor(message.type)}Text`}
              sx={{ overflow: "hidden", ...typographyProps?.sx }}
              {...typographyProps}
            >
              {kcSanitize(summary) || summary}
            </Typography>
          )}
        </Alert>
      );
  }
}
