import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { InputAdornment } from "@mui/material";
import React from "react";
import { MainTextField } from "..";
import { PasswordTextFieldProps } from "./PasswordTextField.d";

export default function PasswordTextField(props: PasswordTextFieldProps) {
  const { disabledEye, ...others } = props;
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);

  const togglePasswordHidden = () => setIsPasswordHidden(!isPasswordHidden);

  return (
    <MainTextField
      InputProps={
        disabledEye
          ? {}
          : {
              endAdornment: (
                <InputAdornment position="end">
                  <CobaltIcon
                    name={isPasswordHidden ? "eye" : "eye-off"}
                    sx={{ cursor: "pointer" }}
                    onClick={togglePasswordHidden}
                  />
                </InputAdornment>
              ),
            }
      }
      type={isPasswordHidden ? "password" : "text"}
      {...others}
    />
  );
}
