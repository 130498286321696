import React from "react";
import { RegisterDynamicUsernameProps } from "./RegisterDynamicUsername.d";
import { Box, Stack } from "@mui/material";
import { isNotBlankString, onSubmit } from "../../scripts";
import { useForm } from "react-hook-form";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import {
  useFormContext,
  useComponentsContext,
  useKcMessagesContext,
} from "../../contexts";

const RegisterDynamicUsername = React.memo(
  ({ kcContext, kcProps }: RegisterDynamicUsernameProps) => {
    const { url, passwordRequired, email, lastName, firstName, mobile_number } =
      kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const { getTextField, getPasswordTextField, getSubmitButton, getLink } =
      useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      control,
      trigger,
      formState: { isValid },
      watch,
    } = useForm(getFormProps());

    const watchEmail = watch("email");
    const watchPhoneNumber = watch("mobile_number");

    // if (messagesPerField.exists("mobile_number")) {
    //   Snippet to register the custom phone number field
    // }

    const validateDependentField = (
      value: string,
      dependencyValue: string
    ): string | undefined => {
      if (isNotBlankString(value) || isNotBlankString(dependencyValue))
        return undefined;
      return msgStr("requiredFields");
    };

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-form-register"
      >
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          <Box id="kc-form-register-firstname-wrapper">
            {getTextField({
              name: "firstName",
              id: "kc-form-register-firstname",
              control: control,
              rules: GetRules("firstName"),
              defaultValue: firstName,
            })}
          </Box>
          <Box id="kc-form-register-lastname-wrapper">
            {getTextField({
              name: "lastName",
              id: "kc-form-register-lastname",
              control: control,
              rules: GetRules("lastName"),
              defaultValue: lastName,
            })}
          </Box>
          <Box id="kc-form-register-email-wrapper">
            {getTextField({
              name: "email",
              id: "kc-form-register-email",
              control: control,
              rules: {
                ...GetRules("email"),
                required: "",
                validate: (value: string) =>
                  validateDependentField(value, watchPhoneNumber),
              },
              defaultValue: email,
            })}
          </Box>
          <Box id="kc-form-register-mobile_number-wrapper">
            {getTextField({
              name: "mobile_number",
              id: "mobile_number",
              control: control,
              rules: {
                ...GetRules("mobile_number"),
                required: "",
                validate: (value: string) =>
                  validateDependentField(value, watchEmail),
              },
              defaultValue: mobile_number,
            })}
          </Box>
          {passwordRequired && (
            <Box id="kc-form-register-password-wrapper">
              {getPasswordTextField({
                name: "password",
                id: "kc-form-register-password",
                control: control,
                rules: GetRules("password"),
              })}
            </Box>
          )}
          {passwordRequired && (
            <Box id="kc-form-register-password-confirm-wrapper">
              {getPasswordTextField({
                name: "password-confirm",
                id: "kc-form-register-password-confirm",
                control: control,
                rules: GetRules("password"),
                displayname: msgStr("passwordConfirm"),
              })}
            </Box>
          )}
          <Box id="kc-form-register-return-link-wrapper">
            {getLink({
              href: url.loginUrl,
              id: "kc-back-to-login",
              children: msg("backToLogin"),
            })}
          </Box>
          <Box className={getClassName("kcFormGroupClass")}>
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doRegister"),
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default RegisterDynamicUsername;
