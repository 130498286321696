import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "@docaposte-agility/da-design-system";
import { WaitingScreen } from "../../components/common";
import {
  ComponentsContextProvider,
  DynamicContextProvider,
  JsonContextProvider,
  KcUseFormContextProvider,
  KcAttributesContextProvider,
  KcMessagesContextProvider,
  KcLanguagesContextProvider,
} from "..";
import { ContextProviderProps } from "./ContextProvider.d";

export const ContextProvider = (props: ContextProviderProps) => {
  const {
    children,
    kcContext,
    customJson,
    customForm,
    customAttributes,
    i18n,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      {kcContext ? (
        <JsonContextProvider customJson={customJson} kcContext={kcContext}>
          <KcAttributesContextProvider
            kcContext={kcContext}
            customKeys={customAttributes}
          >
            <KcMessagesContextProvider i18n={i18n}>
              <KcLanguagesContextProvider i18n={i18n}>
                <DynamicContextProvider>
                  <ComponentsContextProvider>
                    <KcUseFormContextProvider customForm={customForm}>
                      <CssBaseline />
                      {children}
                    </KcUseFormContextProvider>
                  </ComponentsContextProvider>
                </DynamicContextProvider>
              </KcLanguagesContextProvider>
            </KcMessagesContextProvider>
          </KcAttributesContextProvider>
        </JsonContextProvider>
      ) : (
        <WaitingScreen />
      )}
    </ThemeProvider>
  );
};

export default ContextProvider;
