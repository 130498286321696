import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginUpdatePasswordProps } from "./LoginUpdatePassword.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginUpdatePassword = memo(
  ({ kcContext, kcProps }: LoginUpdatePasswordProps) => {
    const { url, isAppInitiatedAction, messagesPerField } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const {
      getSwitch,
      getSubmitButton,
      getPasswordTextField,
      getTypography,
      getSecondaryButton,
    } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      register,
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    if (messagesPerField.exists("password-new")) {
      // Register "password-new" as a field name in messagesPerField funtions
    }

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) =>
          onSubmit(event, isValid, trigger, isAppInitiatedAction)
        }
        method="post"
        id="kc-form-login"
      >
        <Stack spacing={3} className={getClassName("kcFormClass")}>
          <Box id="kc-password-new-wrapper">
            {getPasswordTextField({
              name: "password-new",
              id: "kc-form-update-password-new",
              control: control,
              rules: GetRules("password"),
              displayname: msgStr("passwordNew"),
            })}
          </Box>
          <Box id="kc-password-confirm-wrapper">
            {getPasswordTextField({
              name: "password-confirm",
              id: "kc-form-update-password-confirm",
              control: control,
              rules: GetRules("password"),
              displayname: msgStr("passwordNewConfirm"),
            })}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            id="kc-login-switch-container"
          >
            {getSwitch({
              ...register("logout-sessions"),
              defaultChecked: true,
            })}
            {getTypography({
              sx: { marginLeft: 2 },
              children: msg("logoutOtherSessions"),
            })}
          </Box>
          <Stack
            sx={{ mt: 1, width: "100%" }}
            className={getClassName("kcFormGroupClass")}
            id="kc-form-buttons"
          >
            <Box
              display="flex"
              justifyContent="center"
              id="kc-login-submit-button-container"
            >
              {getSubmitButton({
                kcProps: kcProps,
                message: msgStr("doSubmit"),
              })}
            </Box>
            {isAppInitiatedAction && (
              <Box
                display="flex"
                justifyContent="center"
                mt={1}
                id="kc-login-secondary-button-container"
              >
                {getSecondaryButton({
                  kcProps: kcProps,
                  name: "cancel-aia",
                  value: "true",
                  type: "submit",
                })}
              </Box>
            )}
          </Stack>
        </Stack>
      </form>
    );
  }
);

export default LoginUpdatePassword;
