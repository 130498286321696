import { useComponentsContext } from "../../../contexts/ComponentsContext/ComponentsContext";
import { ProviderButtonProps } from "./ProviderButton.d";

export default function ProviderButton(props: ProviderButtonProps) {
  const { getSecondaryButton } = useComponentsContext();
  return getSecondaryButton({
    variant: "outlined",
    fullWidth: false,
    ...props,
    sx: { minWidth: "150px", ...props.sx },
  });
}
