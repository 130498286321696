import React, { memo } from "react";
import { Box, Stack } from "@mui/material";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
} from "../../contexts";
import { IdpReviewUserProfileProps } from "./IdpReviewUserProfile.d";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import UserProfileCommons from "../UserProfileCommons";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const IdpReviewUserProfile = memo(
  ({ kcContext, kcProps }: IdpReviewUserProfileProps) => {
    const { url, profile } = kcContext;
    const { msgStr } = useKcMessagesContext();
    const { getSubmitButton } = useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const { getFormProps } = useFormContext();
    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    return (
      <form
        id="kc-idp-review-profile-form"
        className={getClassName("kcFormClass")}
        action={url.loginAction}
        method="post"
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
      >
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          <UserProfileCommons
            control={control}
            profile={profile}
            kcProps={kcProps}
          />

          <Box className={getClassName("kcFormGroupClass")}>
            <Box
              className={getClassName("kcFormOptionsClass")}
              id="kc-form-options"
            >
              <Box className={getClassName("kcFormOptionsWrapperClass")} />
            </Box>
          </Box>
          <Box
            className={getClassName("kcFormButtonsClass")}
            id="kc-form-buttons"
          >
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doSubmit"),
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default IdpReviewUserProfile;
