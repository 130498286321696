import { AppBar, Grid, Link } from "@mui/material";
import { memo } from "react";
import {
  useKcAttributesContext,
  useKcMessagesContext,
} from "../../../contexts";
import { footerKeys } from "../util";
import { FooterLink, FooterProps } from "./BasicFooter.d";

const FooterLinks = (props: { links: Array<FooterLink> }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      {props.links.map(
        ({ key, label, url }) =>
          url.length > 0 && (
            <Grid item key={key}>
              <Link href={url} underline="none" sx={{ color: "cobalt.lynch" }}>
                {label}
              </Link>
            </Grid>
          )
      )}
    </Grid>
  );
};

const Footer = memo((props: FooterProps) => {
  const { advancedMsgStr } = useKcMessagesContext();
  const { getAttributeValue } = useKcAttributesContext();

  const links: Array<FooterLink> = footerKeys.map((key) => ({
    key: key.label,
    label: advancedMsgStr(key.label),
    url: getAttributeValue(key.url) || "",
  }));

  const createImageElement = (side: string) => {
    const imageUrl = getAttributeValue(`footer.logo_${side}.img`);
    const imageAlt =
      getAttributeValue(`footer.logo_${side}.alt`) ??
      advancedMsgStr(`footer.logo_${side}.alt`);
    const logoUrl = getAttributeValue(`footer.logo_${side}.url`);

    if (imageUrl) {
      return logoUrl ? (
        <Link href={logoUrl}>
          <img src={imageUrl} alt={imageAlt} height="40px" />
        </Link>
      ) : (
        <img src={imageUrl} alt={imageAlt} height="40px" />
      );
    }
    return null;
  };

  return (
    <AppBar
      sx={{
        top: "auto",
        bottom: 0,
        padding: "12px",
        backgroundColor: "cobalt.grey10",
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        minHeight: "48px",
      }}
    >
      {createImageElement("left")}
      <FooterLinks links={links} />
      {createImageElement("right")}
    </AppBar>
  );
});

export default Footer;
