import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
} from "../../../contexts";
import { UserProfileFieldProps } from "./UserProfileField.d";

const UserProfileField = (props: UserProfileFieldProps) => {
  const { attribute, control, ...others } = props;

  const {
    getTextField,
    getProfileOptions,
    getUserProfileCheckboxs,
    getUserProfileRadios,
  } = useComponentsContext();
  const { getProfileRules } = useFormContext();
  const { advancedMsgStr } = useKcMessagesContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  switch (attribute?.annotations?.inputType || "") {
    case "select":
    case "multiselect":
      return getProfileOptions({
        id: attribute.name,
        name: attribute.name,
        className: getClassName("kcInputClass"),
        readOnly: attribute.readOnly,
        multiple: attribute?.annotations?.inputType === "multiselect",
        control: control,
        defaultValue: attribute.value || [],
        rules: getProfileRules(attribute) || {},
        attribute: attribute,
        kcProps: others,
        options: attribute.validators?.options?.options || [],
        requiredChar: "*",
      });
    case "select-radiobuttons":
      return getUserProfileRadios({ attribute: attribute, control: control });
    case "multiselect-checkboxes":
      return getUserProfileCheckboxs({
        attribute: attribute,
        control: control,
      });
    case "textarea":
    default:
      return getTextField({
        id: attribute.name,
        name: attribute.name,
        displayname:
          advancedMsgStr(attribute.displayName || "") || attribute.displayName,
        defaultValue: attribute.value,
        className: getClassName("kcInputClass"),
        inputProps: {
          readOnly: attribute.readOnly,
        },
        autoComplete: attribute.autocomplete,
        control: control,
        rules: getProfileRules(attribute),
        multiline: attribute?.annotations?.inputType === "textarea",
        rows: attribute?.annotations?.inputTypeSize,
      });
  }
};

export default UserProfileField;
