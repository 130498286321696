import { Box, Link } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { FlowResetButtonProps } from "./FlowResetButton.d";

const FlowResetButton = ({ url }: FlowResetButtonProps) => {
  return (
    <Box width="fit-content">
      <Link href={url} style={{ textDecoration: "none" }}>
        <CobaltIcon
          name="arrow-left"
          sx={{ color: "cobalt.ultramarine", cursor: "pointer" }}
        />
      </Link>
    </Box>
  );
};

export default FlowResetButton;
