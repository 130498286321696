import React from "react";
import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import { OtpSelectorProps } from "./OtpSelector.d";
import { useComponentsContext } from "../../../contexts";

export default function OtpSelector(props: OtpSelectorProps) {
  const { otpLogin, value, handleChange, formLabelProps, ...others } = props;

  const { getOtpSelectorItem } = useComponentsContext();

  return (
    <FormGroup onChange={handleChange} {...others}>
      <Grid container spacing={1} justifyContent="center">
        {otpLogin.userOtpCredentials.map((otpCredential, index) => (
          <Grid item key={otpCredential.id}>
            <FormControlLabel
              key={otpCredential.id}
              labelPlacement="top"
              checked={otpCredential.id === value}
              value={otpCredential.id}
              control={
                <>
                  {getOtpSelectorItem({
                    selectedValue: value,
                    value: otpCredential.id,
                    label: otpCredential.userLabel,
                  })}
                  <input
                    type="radio"
                    hidden
                    id={`kc-otp-credential-${index}`}
                    name="selectedCredentialId"
                    value={otpCredential.id}
                  />
                </>
              }
              label=""
              {...(formLabelProps && formLabelProps(otpCredential, index))}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}
