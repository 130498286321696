import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { UpdateUserProfileProps } from "./UpdateUserProfile.d";
import UserProfileCommons from "../UserProfileCommons";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const UpdateUserProfile = memo(
  ({ kcContext, ...props }: UpdateUserProfileProps) => {
    const { url, isAppInitiatedAction, profile } = kcContext;
    const { msgStr, msg } = useKcMessagesContext();
    const { getSubmitButton, getSecondaryButton } = useComponentsContext();
    const { getFormProps } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-totp-settings-form"
        className={getClassName("kcFormClass")}
      >
        <Stack spacing={2} id="user-profile-update-container">
          <UserProfileCommons profile={profile} control={control} {...props} />
          <Box
            id="kc-form-options"
            className={getClassName("kcFormOptionsClass")}
          >
            <Box className={getClassName("kcFormOptionsWrapperClass")} />
          </Box>
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormButtonsClass")}
            width={"100%"}
            display="flex"
            justifyContent="center"
          >
            {getSubmitButton({
              id: "kc-login",
              name: "login",
              value: msgStr("doLogIn"),
              kcProps: props,
            })}
            {isAppInitiatedAction && (
              <Box
                display="flex"
                justifyContent="center"
                mt={1}
                id="kc-login-secondary-button-container"
              >
                {getSecondaryButton({
                  kcProps: props,
                  message: msg("doCancel"),
                  name: "cancel-aia",
                  id: "cancel-update-user-profile",
                  onClick: () => (window.location.href = url.loginAction),
                })}
              </Box>
            )}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default UpdateUserProfile;
