import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { DeleteAccountConfirmProps } from "./DeleteAccountConfirm.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const DeleteAccountConfirm = memo(
  ({ kcContext, kcProps }: DeleteAccountConfirmProps) => {
    const { url, triggered_from_aia } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const { getSubmitButton, getSecondaryButton, getAlert, getTypography } =
      useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <form
        action={url.loginAction}
        method="post"
        className="form-vertical"
        id="kc-form-delete-account-confirm"
      >
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          {getAlert({
            message: { summary: msgStr("irreversibleAction"), type: "warning" },
            kcContext,
          })}
          <Box>
            {getTypography({
              children: msg("deletingImplies"),
            })}
            <ul id="delete-account-list">
              <li id="delete-account-list-item1">
                {msg("loggingOutImmediately")}
              </li>
              <li id="delete-account-list-item2">{msg("errasingData")}</li>
            </ul>

            {getTypography({
              className: "delete-account-text",
              children: msg("finalDeletionConfirmation"),
            })}
          </Box>
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormGroupClass")}
            display="flex"
            flexDirection="column"
            sx={{ gridGap: 16 }}
          >
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doConfirmDelete"),
              id: "delete-account-delete-button",
              sx: {
                backgroundColor: "cobalt.redText",
                "&:hover, &:focus, &:active": {
                  backgroundColor: "cobalt.redIcon",
                },
              },
            })}

            {triggered_from_aia &&
              getSecondaryButton({
                kcProps: kcProps,
                message: msgStr("doCancel"),
                variant: "text",
                name: "cancel-aia",
                type: "submit",
                id: "delete-account-cancel-button",
              })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default DeleteAccountConfirm;
