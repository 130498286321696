import { useKcMessagesContext } from "../../../contexts";
import { ProfileFieldLabelProps } from "./ProfileFieldLabel.d";

const ProfileFieldLabel = (props: ProfileFieldLabelProps) => {
  const { attribute, option } = props;
  const { advancedMsg } = useKcMessagesContext();

  if (attribute.annotations?.inputOptionLabels) {
    return (
      <>
        {advancedMsg(
          attribute.annotations?.inputOptionLabels[option as any] || option
        )}
      </>
    );
  }

  if (attribute.annotations.inputOptionLabelsI18nPrefix) {
    return (
      <>
        {advancedMsg(
          `${attribute.annotations.inputOptionLabelsI18nPrefix}.${option}`
        )}
      </>
    );
  }

  return <>{option}</>;
};

export default ProfileFieldLabel;
