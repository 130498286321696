import { createContext, useCallback, useContext, useMemo } from "react";
import parse from "html-react-parser";
import {
  KcMessagesContextProps,
  KcMessagesContextProviderProps,
} from "./KcMessagesContext.d";

const KcMessagesContext = createContext<KcMessagesContextProps>({
  msg: () => <></>,
  msgStr: () => "",
  advancedMsg: () => <></>,
  advancedMsgStr: () => "",
  kcSanitize: () => "",
  isHTML: () => false,
});

export function KcMessagesContextProvider({
  children,
  i18n,
}: KcMessagesContextProviderProps) {
  const defaultMsg = () => <></>;
  const defaultMsgStr = () => "";

  const msg = i18n?.msg || defaultMsg;
  const msgStr = i18n?.msgStr || defaultMsgStr;
  const advancedMsg = i18n?.advancedMsg || defaultMsg;
  const advancedMsgStr = i18n?.advancedMsgStr || defaultMsgStr;

  const isHTML = useCallback((str: string): boolean => {
    var div = document.createElement("div");
    div.innerHTML = str;

    for (var c = div.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }, []);

  const kcSanitize = useCallback(
    (message: string) => {
      return isHTML(message) ? parse(message) : message;
    },
    [isHTML]
  );

  const value = useMemo(
    () => ({
      msg,
      msgStr,
      advancedMsg,
      advancedMsgStr,
      kcSanitize,
      isHTML,
    }),
    [advancedMsg, advancedMsgStr, msg, msgStr, kcSanitize, isHTML]
  );

  return (
    <KcMessagesContext.Provider value={value}>
      {children}
    </KcMessagesContext.Provider>
  );
}

export const useKcMessagesContext = () => useContext(KcMessagesContext);

export default KcMessagesContext;
