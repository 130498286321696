import React, { lazy, memo } from "react";
import {
  AcceptTermsAndConditionsTemplate,
  FullPageTemplate,
  Template,
} from "../../pages";
import { getPage } from "../../scripts";
import { DaKcAppProps } from "./DaKcApp.d";
import { ContextProvider } from "../../contexts";
import { useI18n } from "../../messages";
import { KcContext } from "keycloakify/login/kcContext";
import Fallback from "keycloakify/login/Fallback";

const DefaultTemplate = lazy(() => import("keycloakify/login/Template"));

const DaKcApp = memo(({ kcContext }: DaKcAppProps) => {
  const i18n = useI18n({
    kcContext: kcContext,
  });

  //NOTE: Locale not yet downloaded
  if (i18n === null) {
    return null;
  }

  const page = getPage(kcContext, i18n);

  if (page !== undefined) {
    switch (kcContext.pageId) {
      case "accept-terms-and-conditions.ftl":
        return (
          <ContextProvider kcContext={kcContext} i18n={i18n}>
            <AcceptTermsAndConditionsTemplate {...{ kcContext, i18n: i18n }}>
              {page}
            </AcceptTermsAndConditionsTemplate>
          </ContextProvider>
        );
      case "saml-post-form.ftl":
        return (
          <ContextProvider kcContext={kcContext} i18n={i18n}>
            <FullPageTemplate {...{ kcContext, i18n: i18n }}>
              {page}
            </FullPageTemplate>
          </ContextProvider>
        );
      default:
        return (
          <ContextProvider kcContext={kcContext} i18n={i18n}>
            <Template {...{ kcContext, i18n: i18n }}>{page}</Template>
          </ContextProvider>
        );
    }
  } else {
    const newKcContext: KcContext = kcContext as KcContext;
    return (
      <ContextProvider kcContext={kcContext} i18n={i18n}>
        <Fallback
          Template={DefaultTemplate}
          doUseDefaultCss={true}
          {...{ kcContext: newKcContext, i18n: i18n }}
        />
      </ContextProvider>
    );
  }
});

export default DaKcApp;
