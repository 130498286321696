import React, { memo, useRef } from "react";
import { Stack, Box, Divider } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { MagicLinkInfoProps } from "./LoginMagicLinkInfo.d";
import { stringFromDateTime } from "../../scripts/common";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginMagicLinkInfo = memo(
  ({ kcContext, kcProps }: MagicLinkInfoProps) => {
    const { email, da_magic_link_cant_be_sent_before } = kcContext;
    const { advancedMsgStr } = useKcMessagesContext();

    const { getTypography, getMainTitle, getPrimaryButton } =
      useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const nextEmailTime: number = Date.parse(
      stringFromDateTime(da_magic_link_cant_be_sent_before)
    );
    const emailDelay: number = (nextEmailTime - Date.now()) / 1000;
    const [timer, setTimer] = React.useState(emailDelay);

    const resendLinkByEmail = () => {
      window.location.reload();
    };

    const timerInterval = useRef<NodeJS.Timer>();
    function startTimer() {
      timerInterval.current = setInterval(
        () => setTimer((timer) => timer - 1),
        1000
      );
    }

    React.useEffect(() => {
      if (!timerInterval.current) startTimer();
      if (timer <= 0) clearInterval(timerInterval.current);
    }, [timer]);

    return (
      <React.Fragment>
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          <Box className={getClassName("kcFormOptionsWrapperClass")}>
            {getMainTitle({
              align: "center",
              children: <>{email}</>,
            })}
          </Box>
          <Divider />
          <Box className={getClassName("kcFormOptionsWrapperClass")}>
            {getTypography({
              align: "center",
              children: advancedMsgStr("da-magic-link.display.message"),
            })}
          </Box>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          {getPrimaryButton({
            id: "kc-resend",
            name: "resend",
            kcProps: kcProps,
            message:
              advancedMsgStr("da-magic-link.resend-button") +
              (timer > 0
                ? " : " +
                  timer.toFixed(0) +
                  advancedMsgStr("da-magic-link.timer-unit")
                : ""),
            onClick: () => resendLinkByEmail(),
            disabled: timer > 0,
          })}
        </Box>
      </React.Fragment>
    );
  }
);

export default LoginMagicLinkInfo;
