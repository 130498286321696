import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useKcMessagesContext, useFormContext } from "../../../contexts";
import { MainProfileTextFieldProps } from "./MainProfileTextField.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { kcContext } from "../../../kcContext";

export default function MainProfileTextField(props: MainProfileTextFieldProps) {
  const {
    displayname,
    control,
    rules,
    defaultValue,
    attribute,
    kcProps,
    errorname,
    disableKCErrors,
    requiredChar,
    ...others
  } = props;
  const { advancedMsgStr, msgStr } = useKcMessagesContext();
  const { getProfileRules } = useFormContext();
  const { messagesPerField } = kcContext || {};

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <Controller
      name={attribute.name}
      control={control}
      rules={rules !== undefined ? rules : getProfileRules(attribute)}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <TextField
          fullWidth
          id={attribute.name}
          value={value}
          className={getClassName("kcInputClass")}
          error={
            (!disableKCErrors &&
              messagesPerField?.existsError(errorname || attribute.name)) ||
            error !== undefined
          }
          disabled={attribute.readOnly}
          autoComplete={attribute.autocomplete}
          placeholder={
            displayname || advancedMsgStr(attribute.displayName ?? "")
          }
          label={`${displayname || msgStr(attribute.name)} ${
            rules?.required ? requiredChar || "*" : ""
          }`}
          helperText={
            <>
              {!disableKCErrors &&
              messagesPerField?.existsError(errorname || attribute.name) ? (
                <>
                  {error?.message && (
                    <>
                      {error?.message}
                      <br />
                    </>
                  )}
                  {messagesPerField?.get(errorname || attribute.name)}
                </>
              ) : (
                error?.message
              )}
            </>
          }
          onChange={onChange}
          onBlur={onBlur}
          {...others}
          name={attribute.name}
        />
      )}
    />
  );
}
