import { Divider } from "@mui/material";
import { colors } from "@docaposte-agility/da-design-system/";
import { useComponentsContext } from "../../../contexts";
import { KcDividerProps } from "./KcDivider.d";

export default function KcDivider(props: KcDividerProps) {
  const { color, message, typographyProps, dividerProps } = props;
  const { getTypography } = useComponentsContext();

  return (
    <Divider
      id="kc-login-divider"
      {...dividerProps}
      sx={{
        "&.MuiDivider-root::before": {
          borderTop: `1px dashed ${color || colors.bleuDigital10}`,
        },
        "&.MuiDivider-root::after": {
          borderTop: `1px dashed ${color || colors.bleuDigital10}`,
        },
        ...dividerProps?.sx,
      }}
    >
      {getTypography({
        id: "kc-login-divider-text",
        variant: "h4",
        children: message,
        ...typographyProps,
        sx: { color: "cobalt.lynch", fontSize: "12px", ...typographyProps?.sx },
      })}
    </Divider>
  );
}
