import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { LoginVerifyEmailProps } from "./LoginVerifyEmail.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginVerifyEmail = memo(
  ({ kcContext, ...props }: LoginVerifyEmailProps) => {
    const { url, user } = kcContext;
    const { msgStr, advancedMsgStr } = useKcMessagesContext();
    const { getLink, getTypography } = useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <React.Fragment>
        <Stack
          sx={{ mt: 2 }}
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={5}
          className={getClassName("kcFormGroupClass")}
        >
          <Box className={getClassName("kcFormOptionsWrapperClass")}>
            {getTypography({
              align: "left",
              children: advancedMsgStr("emailVerifyInstruction1", user?.email),
            })}
          </Box>
          {getTypography({
            align: "left",
            children: (
              <>
                {msgStr("emailVerifyInstruction2")}&nbsp;
                {getLink({
                  href: url.loginAction,
                  id: "kc-restart-flow-link",
                  children: msgStr("doClickHere"),
                })}
                &nbsp;{msgStr("emailVerifyInstruction3")}
              </>
            ),
          })}
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {getTypography({
            children: (
              <>
                {advancedMsgStr("loginVerifyEmailButton")}{" "}
                {getLink({
                  id: "kc-restart-flow-link",
                  onClick: () => window.location.reload(),
                  sx: { cursor: "pointer" },
                  children: msgStr("doClickHere"),
                })}
                &nbsp; {advancedMsgStr("loginVerifyEmailButton2")}
              </>
            ),
          })}
        </Box>
      </React.Fragment>
    );
  }
);

export default LoginVerifyEmail;
