import React, { memo, useState } from "react";
import { Stack, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginProps } from "./Login.d";
import ReCaptcha from "react-google-recaptcha";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const Login = memo(({ kcContext, kcProps }: LoginProps) => {
  const {
    url,
    login,
    realm,
    social,
    registrationDisabled,
    auth,
    usernameHidden,
    recaptchaRequired,
    recaptchaSiteKey,
    locale,
  } = kcContext;
  const { msg, msgStr, advancedMsgStr } = useKcMessagesContext();
  const {
    getTextField,
    getPasswordTextField,
    getSubmitButton,
    getLink,
    getSecondaryButton,
    getProviderButton,
    getDivider,
    getProviderDivider,
    getRememberMe,
    getProviderImage,
  } = useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    register,
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  const [canSubmit, setCanSubmit] = useState(true);
  const handleCaptchaSubmit = () => setCanSubmit(false);

  const identityMsg = !realm?.loginWithEmailAllowed
    ? "username"
    : realm?.registrationEmailAsUsername
    ? "email"
    : "usernameOrEmail";

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-login"
    >
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        {!usernameHidden && (
          <Box id="kc-form-login-id-wrapper">
            {getTextField({
              name: "username",
              id: "kc-form-login-id",
              control: control,
              rules: GetRules(
                realm?.registrationEmailAsUsername ? "email" : "username"
              ),
              displayname: msgStr(identityMsg),
              defaultValue: login?.username ?? "",
            })}
          </Box>
        )}
        <Box id="kc-form-login-password-wrapper">
          {getPasswordTextField({
            name: "password",
            id: "kc-form-login-password",
            control: control,
            rules: GetRules("password"),
          })}
        </Box>
        {realm?.rememberMe &&
          !usernameHidden &&
          getRememberMe({
            kcProps: kcProps,
            checkerProps: { ...register("rememberMe") },
          })}
        {realm?.resetPasswordAllowed && (
          <Box
            id="kc-reset-password-link-container"
            className={getClassName("kcFormOptionsWrapperClass")}
          >
            {getLink({
              href: url.loginResetCredentialsUrl,
              id: "kc-forgot-password-link",
              children: msg("doForgotPassword"),
            })}
          </Box>
        )}
        {recaptchaRequired && (
          <Box id="kc-captcha">
            <ReCaptcha
              sitekey={recaptchaSiteKey || ""}
              hl={locale?.currentLanguageTag}
              onChange={handleCaptchaSubmit}
            />
          </Box>
        )}
        <Box
          sx={{ mt: 1 }}
          className={getClassName("kcFormGroupClass")}
          id="kc-form-buttons"
        >
          <input
            type="hidden"
            id="id-hidden-input"
            name="credentialId"
            {...(auth?.selectedCredential !== undefined
              ? {
                  value: auth.selectedCredential,
                }
              : {})}
          />
          {getSubmitButton({
            kcProps: kcProps,
            message: msgStr("doLogIn"),
            disabled: recaptchaRequired ? canSubmit : false,
          })}
        </Box>
        {realm?.password &&
          realm?.registrationAllowed &&
          !registrationDisabled && (
            <>
              <Box
                id="kc-login-divider-wrapper"
                display="flex"
                flexDirection="column"
              >
                {getDivider({
                  message: advancedMsgStr("or").toUpperCase(),
                })}
              </Box>
              <Box mt={1} id={"kc-registration-container"}>
                {getSecondaryButton({
                  kcProps: kcProps,
                  message: msgStr("doRegister"),
                  variant: "text",
                  onClick: () =>
                    (window.location.href = kcContext.url.registrationUrl),
                })}
              </Box>
            </>
          )}
        {realm?.password && social.providers && (
          <React.Fragment>
            {getProviderDivider({})}
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              id="kc-providers-container"
            >
              {social?.providers?.map((provider) => (
                <Grid
                  item
                  mt={2}
                  id={provider.providerId}
                  key={provider.providerId}
                  xs={matchesSmDown ? 12 : 6}
                  className={`kc-provider-container provider-alias-${
                    provider.alias || "common"
                  }`}
                >
                  {getProviderButton({
                    id: `social-${provider.providerId}`,
                    message: provider.displayName,
                    provider: provider,
                    startIcon: getProviderImage({ provider: provider }),
                    onClick: () => (window.location.href = provider.loginUrl),
                    className:
                      "pf-c-button pf-m-control pf-m-block kc-social-item kc-social-gray pf-l-grid__item",
                    sx: { width: "95%" },
                  })}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
      </Stack>
    </form>
  );
});

export default Login;
