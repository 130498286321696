import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useKcMessagesContext, useFormContext } from "../../../contexts";
import { MainTextFieldProps } from "./MainTextField.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { kcContext } from "../../../kcContext";

export default function MainTextField(props: MainTextFieldProps) {
  const {
    displayname,
    control,
    name,
    rules,
    defaultValue,
    errorname,
    disableKCErrors,
    requiredChar,
    ...others
  } = props;
  const { advancedMsgStr } = useKcMessagesContext();
  const { GetRules } = useFormContext();
  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });
  const { messagesPerField } = kcContext || {};

  const errorName = errorname || name;
  const onError = !disableKCErrors && messagesPerField?.existsError(errorName);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules !== undefined ? rules : GetRules("requiredField")}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <TextField
          id="kc-form-login-input"
          className={getClassName("kcInputClass")}
          fullWidth
          onBlur={onBlur}
          label={`${displayname || advancedMsgStr(name)} ${
            rules?.required ? requiredChar || "*" : ""
          }`}
          placeholder={displayname || advancedMsgStr(name)}
          error={onError || error !== undefined}
          helperText={
            <>
              {onError ? (
                <>
                  {error?.message && (
                    <>
                      {error?.message}
                      <br />
                    </>
                  )}
                  {errorName !== "username" && errorName !== "password" && (
                    <>{messagesPerField?.get(errorName)}</>
                  )}
                </>
              ) : (
                error?.message
              )}
            </>
          }
          value={value}
          onChange={onChange}
          name={name}
          {...others}
        />
      )}
    />
  );
}
