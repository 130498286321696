import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LogoutConfirmProps } from "./LogoutConfirm.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LogoutConfirm = memo(({ kcContext, kcProps }: LogoutConfirmProps) => {
  const { url, client, logoutConfirm } = kcContext;
  const { msg, msgStr } = useKcMessagesContext();
  const { getSubmitButton, getLink, getTypography } = useComponentsContext();
  const { getFormProps } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  return (
    <div>
      <Stack id="kc-logout-confirm" spacing={2}>
        {getTypography({
          children: msgStr("logoutConfirmHeader"),
        })}
        <form
          action={url?.logoutConfirmAction || ""}
          onSubmit={(event) => onSubmit(event, isValid, trigger)}
          method="post"
          id="form-actions"
        >
          <input
            type="hidden"
            name="session_code"
            value={logoutConfirm?.code}
          />
          <Box className={getClassName("kcFormGroupClass")}>
            <Box
              className={getClassName("kcFormOptionsClass")}
              id="kc-form-options"
            >
              <Box className={getClassName("kcFormOptionsWrapperClass")} />
            </Box>
          </Box>
          <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
            <Box
              sx={{ mt: 1 }}
              className={getClassName("kcFormGroupClass")}
              id="kc-form-buttons"
            >
              {getSubmitButton({
                kcProps: kcProps,
                message: msgStr("doLogout"),
                name: "confirmLogout",
                id: "kc-logout",
              })}
            </Box>
          </Stack>
        </form>

        <Box id="kc-info-message">
          {!logoutConfirm?.skipLink &&
            client?.baseUrl &&
            getLink({
              href: client?.baseUrl,
              id: "backToApplication",
              children: msg("backToApplication"),
            })}
        </Box>
      </Stack>
      <div className="clearfix"></div>
    </div>
  );
});

export default LogoutConfirm;
