import React, { Fragment, memo } from "react";
import {
  Stack,
  Box,
  //  FormControl
} from "@mui/material";
import { RegisterUserProfileProps } from "./RegisterUserProfile.d";
import { useFormValidation } from "keycloakify/login/lib/useFormValidation";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
} from "../../contexts";
import { onSubmit } from "../../scripts";
import { useForm } from "react-hook-form";
import UserProfileCommons from "../UserProfileCommons";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const RegisterUserProfile = memo(
  ({ kcContext, i18n, kcProps }: RegisterUserProfileProps) => {
    const {
      url,
      recaptchaRequired,
      recaptchaSiteKey,
      profile,
      passwordRequired,
      realm,
    } = kcContext;
    const {
      msg,
      // advancedMsg,
      advancedMsgStr,
    } = useKcMessagesContext();
    const {
      getLink,
      getSubmitButton,
      // getProfileTextField,
      getPasswordProfileTextField,
      // getProfileOptions,
      // getTypography,
    } = useComponentsContext();
    const { getFormProps, getProfileRules } = useFormContext();

    const {
      // formValidationState: { fieldStateByAttributeName },

      attributesWithPassword,
    } = useFormValidation({
      kcContext,
      i18n,
    });

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    // let currentGroup = "";

    const passwordAttribute = attributesWithPassword.find(
      (elem) => elem.name === "password"
    );

    const passwordConfirmAttribute = attributesWithPassword.find(
      (elem) => elem.name === "password-confirm"
    );

    return (
      <form
        action={url.registrationAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-form-login"
      >
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          <UserProfileCommons
            control={control}
            profile={profile}
            afterField={(attribute) => (
              <>
                {passwordRequired &&
                  (attribute.name === "username" ||
                    (attribute.name === "email" &&
                      realm?.registrationEmailAsUsername)) &&
                  getPasswordProfileTextField({
                    displayname: advancedMsgStr("password"),
                    attribute: passwordAttribute,
                    control: control,
                    rules: getProfileRules(passwordAttribute || ({} as any)),
                    kcProps: kcProps,
                  })}
                {passwordRequired &&
                  (attribute.name === "username" ||
                    (attribute.name === "email" &&
                      realm?.registrationEmailAsUsername)) &&
                  getPasswordProfileTextField({
                    displayname: advancedMsgStr("password"),
                    attribute: passwordConfirmAttribute,
                    control: control,
                    kcProps: kcProps,
                    rules: getProfileRules(
                      passwordConfirmAttribute || ({} as any)
                    ),
                  })}
              </>
            )}
            kcProps={kcProps}
          />
          {recaptchaRequired && (
            <Box className="form-group">
              <Box className={getClassName("kcInputWrapperClass")}>
                <div
                  className="g-recaptcha"
                  data-size="compact"
                  data-sitekey={recaptchaSiteKey}
                />
              </Box>
            </Box>
          )}
          <Box
            id="kc-form-options"
            className={getClassName("kcFormOptionsClass")}
          >
            <Box className={getClassName("kcFormOptionsWrapperClass")}>
              {getLink({
                href: url.loginUrl,
                id: "backToApplication",
                children: msg("backToApplication"),
              })}
            </Box>
          </Box>
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormButtonsClass")}
            display="flex"
            justifyContent="center"
            width="100%"
          >
            {getSubmitButton({
              kcProps: kcProps,
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default RegisterUserProfile;
