import { createGetKcContext } from "keycloakify/login";
import { DaKcContextType, KcCustomPage } from "./kcContext.d";

// Add custom pages to exported pages list
export const ExtraPages: KcCustomPage[] = [
  { pageId: "secret-question.ftl" },
  { pageId: "secret-question-config.ftl" },
  { pageId: "da-otp-mail-page.ftl" },
  { pageId: "otp-sms.ftl" },
  { pageId: "da-magic-link-page.ftl" },
  { pageId: "da-magic-link-view-email.ftl" },
  { pageId: "accept-terms-and-conditions.ftl" },
  { pageId: "webauthn-authenticate.ftl" },
  { pageId: "webauthn-error.ftl" },
  { pageId: "webauthn-register.ftl" },
  { pageId: "saml-post-form.ftl" },
  { pageId: "delete-account-confirm.ftl" },
  { pageId: "login-dynamic-username.ftl" },
  { pageId: "register-dynamic-username.ftl" },
  { pageId: "restricted-profile-review.ftl" },
];

const { getKcContext } = createGetKcContext<DaKcContextType>();

export const { kcContext } = getKcContext() || {};

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
