import React, { memo } from "react";
import { Stack, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginUsernameProps } from "./LoginUsername.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginUsername = memo(({ kcContext, kcProps }: LoginUsernameProps) => {
  const { url, social, realm, registrationDisabled } = kcContext;
  const { loginWithEmailAllowed } = realm;
  const { msgStr, advancedMsgStr } = useKcMessagesContext();
  const {
    getSubmitButton,
    getTextField,
    getProviderDivider,
    getProviderButton,
    getProviderImage,
    getDivider,
    getSecondaryButton,
  } = useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-login"
    >
      <Stack spacing={3} className={getClassName("kcFormGroupClass")}>
        <Box id="kc-form-login-id-wrapper">
          {getTextField({
            name: "username",
            id: "kc-form-login-id",
            control: control,
            rules: GetRules("requiredField"),
            displayname: loginWithEmailAllowed
              ? msgStr("usernameOrEmail")
              : msgStr("email"),
          })}
        </Box>
        <Box
          sx={{ mt: 1, width: "100%" }}
          className={getClassName("kcFormGroupClass")}
          display="flex"
          justifyContent="center"
          id="kc-form-buttons"
        >
          {getSubmitButton({
            kcProps: kcProps,
            message: msgStr("doLogIn"),
          })}
        </Box>

        {realm?.password &&
          realm?.registrationAllowed &&
          !registrationDisabled && (
            <>
              <Box
                id="kc-login-divider-wrapper"
                display="flex"
                flexDirection="column"
              >
                {getDivider({
                  message: advancedMsgStr("or").toUpperCase(),
                })}
              </Box>
              <Box mt={1} id={"kc-registration-container"}>
                {getSecondaryButton({
                  kcProps: kcProps,
                  message: msgStr("doRegister"),
                  variant: "text",
                  onClick: () =>
                    (window.location.href = kcContext.url.registrationUrl),
                })}
              </Box>
            </>
          )}
        {realm?.password && social?.providers && (
          <React.Fragment>
            {getProviderDivider({})}
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              id="kc-providers-container"
            >
              {social?.providers?.map((provider) => (
                <Grid
                  item
                  mt={2}
                  id={provider.providerId}
                  key={provider.providerId}
                  xs={matchesSmDown ? 12 : 6}
                  className={`kc-provider-container provider-alias-${
                    provider.alias || "common"
                  }`}
                >
                  {getProviderButton({
                    id: `social-${provider.providerId}`,
                    message: provider.displayName,
                    provider: provider,
                    startIcon: getProviderImage({ provider: provider }),
                    onClick: () => (window.location.href = provider.loginUrl),
                    className:
                      "pf-c-button pf-m-control pf-m-block kc-social-item kc-social-gray pf-l-grid__item",
                    sx: { width: "95%" },
                  })}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
      </Stack>
    </form>
  );
});

export default LoginUsername;
