import React from "react";
import { AdvancedHeader } from "..";
import { LoginPasswordProps } from "./LoginPassword.d";

const LoginPasswordHeader = (props: LoginPasswordProps) => {
  const { kcContext, ...others } = props;
  const { client, login } = kcContext;

  return (
    <AdvancedHeader
      message={<>{login?.username}</>}
      reverse
      {...others}
      client={client}
    />
  );
};

export default LoginPasswordHeader;
