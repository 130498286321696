// for embedded scripts, quoted and modified from https://github.com/swansontec/rfc4648.js by William Swanson
// eslint-disable-next-line strict
"use strict";
// eslint-disable-next-line no-use-before-define
var base64url = base64url || {};
(function (base64url) {
  function parse(string, encoding, opts = {}) {
    // Build the character lookup table:
    if (!encoding.codes) {
      encoding.codes = {};
      for (let i = 0; i < encoding.chars.length; ++i) {
        encoding.codes[encoding.chars[i]] = i;
      }
    }

    // The string must have a whole number of bytes:
    if (!opts.loose && (string.length * encoding.bits) & 7) {
      throw new SyntaxError("Invalid padding");
    }

    // Count the padding bytes:
    let end = string.length;
    while (string[end - 1] === "=") {
      --end;

      // If we get a whole number of bytes, there is too much padding:
      if (!opts.loose && !(((string.length - end) * encoding.bits) & 7)) {
        throw new SyntaxError("Invalid padding");
      }
    }

    // Allocate the output:
    const out = new (opts.out || Uint8Array)(((end * encoding.bits) / 8) | 0);

    // Parse the data:
    let bits = 0; // Number of bits currently in the buffer
    let buffer = 0; // Bits waiting to be written out, MSB first
    let written = 0; // Next byte to write
    for (let i = 0; i < end; ++i) {
      // Read one character from the string:
      const value = encoding.codes[string[i]];
      if (value === void 0) {
        throw new SyntaxError("Invalid character " + string[i]);
      }

      // Append the bits to the buffer:
      buffer = (buffer << encoding.bits) | value;
      bits += encoding.bits;

      // Write out some bits if the buffer has a byte's worth:
      if (bits >= 8) {
        bits -= 8;
        out[written++] = 0xff & (buffer >> bits);
      }
    }

    // Verify that we have received just enough bits:
    if (bits >= encoding.bits || 0xff & (buffer << (8 - bits))) {
      throw new SyntaxError("Unexpected end of data");
    }

    return out;
  }

  function stringify(data, encoding, opts = {}) {
    const { pad = true } = opts;
    const mask = (1 << encoding.bits) - 1;
    let out = "";

    let bits = 0; // Number of bits currently in the buffer
    let buffer = 0; // Bits waiting to be written out, MSB first
    for (let i = 0; i < data.length; ++i) {
      // Slurp data into the buffer:
      buffer = (buffer << 8) | (0xff & data[i]);
      bits += 8;

      // Write out as much as we can:
      while (bits > encoding.bits) {
        bits -= encoding.bits;
        out += encoding.chars[mask & (buffer >> bits)];
      }
    }

    // Partial character:
    if (bits) {
      out += encoding.chars[mask & (buffer << (encoding.bits - bits))];
    }

    // Add padding characters until we hit a byte boundary:
    if (pad) {
      while ((out.length * encoding.bits) & 7) {
        out += "=";
      }
    }

    return out;
  }

  const encoding = {
    chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_",
    bits: 6,
  };

  base64url.decode = function (string, opts) {
    return parse(string, encoding, opts);
  };

  base64url.encode = function (data, opts) {
    return stringify(data, encoding, opts);
  };

  return base64url;
})(base64url);

// AUTHENTICATE PAGE

export function webAuthnAuthenticate(kcContext, msg) {
  if (!kcContext.isUserIdentified) {
    doAuthenticate([], kcContext, msg);
    return;
  }
  checkAllowCredentials(kcContext, msg);
}
function checkAllowCredentials(kcContext, msg) {
  let allowCredentials = [];
  let authn_use = document.forms["authn_select"].authn_use_chk;
  if (authn_use !== undefined) {
    if (authn_use.length === undefined) {
      allowCredentials.push({
        id: base64url.decode(authn_use.value, { loose: true }),
        type: "public-key",
      });
    } else {
      for (let i = 0; i < authn_use.length; i++) {
        allowCredentials.push({
          id: base64url.decode(authn_use[i].value, { loose: true }),
          type: "public-key",
        });
      }
    }
  }
  doAuthenticate(allowCredentials, kcContext, msg);
}
function doAuthenticate(allowCredentials, kcContext, msg) {
  // Check if WebAuthn is supported by this browser
  if (!window.PublicKeyCredential) {
    document.getElementById("error").value = msg(
      "webauthn-unsupported-browser-text"
    );
    document.getElementById("webauth").submit();
    return;
  }
  let challenge = kcContext.challenge;
  let userVerification = kcContext.userVerification;
  let rpId = kcContext.rpId;
  let publicKey = {
    rpId: rpId,
    challenge: base64url.decode(challenge, { loose: true }),
  };

  if (
    kcContext.createTimeout &&
    kcContext.createTimeout !== 0 &&
    kcContext.createTimeout !== "0"
  )
    publicKey.timeout = kcContext.createTimeout * 1000;
  if (allowCredentials.length) {
    publicKey.allowCredentials = allowCredentials;
  }
  if (userVerification !== "not specified")
    publicKey.userVerification = userVerification;
  else publicKey.userVerification = "discouraged";

  navigator.credentials
    .get({ publicKey: publicKey })
    .then((result) => {
      window.result = result;

      let clientDataJSON = result.response.clientDataJSON;
      let authenticatorData = result.response.authenticatorData;
      let signature = result.response.signature;

      document.getElementById("clientDataJSON").value = base64url.encode(
        new Uint8Array(clientDataJSON),
        { pad: false }
      );
      document.getElementById("authenticatorData").value = base64url.encode(
        new Uint8Array(authenticatorData),
        { pad: false }
      );
      document.getElementById("signature").value = base64url.encode(
        new Uint8Array(signature),
        { pad: false }
      );
      document.getElementById("credentialId").value = result.id;
      if (result.response.userHandle) {
        document.getElementById("userHandle").value = base64url.encode(
          new Uint8Array(result.response.userHandle),
          {
            pad: false,
          }
        );
      }
      document.getElementById("webauth").submit();
    })
    .catch((err) => {
      document.getElementById("error").value = msg(
        "webauthn-unsupported-browser-text"
      );
      document.getElementById("webauth").submit();
    });
}

// REGISTER PAGE

export function registerSecurityKey(kcContext, msg) {
  // Check if WebAuthn is supported by this browser
  if (!window.PublicKeyCredential) {
    document.getElementById("error").value = msg(
      "webauthn-unsupported-browser-text"
    );
    document.getElementById("webauth").submit();
    return;
  }
  // mandatory parameters
  let challenge = kcContext.challenge;
  let userid = kcContext.userid;
  let username = kcContext.username;
  let signatureAlgorithms = kcContext.signatureAlgorithms;
  let pubKeyCredParams = getPubKeyCredParams(signatureAlgorithms);
  let rpEntityName = kcContext.rpEntityName;
  let rp = { name: rpEntityName };
  let publicKey = {
    challenge: base64url.decode(challenge, { loose: true }),
    rp: rp,
    user: {
      id: base64url.decode(userid, { loose: true }),
      name: username,
      displayName: username,
    },
    pubKeyCredParams: pubKeyCredParams,
  };

  // optional parameters
  let rpId = kcContext.rpId;
  publicKey.rp.id = rpId;
  let attestationConveyancePreference =
    kcContext.attestationConveyancePreference;
  if (attestationConveyancePreference !== "not specified")
    publicKey.attestation = attestationConveyancePreference;
  let authenticatorSelection = {};
  let isAuthenticatorSelectionSpecified = false;
  let authenticatorAttachment = kcContext.authenticatorAttachment;
  if (authenticatorAttachment !== "not specified") {
    authenticatorSelection.authenticatorAttachment = authenticatorAttachment;
    isAuthenticatorSelectionSpecified = true;
  }
  let requireResidentKey = kcContext.requireResidentKey;
  if (requireResidentKey !== "not specified") {
    if (requireResidentKey === "Yes")
      authenticatorSelection.requireResidentKey = true;
    else authenticatorSelection.requireResidentKey = false;
    isAuthenticatorSelectionSpecified = true;
  }
  let userVerificationRequirement = kcContext.userVerificationRequirement;
  if (userVerificationRequirement !== "not specified") {
    authenticatorSelection.userVerification = userVerificationRequirement;
    isAuthenticatorSelectionSpecified = true;
  }
  if (isAuthenticatorSelectionSpecified)
    publicKey.authenticatorSelection = authenticatorSelection;
  let createTimeout = parseInt(kcContext.createTimeout) || 0;
  if (createTimeout !== 0) publicKey.timeout = createTimeout * 1000;
  let excludeCredentialIds = kcContext.excludeCredentialIds;
  let excludeCredentials = getExcludeCredentials(excludeCredentialIds);
  if (excludeCredentials.length > 0)
    publicKey.excludeCredentials = excludeCredentials;

  navigator.credentials
    .create({ publicKey: publicKey })
    .then(function (result) {
      window.result = result;
      let clientDataJSON = result.response.clientDataJSON;
      let attestationObject = result.response.attestationObject;
      let publicKeyCredentialId = result.rawId;
      document.getElementById("clientDataJSON").value = base64url.encode(
        new Uint8Array(clientDataJSON),
        { pad: false }
      );
      document.getElementById("attestationObject").value = base64url.encode(
        new Uint8Array(attestationObject),
        { pad: false }
      );
      document.getElementById("publicKeyCredentialId").value = base64url.encode(
        new Uint8Array(publicKeyCredentialId),
        { pad: false }
      );
      if (typeof result.response.getTransports === "function") {
        let transports = result.response.getTransports();
        if (transports) {
          document.getElementById("transports").value =
            getTransportsAsString(transports);
        }
      } else {
        console.log(
          "Your browser is not able to recognize supported transport media for the authenticator."
        );
      }
      let initLabel = "WebAuthn Authenticator (Default Label)";
      let labelResult = window.prompt(
        "Please input your registered authenticator's label",
        initLabel
      );
      if (labelResult === null) labelResult = initLabel;
      document.getElementById("authenticatorLabel").value = labelResult;
      document.getElementById("register").submit();
    })
    .catch(function (err) {
      document.getElementById("error").value = err;
      document.getElementById("register").submit();
    });
}
function getPubKeyCredParams(signatureAlgorithms) {
  let pubKeyCredParams = [];
  if (signatureAlgorithms.length === 0) {
    pubKeyCredParams.push({ type: "public-key", alg: -7 });
    return pubKeyCredParams;
  }
  for (let i = 0; i < signatureAlgorithms.length; i++) {
    pubKeyCredParams.push({
      type: "public-key",
      alg: signatureAlgorithms[i],
    });
  }
  return pubKeyCredParams;
}
function getExcludeCredentials(excludeCredentialIds) {
  let excludeCredentials = [];
  if (excludeCredentialIds === "") return excludeCredentials;
  let excludeCredentialIdsList = excludeCredentialIds.split(",");
  for (let i = 0; i < excludeCredentialIdsList.length; i++) {
    excludeCredentials.push({
      type: "public-key",
      id: base64url.decode(excludeCredentialIdsList[i], { loose: true }),
    });
  }
  return excludeCredentials;
}
function getTransportsAsString(transportsList) {
  if (transportsList === "" || transportsList.constructor !== Array) return "";
  let transportsString = "";
  for (let i = 0; i < transportsList.length; i++) {
    transportsString += transportsList[i] + ",";
  }
  return transportsString.slice(0, -1);
}

// ERROR PAGE

export function refreshPage(execution) {
  document.getElementById("isSetRetry").value = "retry";
  document.getElementById("executionValue").value = execution;
  document.getElementById("kc-error-credential-form").submit();
}
