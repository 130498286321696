import React from "react";
import { useComponentsContext, useKcMessagesContext } from "../../../contexts";
import Header from "../Header";
import { CustomHeaderProps } from "./CustomHeader.d";

const CustomHeader = (props: CustomHeaderProps) => {
  const { kcContext, basicHeaderProps, advancedHeaderProps } = props;
  const { pageId, client, auth } = kcContext;
  const { advancedMsgStr, msg } = useKcMessagesContext();
  const { getBasicHeader, getAdvancedHeader, getLoginPasswordHeader } =
    useComponentsContext();

  if (!kcContext) {
    return <></>;
  }

  switch (pageId) {
    case "login.ftl":
    case "login-otp.ftl":
    case "login-dynamic-username.ftl":
      return getAdvancedHeader({
        client: client,
        message: "accessTo",
        ...advancedHeaderProps,
      });
    case "error.ftl":
      return getBasicHeader({ message: "errorTitle", ...basicHeaderProps });
    case "info.ftl":
      return getBasicHeader({
        message:
          kcContext.messageHeader ||
          advancedMsgStr(kcContext.message?.summary || "") ||
          "confirmExecutionOfActions",
        ...basicHeaderProps,
      });
    case "login-password.ftl":
      return getLoginPasswordHeader({
        kcContext: kcContext,
        ...advancedHeaderProps,
      });
    case "login-reset-password.ftl":
      return getBasicHeader({
        message: "emailForgotTitle",
        ...basicHeaderProps,
      });
    case "login-update-password.ftl":
      return getBasicHeader({
        message: "updatePasswordTitle",
        ...basicHeaderProps,
      });
    case "login-update-profile.ftl":
      return getBasicHeader({
        message: "updateProfileMessage",
        ...basicHeaderProps,
      });
    case "login-username.ftl":
      return getBasicHeader({
        message: msg("loginAccountTitle", advancedMsgStr("yourAccount")),
        ...basicHeaderProps,
      });
    case "login-verify-email.ftl":
      return getBasicHeader({
        message: "emailVerifyTitle",
        ...basicHeaderProps,
      });
    case "login-page-expired.ftl":
      return getBasicHeader({
        message: "pageExpiredTitle",
        ...basicHeaderProps,
      });
    case "register-dynamic-username.ftl":
    case "register-user-profile.ftl":
    case "register.ftl":
      return getAdvancedHeader({
        client: client,
        message: "registerTitle",
        ...advancedHeaderProps,
      });
    case "select-authenticator.ftl":
      return getBasicHeader({
        message: auth.attemptedUsername,
        ...basicHeaderProps,
      });
    default:
      return <Header kcContext={kcContext} />;
  }
};

export default CustomHeader;
