import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { PageExpiredProps } from "./PageExpired.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
const PageExpired = memo(({ kcContext, ...props }: PageExpiredProps) => {
  const { url } = kcContext;
  const { msg } = useKcMessagesContext();
  const { getLink, getTypography, getInfoDivider } = useComponentsContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <Stack spacing={5} className={getClassName("kcFormGroupClass")}>
      <Box id="kc-expired-container">
        <Box id="kc-expired-text-container">
          {getTypography({
            children: (
              <>
                {msg("pageExpiredMsg1")}&nbsp;
                {getLink({
                  href: url.loginRestartFlowUrl,
                  id: "loginRestartLink",
                  children: msg("doClickHere"),
                })}
              </>
            ),
            className: "instruction",
            id: "instruction1",
          })}
        </Box>
        {getInfoDivider({})}
        <Box id="kc-expired-text-container">
          {getTypography({
            children: (
              <>
                {msg("pageExpiredMsg2")}&nbsp;
                {getLink({
                  href: url.loginAction,
                  id: "loginContinueLink",
                  children: msg("doClickHere"),
                })}
              </>
            ),
            className: "instruction",
            id: "instruction1",
          })}
        </Box>
      </Box>
    </Stack>
  );
});

export default PageExpired;
