import axios from "axios";
import { createContext, useCallback, useContext, useMemo } from "react";
import {
  defaultKeysProps,
  KcAttributesContextProps,
  KcAttributesContextProviderProps,
} from "./KcAttributesContext.d";

const defaultKeys: defaultKeysProps = {
  logoUrl: "logo.url",
  logoAlt: "logo.alt",
  cssUrl: "css.url",
  faviconUrl: "favicon.url",
  backgroundUrl: "background.img.url",
  defaultFaviconUrl: "/common/favicon.ico",
  defaultLogoUrl: "/common/logo-docaposte.svg",
  defaultBackgroundUrl: "/common/background-docaposte.png",
  title: "title.text",
  json: "json.text",
};

// DYNAMIC IMAGES

function checkIfImageExists(url: string, callback: (exist: boolean) => void) {
  if (!url) {
    callback(false);
    return;
  }
  const img: HTMLImageElement = new Image();
  img.src = url;

  if (img.complete && img.width > 0) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

const KcAttributesContext = createContext<KcAttributesContextProps>({
  keys: defaultKeys,
  getAttributeValue: () => "",
  applyCss: () => {},
  applyCssFromAttribute: () => {},
  applyFavicon: () => {},
  applyFaviconFromAttribute: () => {},
  applyImage: () => {},
  applyImageFromAttribute: () => {},
  applyTitle: () => {},
  applyTitleFromAttribute: () => {},
  getJsonFromAttribute: () => defaultKeys,
});

export function KcAttributesContextProvider({
  children,
  customKeys,
  kcContext,
}: KcAttributesContextProviderProps) {
  const staticResourcesServerUrl = `${window.origin}/static`;

  const defaultKeysWithEnv: defaultKeysProps = useMemo(() => {
    return !staticResourcesServerUrl
      ? defaultKeys
      : {
          ...defaultKeys,
          defaultFaviconUrl:
            staticResourcesServerUrl + defaultKeys.defaultFaviconUrl,
          defaultLogoUrl: staticResourcesServerUrl + defaultKeys.defaultLogoUrl,
          defaultBackgroundUrl:
            staticResourcesServerUrl + defaultKeys.defaultBackgroundUrl,
        };
  }, [staticResourcesServerUrl]);

  const keys = useMemo(
    () =>
      customKeys
        ? { ...defaultKeysWithEnv, ...customKeys }
        : { ...defaultKeysWithEnv },
    [customKeys, defaultKeysWithEnv]
  );

  const getAttributeValue = useCallback(
    (key: string): string => {
      if (kcContext?.client?.attributes)
        return kcContext?.client?.attributes[key];
      return "";
    },
    [kcContext]
  );

  const applyCss = useCallback(
    (cssUrl: string, cssTagId: string, onCssLoaded: () => void) => {
      if (!cssUrl || !cssTagId) {
        onCssLoaded();
        return;
      }
      let cssTag = document.getElementById(cssTagId) as HTMLLinkElement;
      if (!cssTag) {
        axios
          .get(cssUrl)
          .then(() => {
            cssTag = document.createElement("link");
            cssTag.type = "text/css";
            cssTag.rel = "stylesheet";
            cssTag.href = cssUrl;
            cssTag.onload = onCssLoaded;
            cssTag.onerror = onCssLoaded;
            cssTag.id = cssTagId;
            document.head.appendChild(cssTag);
          })
          .catch(() => {
            onCssLoaded();
          });
      }
    },
    []
  );

  const applyCssFromAttribute = useCallback(
    (cssTagId: string, onCssLoaded: () => void, key?: string) => {
      const usedKey = key || keys.cssUrl;
      const cssUrl = getAttributeValue(usedKey);
      applyCss(cssUrl, cssTagId, onCssLoaded);
    },
    [applyCss, getAttributeValue, keys.cssUrl]
  );

  const applyFavicon = useCallback(
    (faviconUrl: string) => {
      let favicon = document.querySelectorAll(
        '[rel="icon"]'
      )[0] as HTMLLinkElement;
      const defaultFavIcon = favicon !== undefined && favicon !== null;
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.id = "favicon";
      }
      checkIfImageExists(faviconUrl, (exist: boolean) => {
        if (exist || !keys.defaultFaviconUrl) {
          favicon.href = faviconUrl;
        } else {
          favicon.href = keys.defaultFaviconUrl;
        }

        if (!defaultFavIcon) {
          document.head?.appendChild(favicon);
        }
      });
    },
    [keys]
  );

  const applyFaviconFromAttribute = useCallback(
    (key?: string) => {
      const faviconKey = key || keys.faviconUrl;
      const faviconUrl = getAttributeValue(faviconKey);
      applyFavicon(faviconUrl);
    },
    [applyFavicon, getAttributeValue, keys]
  );

  const applyImage = useCallback(
    (
      imgUrl: string,
      fallback: string,
      existing: string | null,
      setExisting: (value: string) => void
    ) => {
      if (!existing) {
        checkIfImageExists(imgUrl, (exist: boolean) => {
          if (exist) {
            setExisting(imgUrl);
          } else {
            setExisting(fallback);
          }
        });
      }
    },
    []
  );

  const applyImageFromAttribute = useCallback(
    (
      fallback: string,
      existing: string | null,
      setExisting: (value: string) => void,
      key?: string
    ) => {
      const imgKey = key || keys.logoUrl;
      const imgUrl = getAttributeValue(imgKey);
      applyImage(imgUrl, fallback, existing, setExisting);
    },
    [applyImage, getAttributeValue, keys]
  );

  const applyTitle = useCallback((title: string) => {
    const titleTag = document.getElementsByTagName("title");
    if (titleTag) {
      document.title = title;
    } else {
      const newTitle = document.createElement("title");
      newTitle.text = title;
      document.head.appendChild(newTitle);
    }
  }, []);

  const applyTitleFromAttribute = useCallback(
    (fallback: string, key?: string) => {
      const titleKey = key || keys.title;
      const title = getAttributeValue(titleKey) || fallback;
      applyTitle(title);
    },
    [applyTitle, getAttributeValue, keys]
  );

  const getJsonFromAttribute = useCallback(
    (key?: string) => {
      const jsonKey = key || keys.json;
      const json = getAttributeValue(jsonKey);
      let result: { [key: string]: string } = {};
      if (json) {
        try {
          result = JSON.parse(json);
        } catch (e) {}
      }
      return result;
    },
    [getAttributeValue, keys]
  );

  const value = useMemo(
    () => ({
      keys,
      getAttributeValue,
      applyCss,
      applyCssFromAttribute,
      applyFavicon,
      applyFaviconFromAttribute,
      applyImage,
      applyImageFromAttribute,
      applyTitle,
      applyTitleFromAttribute,
      getJsonFromAttribute,
    }),
    [
      keys,
      getAttributeValue,
      applyCss,
      applyCssFromAttribute,
      applyFavicon,
      applyFaviconFromAttribute,
      applyImage,
      applyImageFromAttribute,
      applyTitle,
      applyTitleFromAttribute,
      getJsonFromAttribute,
    ]
  );

  return (
    <KcAttributesContext.Provider value={value}>
      {children}
    </KcAttributesContext.Provider>
  );
}

export const useKcAttributesContext = () => useContext(KcAttributesContext);

export default KcAttributesContext;
