import React from "react";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";
import { isString } from "../../../scripts";
import { MainTitle, SubTitle } from "../../common";
import { AdvancedHeaderProps } from "./AdvancedHeader.d";

const AdvancedHeader = (props: AdvancedHeaderProps) => {
  const { message, client, reverse, mainTitleProps, subTitleProps } = props;
  const { advancedMsg, advancedMsgStr, isHTML, kcSanitize } =
    useKcMessagesContext();

  const subTitle = (
    <SubTitle {...subTitleProps}>
      {message && isString(message) ? advancedMsg(message as string) : message}
    </SubTitle>
  );
  return (
    <React.Fragment>
      {!reverse && message && subTitle}
      {isHTML(client?.name || client?.clientId) ? (
        kcSanitize(client?.name || client?.clientId)
      ) : (
        <MainTitle {...mainTitleProps}>
          {advancedMsgStr(client?.name || "") ||
            client?.clientId ||
            advancedMsgStr("yourAccount")}
        </MainTitle>
      )}
      {reverse && message && subTitle}
    </React.Fragment>
  );
};

export default AdvancedHeader;
