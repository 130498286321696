import { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginMagicLinkProps } from "./LoginMagicLink.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginMagicLink = memo(({ kcContext, kcProps }: LoginMagicLinkProps) => {
  const { url, client } = kcContext;
  const { msgStr, advancedMsg } = useKcMessagesContext();
  const { getTextField, getMainTitle, getSubmitButton } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-login"
    >
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        {getMainTitle({
          variant: "h1",
          id: "sign-in",
          paddingBottom: 3,
          children: advancedMsg("loginTitle", client.clientId) || "Login",
        })}
      </Stack>
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        <Box id="kc-form-login-id-wrapper">
          {getTextField({
            name: "email",
            id: "kc-form-login-id",
            control: control,
            rules: GetRules("email"),
            displayname: msgStr("email"),
            defaultValue: "",
          })}
        </Box>
        <Box
          sx={{ mt: 1 }}
          className={getClassName("kcFormGroupClass")}
          id="kc-form-buttons"
        >
          <input type="hidden" id="id-hidden-input" name="credentialId" />
          {getSubmitButton({
            kcProps: kcProps,
            message: msgStr("doLogIn"),
          })}
        </Box>
      </Stack>
    </form>
  );
});

export default LoginMagicLink;
