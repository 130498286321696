import { useComponentsContext } from "../../../contexts";
import { SubTitleProps } from "./SubTitle.d";

export default function SubTitle(props: SubTitleProps) {
  const { children, ...others } = props;
  const { getTypography } = useComponentsContext();
  return getTypography({
    variant: "h2",
    id: "kc-page-subtitle",
    children: children,
    ...others,
    sx: {
      color: "cobalt.lynch",
      ...props.sx,
    },
  });
}
