import { useComponentsContext } from "../../../contexts";
import { MainTitleProps } from "./MainTitle.d";

export default function MainTitle(props: MainTitleProps) {
  const { children, ...others } = props;
  const { getTypography } = useComponentsContext();
  return getTypography({
    variant: "h1",
    id: "kc-page-title",
    children: children,
    ...others,
    sx: {
      color: "cobalt.bleuDigital100",
      wordBreak: "break-word",
      ...props.sx,
    },
  });
}
