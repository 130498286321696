import React, { memo, useState } from "react";
import { Stack, Box, FormControlLabel, Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { AcceptTermsAndConditionsProps } from "./AcceptTermsAndConditions.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const AcceptTermsAndConditions = memo(
  ({ kcContext, kcProps }: AcceptTermsAndConditionsProps) => {
    const { applicationName, url, pdfData } = kcContext;
    const { advancedMsgStr, advancedMsg } = useKcMessagesContext();
    const { getSubmitButton, getCheckbox, getTypography, getPDFViewer } =
      useComponentsContext();
    const { getFormProps } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      trigger,
      formState: { isValid },
    } = useForm(getFormProps());

    const [checked, setChecked] = useState(false);

    const handleCheck = () => setChecked(!checked);

    const checkBox: React.ReactElement = getCheckbox({
      onClick: handleCheck,
      sx: { minWidth: "16px" },
      checked: checked,
    }) || (
      <Checkbox
        onClick={handleCheck}
        checked={checked}
        sx={{ minWidth: "16px" }}
      />
    );

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-totp-login-form"
      >
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          {getTypography({
            variant: "h1",
            id: "sign-in",
            children: advancedMsg(applicationName || "") || applicationName,
          })}
          {getTypography({
            variant: "h3",
            color: "cobalt.lynch",
            id: "sign-in-title",
            children: advancedMsgStr(
              "accept_terms_and_conditions.accept_title"
            ),
          })}
          <Box id="pdf-container">
            {getPDFViewer({
              file: pdfData,
              pageProps: {
                renderAnnotationLayer: false,
                renderTextLayer: false,
              },
            })}
          </Box>
          <FormControlLabel
            className="checkbox"
            value={checked}
            sx={{ display: "flex", alignItems: "center" }}
            label={getTypography({
              sx: { ml: 1 },
              children: (
                <>
                  {advancedMsgStr(
                    "accept_terms_and_conditions.accept_check_title"
                  )}
                  {applicationName && (
                    <>
                      {" "}
                      {advancedMsgStr("accept_terms_and_conditions.for")}{" "}
                      {applicationName}
                    </>
                  )}
                </>
              ),
            })}
            control={checkBox}
          />
          <Box className={getClassName("kcFormGroupClass")}>
            <Box
              className={getClassName("kcFormOptionsClass")}
              id="kc-form-options"
            >
              <Box className={getClassName("kcFormOptionsWrapperClass")} />
            </Box>
          </Box>

          <Box
            className={getClassName("kcFormButtonsClass")}
            id="kc-form-buttons"
            width={"100%"}
            display="flex"
            justifyContent="center"
          >
            {getSubmitButton({
              id: "submit-button",
              name: "login",
              value: advancedMsgStr(
                "accept_terms_and_conditions.accept_message"
              ),
              message: advancedMsgStr(
                "accept_terms_and_conditions.accept_message"
              ),
              kcProps: kcProps,
              disabled: !checked,
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default AcceptTermsAndConditions;
