import { Button } from "@mui/material";
import { useKcMessagesContext } from "../../../contexts";
import { SecondaryButtonProps } from "./SecondaryButton.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

export default function SecondaryButton(props: SecondaryButtonProps) {
  const { kcProps, message, ...others } = props;
  const { msgStr } = useKcMessagesContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <Button
      variant="outlined"
      fullWidth
      className={
        kcProps
          ? `${getClassName("kcButtonClass")} ${getClassName(
              "kcButtonDefaultClass"
            )} ${getClassName("kcButtonBlockClass")} ${getClassName(
              "kcButtonLargeClass"
            )}`
          : ""
      }
      {...others}
    >
      {message || msgStr("doCancel")}
    </Button>
  );
}
