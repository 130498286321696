import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { SecretQuestionProps } from "./SecretQuestion.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const SecretQuestion = memo(({ kcContext, kcProps }: SecretQuestionProps) => {
  const { url, secret_question } = kcContext;
  const { advancedMsgStr } = useKcMessagesContext();
  const { getSubmitButton, getTextField, getTypography } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-totp-login-form"
      className={getClassName("kcFormClass")}
    >
      <Stack spacing={2} id="secret-question-container">
        {getTypography({
          id: "secret_question_text1",
          variant: "subtitle2",
          sx: { color: "GrayText" },
          children: advancedMsgStr(
            "secret_question.secret_answer.please-answer-question"
          ),
        })}
        <Box className={getClassName("kcFormGroupClass")}>
          {getTextField({
            name: "secret_answer",
            id: "secret_answer",
            control: control,
            autoComplete: "off",
            rules: GetRules("requiredField"),
            displayname: secret_question,
            disableKCErrors: true,
            placeholder: advancedMsgStr("secret_question.secret_answer.label"),
          })}
        </Box>

        <Box className={getClassName("kcFormGroupClass")}>
          <Box
            className={getClassName("kcFormOptionsClass")}
            id="kc-form-options"
          >
            <Box className={getClassName("kcFormOptionsWrapperClass")} />
          </Box>
        </Box>

        <Box
          className={getClassName("kcFormButtonsClass")}
          id="kc-form-buttons"
        >
          {getSubmitButton({
            id: "doLoginButton",
            name: "login",
            kcProps: kcProps,
            message: advancedMsgStr("secret_question.do_submit"),
          })}
        </Box>
      </Stack>
    </form>
  );
});

export default SecretQuestion;
