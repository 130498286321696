import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginResetPasswordProps } from "./LoginResetPassword.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginResetPassword = memo(
  ({ kcContext, kcProps }: LoginResetPasswordProps) => {
    const { realm, url } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const { getLink, getSubmitButton, getTextField } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    const identityMsg = !realm?.loginWithEmailAllowed
      ? "username"
      : realm?.registrationEmailAsUsername
      ? "email"
      : "usernameOrEmail";

    return (
      <form
        action={url?.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-form-login"
      >
        <Stack spacing={3} className={getClassName("kcFormGroupClass")}>
          <Box id="kc-username-wrapper">
            {getTextField({
              name: "username",
              id: "username",
              control: control,
              rules: GetRules(
                realm?.registrationEmailAsUsername ? "email" : "username"
              ),
              displayname: msgStr(identityMsg),
            })}
          </Box>

          <Box className={getClassName("kcFormOptionsWrapperClass")}>
            {getLink({
              href: url?.loginUrl,
              id: "kc-back-to-login",
              children: msg("backToLogin"),
            })}
          </Box>
          <Box
            sx={{ mt: 1, width: "100%" }}
            display="flex"
            justifyContent="center"
            className={getClassName("kcFormGroupClass")}
          >
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doSubmit"),
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default LoginResetPassword;
