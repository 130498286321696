import { useKcMessagesContext } from "../../../contexts";
import { isString } from "../../../scripts";
import { MainTitle } from "../../common";
import { BasicHeaderProps } from "./BasicHeader.d";

const BasicHeader = (props: BasicHeaderProps) => {
  const { message, argsMessage, mainTitleProps } = props;
  const { advancedMsg } = useKcMessagesContext();

  if (!message) {
    return <></>;
  }

  return (
    <MainTitle align="center" {...mainTitleProps}>
      {isString(message)
        ? advancedMsg.apply(null, [
            message as string,
            ...(argsMessage || []),
          ]) || message
        : message}
    </MainTitle>
  );
};

export default BasicHeader;
