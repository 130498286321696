import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginUpdateProfileProps } from "./LoginUpdateProfile.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginUpdateProfile = memo(
  ({ kcContext, kcProps }: LoginUpdateProfileProps) => {
    const { url, user } = kcContext;
    const { getSubmitButton, getTextField } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-form-login"
      >
        <Stack spacing={3} className={getClassName("kcFormGroupClass")}>
          <Box id="kc-form-update-username-wrapper">
            {getTextField({
              name: "username",
              id: "kc-form-update-username",
              control: control,
              rules: GetRules("username"),
              defaultValue: user.username,
            })}
          </Box>
          <Box id="kc-form-update-email-wrapper">
            {getTextField({
              name: "email",
              id: "kc-form-update-email",
              control: control,
              rules: GetRules("email"),
              defaultValue: user.email,
            })}
          </Box>
          <Box id="kc-form-update-firstname-wrapper">
            {getTextField({
              name: "firstName",
              id: "kc-form-update-firstName",
              control: control,
              rules: GetRules("firstName"),
              defaultValue: user.firstName,
            })}
          </Box>
          <Box id="kc-form-update-lastname-wrapper">
            {getTextField({
              name: "lastName",
              id: "kc-form-update-lastName",
              control: control,
              rules: GetRules("lastName"),
              defaultValue: user.lastName,
            })}
          </Box>
          <Box sx={{ mt: 1 }} className={getClassName("kcFormGroupClass")}>
            {getSubmitButton({
              kcProps: kcProps,
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default LoginUpdateProfile;
