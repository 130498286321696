import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useComponentsContext } from "../../../contexts";
import { RememberMeProps } from "./RememberMe.d";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext/KcMessagesContext";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

export default function RememberMe(props: RememberMeProps) {
  const { checkerProps, typographyProps, kcProps, checkBox, text, ...others } =
    props;
  const { getTypography, getCheckbox } = useComponentsContext();
  const { msg } = useKcMessagesContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <FormGroup>
      <Box
        id="kc-remember-me-container"
        className={getClassName("kcFormOptionsWrapperClass")}
        display="flex"
        alignItems="center"
        marginLeft={2}
        {...others}
      >
        <FormControlLabel
          control={
            checkBox
              ? checkBox({ ...checkerProps })
              : getCheckbox({
                  ...checkerProps,
                }) || <Checkbox {...checkerProps} />
          }
          label={
            text ||
            getTypography({
              children: msg("rememberMe"),
              sx: { marginLeft: 2 },
              ...typographyProps,
            })
          }
        />
      </Box>
    </FormGroup>
  );
}
