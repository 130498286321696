import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import {
  UseGetCustomClassNameProps,
  UseGetCustomClassNameType,
} from "./useGetCustomClassName.d";

export function useGetCustomClassName(
  props: UseGetCustomClassNameProps
): UseGetCustomClassNameType {
  const { doUseDefaultCss, classes } = props;

  const { getClassName } = useGetClassName({
    doUseDefaultCss: doUseDefaultCss ?? true,
    classes: classes ?? {},
  });

  return { getClassName };
}

export default useGetCustomClassName;
