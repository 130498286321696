import React from "react";
import { useComponentsContext, useKcMessagesContext } from "../../../contexts";
import { HeaderProps } from "./Header.d";
import { KcContextCustom } from "../../../kcContext.d";

const Header = (props: HeaderProps) => {
  const { kcContext, titleProps } = props;
  const { pageId, client } = kcContext;
  const { advancedMsgStr, msg } = useKcMessagesContext();
  const { getBasicHeader } = useComponentsContext();

  if (!kcContext) {
    return <></>;
  }

  switch (pageId) {
    case "login.ftl":
    case "login-dynamic-username.ftl":
      return getBasicHeader({ message: "loginAccountTitle", ...titleProps });
    case "delete-account-confirm.ftl":
      return getBasicHeader({ message: "deleteAccountConfirm", ...titleProps });
    case "secret-question.ftl":
      return getBasicHeader({
        message: "secret_question.secret_question.title",
        ...titleProps,
      });
    case "secret-question-config.ftl":
      return getBasicHeader({
        message:
          kcContext.secret_question_form_add_or_edit === "add"
            ? "secret_question.secret_question.title.create"
            : "secret_question.secret_question.title.edit",
        ...titleProps,
      });
    case "idp-review-user-profile.ftl":
      return getBasicHeader({
        message: "loginIdpReviewProfileTitle",
        ...titleProps,
      });
    case "login-config-totp.ftl":
      return getBasicHeader({ message: "loginTotpTitle", ...titleProps });
    case "login-idp-link-confirm.ftl":
      return getBasicHeader({ message: "confirmLinkIdpTitle", ...titleProps });
    case "login-idp-link-email.ftl":
      return getBasicHeader({
        message: "emailLinkIdpTitle",
        argsMessage: [kcContext.idpAlias],
        ...titleProps,
      });
    case "login-otp.ftl":
    case "da-otp-mail-page.ftl":
      return getBasicHeader({
        message: kcContext?.auth?.attemptedUsername || "doLogIn",
        mainTitleProps: { variant: "h3" },
        ...titleProps,
      });
    case "error.ftl":
      return getBasicHeader({ message: "errorTitle", ...titleProps });
    case "info.ftl":
      return getBasicHeader({
        message: kcContext.messageHeader || kcContext.message?.summary,
        ...titleProps,
      });
    case "login-password.ftl":
      return getBasicHeader({
        message: (kcContext as KcContextCustom.LoginPassword).login.username,
        ...titleProps,
      });
    case "login-reset-password.ftl":
      return getBasicHeader({ message: "emailForgotTitle", ...titleProps });
    case "login-update-password.ftl":
      return getBasicHeader({ message: "updatePasswordTitle", ...titleProps });
    case "login-update-profile.ftl":
    case "restricted-profile-review.ftl":
      return getBasicHeader({ message: "updateProfileMessage", ...titleProps });
    case "login-username.ftl":
      return getBasicHeader({
        message: msg("loginAccountTitle", advancedMsgStr("yourAccount")),
        ...titleProps,
      });
    case "login-verify-email.ftl":
      return getBasicHeader({ message: "emailVerifyTitle", ...titleProps });
    case "login-page-expired.ftl":
      return getBasicHeader({ message: "pageExpiredTitle", ...titleProps });
    case "register-dynamic-username.ftl":
    case "register-user-profile.ftl":
    case "register.ftl":
      return getBasicHeader({
        client: client,
        message: "doRegister",
        ...titleProps,
      });
    case "otp-sms.ftl":
      return getBasicHeader({
        client: client,
        message: "otp-sms-authentication.text.otp-sms.title",
        ...titleProps,
      });
    case "webauthn-authenticate.ftl":
      return getBasicHeader({
        client: client,
        message: "webauthn-login-title",
        ...titleProps,
      });
    case "webauthn-error.ftl":
      return getBasicHeader({
        client: client,
        message: "webauthn-error-title",
        ...titleProps,
      });
    case "webauthn-register.ftl":
      return getBasicHeader({
        client: client,
        message: "webauthn-registration-title",
        ...titleProps,
      });
    case "update-user-profile.ftl":
      return getBasicHeader({ message: "loginProfileTitle", ...titleProps });
    case "logout-confirm.ftl":
      return getBasicHeader({
        client: client,
        message: "logoutConfirmTitle",
        ...titleProps,
      });
    default:
      return <></>;
  }
};

export default Header;
