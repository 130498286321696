import { Button } from "@mui/material";
import { useKcMessagesContext } from "../../../contexts";
import { PrimaryButtonProps } from "./PrimaryButton.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

export default function PrimaryButton(props: PrimaryButtonProps) {
  const { kcProps, message, ...others } = props;
  const { msgStr } = useKcMessagesContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <Button
      variant="contained"
      className={
        kcProps
          ? `${getClassName("kcButtonClass")} ${getClassName(
              "kcButtonPrimaryClass"
            )} ${getClassName("kcButtonBlockClass")} ${getClassName(
              "kcButtonLargeClass"
            )}`
          : ""
      }
      fullWidth
      {...others}
      sx={{ px: 3, ...props.sx }}
    >
      {message || msgStr("doYes")}
    </Button>
  );
}
