import { useKcMessagesContext, useComponentsContext } from "../../../contexts";
import { MainSubmitButtonProps } from "./MainSubmitButton.d";

export default function MainSubmitButton(props: MainSubmitButtonProps) {
  const { message, ...others } = props;
  const { msgStr } = useKcMessagesContext();
  const { getPrimaryButton } = useComponentsContext();

  return getPrimaryButton({
    type: "submit",
    ...others,
    message: message || msgStr("doSubmit"),
  });
}
