import React, { memo } from "react";
import { Stack } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { ErrorProps } from "./Error.d";

const Error = memo(({ kcContext, ...props }: ErrorProps) => {
  const { client, message, skipLink } = kcContext;
  const { msg, kcSanitize } = useKcMessagesContext();
  const { getLink, getTypography } = useComponentsContext();

  return (
    <Stack spacing={4} id={"kc-error-message"}>
      {getTypography({
        children: <>{kcSanitize(message.summary)}&nbsp;</>,
        className: "instruction",
      })}
      {client?.baseUrl &&
        !skipLink &&
        getLink({
          href: client?.baseUrl,
          id: "backToApplication",
          children: msg("backToApplication"),
        })}
    </Stack>
  );
});

export default Error;
