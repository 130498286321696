/* eslint-disable no-template-curly-in-string */

import { DaMessages } from "./DaMessages";
import defaultMessages from "keycloakify/login/i18n/baseMessages/en";

export const en_messages: DaMessages = {
  ...defaultMessages,
  or: "or",
  accessTo: "Access to",
  title: "Connection to {0}",
  requiredCorrectEmail: "Required correct email",
  yourAccount: "your account",
  totpRequiredDigitsNumber: "Must contain {0} numerical digits",
  endOfActionMessage: "You may close this browser window.",

  // Modify Keycloak messages
  registerTitle: "Register on",
  doLogIn: "Sign in",
  doTryAnotherWay: "Try another way",

  successLogout: "You are logged out",
  failedLogout: "Logout failed",

  loginTotpStep3DeviceName:
    "Provide a Device Name to help you manage your OTP devices.",
  requiredFields: "Required fields",

  userDeletedSuccessfully: "User deleted successfully",
  logoutConfirmHeader: "Do you want to log out?",

  totpAppFreeOTPName: "Free OTP",
  totpAppMicrosoftAuthenticatorName: "Microsoft Authenticator",

  // Form messages
  mobile_number: "Phone number",
  requiredCorrectPhoneNumber:
    "Your phone number must be follow the format 06XXXXXXXX ou 07XXXXXXXX",

  // OTP SMS
  "otp-sms-authentication.mobile-number.invalid":
    "No mobile number found or incorrect mobile number found. Please contact your administrator.",
  "otp-sms-authentication.code.invalid": "Wrong code",
  "otp-sms-authentication.code.internal-error":
    "Internal error. Please contact the administrator of the system",
  "otp-sms-authentication.code.cant-be-sent-before":
    "A new SMS cannot be sent before {0}",
  "otp-sms-authentication.code.expired-code": "Expired code",
  "otp-sms-authentication.mobile-number.no-present":
    "No mobile number found or incorrect mobile number found. Please contact your administrator.",
  "otp-sms-authentication.text.otp-sms.title": "Checking code",
  "otp-sms-authentication.text.otp-sms.prompt":
    "Please fill in with the received code",
  "otp-sms-authentication.text.send-code":
    "A code has been sent on {0}. If it's not your mobile phone, or if you don't receive the sms, please contact your administrator.",
  "otp-sms-authentication.text.resend-code": "Resend a code by SMS",
  "otp-sms-authentication.user.temporarily-disabled":
    "Your account has been temporarily blocked due to too many authentication failures. Please login later.",
  "user.attributes.mobile_number": "Mobile number",
  "requiredAction.da-otp-sms-required-action": "Type the SMS code",

  // Secret Question
  "secret_question.secret_question.title": "Secret question",
  "secret_question.secret_question.title.create": "Create secret question",
  "secret_question.secret_question.title.edit": "Edit secret question",
  "secret_question.secret_question.label": "What is your secret question ?",
  "secret_question.secret_question.setup_explanation":
    "The secret question will allow you to reset your password if necessary.<br />The answer you type should match exactly what you are going to type here. (<strong>Renault</strong> is different from <strong>renault</strong>)",
  "secret_question.secret_question.placeholder": "Brand of your first car ?",
  "secret_question.secret_answer.label":
    "What is the answer of your secret question ?",
  "secret_question.secret_answer.placeholder": "Renault",
  "secret_question.secret_answer.please-answer-question":
    "In order to change your password, please answer your secret question",
  "secret_question.secret_answer_confirm.label": "Confirm your answer",
  "secret_question.secret_answer_confirm.placeholder": "Renault",
  "secret_question.error.secret_question.invalid":
    "The secret question is invalid, it cannot be empty.",
  "secret_question.error.secret_answer.invalid":
    "The answer to the secret question is invalid, it cannot be empty.",
  "secret_question.error.secret_answer.less_than_2":
    "The answer must contain at least 2 characters.",
  "secret_question.secret_answer_match":
    "The answer and its confirmation must match.",
  "secret_question.error.secret_answer.bad_secret": "Your answer is not valid.",
  "secret_question.error.secret_answer.too_much_attempt":
    "You have tried too many answers for the secret question.",
  "secret_question.user.temporarily-disabled":
    "Your account has been temporarily blocked due to too many input failures. Please try again later.",
  "secret_question.do_submit": "Submit",
  "requiredAction.da-secret-question-required-action":
    "Type the secret question",
  "secret-question-text": "Update your secret question and secret answer",
  "da-secret-question-credential-provider": "Secret question and answer",
  secret_question: "Secret question",

  // accept_terms_and_conditions
  "accept_terms_and_conditions.page": "Page",
  "accept_terms_and_conditions.page.previous": "Previous",
  "accept_terms_and_conditions.page.next": "Next",
  "accept_terms_and_conditions.title": "Accept terms and conditions",
  "accept_terms_and_conditions.accept_title":
    "Accept terms and conditions to proceed :",
  "accept_terms_and_conditions.accept_check_title":
    "By checking this box I accept the terms and conditions presented",
  "accept_terms_and_conditions.accept_message": "Validate",
  "accept_terms_and_conditions.for": "for",
  "accept_terms_and_conditions.user-temporarily-disabled":
    "User temporarily disabled!",
  "accept_terms_and_conditions.no_file": "No PDF file specified.",
  "accept_terms_and_conditions.error_file": "Error loading the TOS.",
  "accept_terms_and_conditions.loading_page": "Loading page.",

  //DA Magic Link
  "da-magic-link.error.email-not-sent":
    "An error occurred while sending the email containing the login link. Please try again later or contact an administrator if the problem persists.",
  "da-magic-link.email.subject": "[{0}] Login link",
  "da-magic-link.email.body.html":
    "<br/>Your login link : <b>{0}</b> <br/>This single-use link is valid for {1} minutes. <br/><br/>Your {2} team<br/><br/>",
  "da-magic-link.email.body.text":
    "Your login link : {0}. This single-use link is valid for {1} minutes. Your {2} team.",
  "da-magic-link.display.message":
    "An email containing your login link has just been sent to you.",
  "da-magic-link.display.info":
    "This single-use link is valid for {0} minutes. Sending a new email is possible after 1 minute of waiting.",
  "da-magic-link.resend-button": "Resend a link by email",
  "da-magic-link.error.mail":
    "Error while sending email, please retry in a few moment",
  "da-magic-link.error.invalid": "This is not a valid email address",
  "da-magic-link.error.inexist": "This user does not exist",
  "da-magic-link.error.config":
    "The submitted link is invalid. A new link will be sent to you by email.",
  "da-magic-link.error.credentials":
    "An internal server error occurred. Please try again later or contact an administrator if the problem persists.",
  "da-magic-link.error.expired-link":
    "The submitted link has expired. A new link will be sent to you by email.",
  "da-magic-link.error.cant-be-sent-before":
    "A new email cannot be sent before {0}",
  "da-magic-link.error.invalid-link":
    "Invalid link. Please verify that you are entering the link from the last email you received.",
  "da-magic-link.timer-unit": "s",

  // DA Otp Mail Page
  "da-otp-mail.email.error": "Failed to send mail. Please try again later.",
  "da-otp-mail.email.subject": "[{0}] Login code",
  "da-otp-mail.email.body.html":
    "<br/>Your login code : <b>{0}</b> <br/>This single-use code is valid for {1} minutes. <br/><br/>Your {2} team<br/><br/>",
  "da-otp-mail.email.body.text":
    "Your login code : {0}. This single-use code is valid for {1} minutes. Your {2} team.",
  "da-otp-mail.display.message":
    "An email containing your login code was sent to you.",
  "da-otp-mail.display.info": "This single-use code is valid for {0} minutes.",
  "da-otp-mail.code": "login code",
  "da-otp-mail.resend-button": "Resend a code by email",
  "da-otp-mail.timer-unit": "s",

  // Dynamic Username
  "da-dynamic-username": "Dynamic username",

  // Footer
  "da-footer-services": "Services",
  "da-footer-contact": "Contact",
  "da-footer-terms-of-use": "Legal Notice",
  "da-footer-privacy-policy": "Privacy Policy",

  // Login Verify Email
  loginVerifyEmailButton: "Have you checked your email?",
  loginVerifyEmailButton2: "to continue authentication.",
  emailVerifyInstruction2: "Haven't received an email?",

  // Additional French keys
  registerWithTitle: "Register with {0}",
  registerWithTitleHtml: "{0}",
  termsTitleHtml: "Terms and conditions",
  federatedIdentityEmailExistsMessage:
    "A user with this email already exists. Please log into your account to link it.",
  shouldBeEqual: "Should be equal to",
  shouldBeDifferent: "Should be different from",
  shouldMatchPattern: "Should match",
  mustBeAnInteger: "Must be an integer",
  notAValidOption: "Invalid option",
  selectAnOption: "Select",

  // Restricted Profile Review
  "da.restricted-profile.department.label": "Department",
};

export default en_messages;
