import { FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useKcMessagesContext, useFormContext } from "../../../contexts";
import { MainProfileOptionsProps } from "./MainProfileOptions.d";
import { Controller } from "react-hook-form";
import { useState } from "react";
import { Attribute } from "keycloakify/login/kcContext/KcContext";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { kcContext } from "../../../kcContext";

export default function MainProfileOptions(props: MainProfileOptionsProps) {
  const {
    attribute,
    options,
    kcProps,
    control,
    rules,
    defaultValue,
    displayname,
    disableKCErrors,
    errorname,
    requiredChar,
    ...others
  } = props;
  const multiple = props.multiple;
  const defaultValues =
    (attribute as Attribute & { values: string })?.values ||
    attribute.value ||
    attribute.required
      ? options[0]
      : [];
  const { advancedMsgStr } = useKcMessagesContext();
  const { getProfileRules } = useFormContext();
  const [values, setValues] = useState<any[]>(
    multiple
      ? Array.isArray(defaultValues)
        ? (defaultValues as string[])
        : [defaultValues]
      : []
  );

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const { messagesPerField } = kcContext || {};

  return (
    <Controller
      name={multiple ? "none" : attribute.name}
      control={control}
      defaultValue={multiple ? [options[0]] : attribute.value || options[0]}
      rules={(rules !== undefined ? rules : getProfileRules(attribute)) || {}}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <InputLabel
            className={getClassName("kcLabelClass")}
            error={
              (!disableKCErrors &&
                messagesPerField?.existsError(errorname || attribute.name)) ||
              error !== undefined
            }
            sx={{ mb: 1 }}
            id={`${attribute.name}-label`}
          >
            {`${
              displayname ||
              advancedMsgStr(attribute.displayName || "") ||
              attribute.displayName
            }${attribute.required ? requiredChar || " *" : ""}`}
          </InputLabel>
          <Select
            id={attribute.name}
            value={multiple ? values : value}
            defaultValue={options[0]}
            error={
              (!disableKCErrors &&
                messagesPerField?.existsError(errorname || attribute.name)) ||
              error !== undefined
            }
            disabled={attribute.readOnly}
            {...others}
            onChange={
              multiple
                ? (event) => {
                    const newValue = event.target.value;
                    if (newValue.length > 0) {
                      setValues(
                        typeof newValue === "string"
                          ? newValue.split(",")
                          : newValue
                      );
                      onChange(newValue[0]);
                    }
                  }
                : onChange
            }
            sx={{ width: "100%", ...props.sx }}
            name={multiple ? "" : attribute.name}
          >
            {options.map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
                {multiple && values.includes(option) && (
                  <input
                    hidden
                    value={option}
                    name={attribute.name}
                    onChange={() => {}}
                  />
                )}
              </MenuItem>
            ))}
          </Select>
          {((!disableKCErrors &&
            messagesPerField?.existsError(errorname || attribute.name)) ||
            error !== undefined) && (
            <FormHelperText
              error={
                (!disableKCErrors &&
                  messagesPerField?.existsError(errorname || attribute.name)) ||
                error !== undefined
              }
            >
              <>
                {!disableKCErrors &&
                messagesPerField?.existsError(errorname || attribute.name) ? (
                  <>
                    {error?.message && (
                      <>
                        {error?.message}
                        <br />
                      </>
                    )}
                    {messagesPerField?.get(errorname || attribute.name)}
                  </>
                ) : (
                  error?.message
                )}
              </>
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
