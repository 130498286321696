import { Stack, Box } from "@mui/material";
import { Attribute } from "keycloakify/login/kcContext/KcContext";
import { useComponentsContext } from "../../contexts";
import { UserProfileCommonsProps } from "./UserProfileCommons.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const UserProfileCommons = (props: UserProfileCommonsProps) => {
  const { profile, control, afterField, beforeField, ...others } = props;
  let currentGroup: string = "";
  const { getTypography, getUserProfileField } = useComponentsContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const getGroupHeader = (attribute: Attribute) => {
    const groupName = attribute.group || "";
    if (groupName !== currentGroup) {
      currentGroup = groupName;
      if (currentGroup !== "") {
        return (
          <Box className={getClassName("kcFormGroupClass")}>
            <Box className={getClassName("kcContentWrapperClass")}>
              {getTypography({
                id: `header-${groupName}`,
                className: getClassName("kcFormGroupHeader"),
                children:
                  profile.groupDisplayHeader === ""
                    ? attribute.groupDisplayHeader
                    : groupName,
                variant: "h5",
              })}
            </Box>
            {profile.groupDisplayDescription && (
              <Box className={getClassName("kcLabelWrapperClass")}>
                {getTypography({
                  id: `description-${groupName}`,
                  className: getClassName("kcLabelClass"),
                  children: profile.groupDisplayDescription,
                  variant: "subtitle2",
                })}
              </Box>
            )}
          </Box>
        );
      }
    }
  };

  return (
    <Stack spacing={2}>
      {profile?.attributes.map((attribute) => {
        return (
          <Stack spacing={2} key={attribute.name}>
            {getGroupHeader(attribute)}
            {beforeField && beforeField(attribute)}
            <Box
              className={`${getClassName("kcFormGroupClass")} ${
                attribute.name
              }-profile-field`}
            >
              {getUserProfileField({
                attribute: attribute,
                control: control,
                ...others,
              })}
            </Box>
            {afterField && afterField(attribute)}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default UserProfileCommons;
