import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { OtpSelectorItemProps } from "./OtpSelectorItem.d";

export default function OtpSelectorItem(props: OtpSelectorItemProps) {
  const { selectedValue, value, label } = props;

  return (
    <span
      style={{
        border: "1px solid",
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 8,
        maxWidth: 200,
        borderColor: value === selectedValue ? "blue" : "inherit",
      }}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "50% 50%",
            px: "4px",
            borderColor: value === selectedValue ? "blue" : "inherit",
          }}
        >
          <i
            className="lni lni-mobile"
            style={{
              height: "20px",
              color: value === selectedValue ? "blue" : "inherit",
            }}
          ></i>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              width: "110px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            {label}
          </Typography>
        </Box>
      </Stack>
    </span>
  );
}
