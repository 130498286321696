import React, { memo } from "react";
import { Stack } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { InfoProps } from "./Info.d";

const Info = memo(({ kcContext, ...props }: InfoProps) => {
  const {
    pageRedirectUri,
    actionUri,
    client,
    skipLink,
    requiredActions,
    message,
    messageHeader,
  } = kcContext;
  const { msg, kcSanitize, advancedMsg, advancedMsgStr } =
    useKcMessagesContext();
  const { getList, getLink, getTypography, getListItem } =
    useComponentsContext();

  const getLinkElement = (link: string, message: string) =>
    getLink({
      href: link,
      id: "click-here-button",
      children: advancedMsg(message),
    });

  const getCorrectLink = () => {
    if (pageRedirectUri)
      return getLinkElement(pageRedirectUri, "backToApplication");
    if (actionUri) return getLinkElement(actionUri, "proceedWithAction");
    if (client?.baseUrl)
      return getLinkElement(client?.baseUrl, "backToApplication");
    return undefined;
  };
  const summary = message?.summary.replaceAll(/{[0-9]+}/g, "") || "";

  return (
    <Stack id={"kc-info-message"} spacing={2}>
      {messageHeader &&
        getTypography({
          className: "instruction",
          children: kcSanitize(advancedMsgStr(summary)),
        })}
      {requiredActions &&
        getList({
          id: "required-actions-list",
          children: requiredActions?.map((requiredAction) =>
            getListItem({
              id: `required-actions-item-${requiredAction}`,
              children: msg(`requiredAction.${requiredAction}`),
            })
          ),
        })}
      {(!skipLink && getCorrectLink()) ||
        (!requiredActions &&
          getTypography({
            className: "close-window-text",
            children: advancedMsg("endOfActionMessage"),
          }))}
    </Stack>
  );
});

export default Info;
