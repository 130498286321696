import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginOtpProps } from "./LoginOtp.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginOtp = memo(({ kcContext, kcProps }: LoginOtpProps) => {
  const { url, otpLogin, messagesPerField } = kcContext;
  const { msgStr } = useKcMessagesContext();
  const { getTextField, getSubmitButton, getOtpSelector } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  const [value, setValue] = React.useState<string>(
    otpLogin.selectedCredentialId || otpLogin?.userOtpCredentials[0]?.id
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  if (messagesPerField.get("otp")) {
    // Register "otp" as a field name in messagesPerField funtions
  }

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-totp-settings-form"
      className={getClassName("kcFormClass")}
    >
      <Stack spacing={2}>
        {otpLogin.userOtpCredentials?.length > 1 && (
          <Box className={getClassName("kcFormGroupClass")}>
            <Box
              className={getClassName("kcInputWrapperClass")}
              justifyContent="center"
              display="flex"
            >
              {getOtpSelector({
                otpLogin: otpLogin,
                value: value,
                handleChange: handleChange,
              })}
            </Box>
          </Box>
        )}
        <Box className={getClassName("kcFormGroupClass")}>
          {getTextField({
            name: "otp",
            id: "otp",
            control: control,
            rules: GetRules("totp", 6),
            displayname: msgStr("loginOtpOneTime"),
            autoComplete: "off",
            errorname: "totp",
            inputRef: (input) => input && input.focus(),
          })}
        </Box>
        <Box
          id="kc-form-buttons"
          className={getClassName("kcFormButtonsClass")}
          width={"100%"}
          display="flex"
          justifyContent="center"
        >
          {getSubmitButton({
            id: "kc-login",
            name: "login",
            kcProps: kcProps,
            value: msgStr("doLogIn"),
          })}
        </Box>
      </Stack>
    </form>
  );
});

export default LoginOtp;
