import { createUseI18n } from "keycloakify/login/i18n/i18n";
import { DaMessageKey } from "./DaMessages";
import en_messages from "./en_messages";
import fr_messages from "./fr_messages";

export const { useI18n } = createUseI18n<DaMessageKey>({
  en: en_messages,
  fr: fr_messages,
});

export type I18n = ReturnType<typeof useI18n>;
