import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginConfigTotpProps } from "./LoginConfigTotp.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginConfigTotp = memo(({ kcContext, kcProps }: LoginConfigTotpProps) => {
  const { totp, url, mode, isAppInitiatedAction } = kcContext;

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const { msg, msgStr, advancedMsgStr } = useKcMessagesContext();
  const {
    getTextField,
    getLink,
    getTypography,
    getSecondaryButton,
    getSubmitButton,
  } = useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  const supportedApplications =
    totp?.supportedApplications?.filter((app) => app !== "totpAppGoogleName") ||
    [];

  const totpTypeMessage =
    msgStr("loginTotpType") + ":" + msgStr("loginTotp." + totp?.policy?.type);

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) =>
        onSubmit(event, isValid, trigger, isAppInitiatedAction)
      }
      method="post"
      id="kc-totp-settings-form"
      className={getClassName("kcFormClass")}
    >
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        <ol
          id="kc-totp-settings"
          style={{ paddingLeft: "16px", fontSize: "12px" }}
        >
          <Stack spacing={1} className={getClassName("kcFormGroupClass")}>
            <li>
              <Stack spacing={1}>
                {getTypography({
                  color: "gray",
                  variant: "body2",
                  id: "login-totp-step-1",
                  children: msg("loginTotpStep1"),
                })}
                <ul id="kc-totp-supported-apps">
                  {supportedApplications.map((app) =>
                    getTypography({
                      variant: "subtitle2",
                      id: "login-totp-apps",
                      key: advancedMsgStr(app),
                      children: advancedMsgStr(app),
                    })
                  )}
                </ul>
              </Stack>
            </li>
            {mode && mode === "manual" ? (
              <>
                <li>
                  <Stack spacing={2}>
                    {getTypography({
                      color: "gray",
                      variant: "body2",
                      id: "login-totp-manual-step-2",
                      children: msg("loginTotpManualStep2"),
                    })}
                    {getTypography({
                      align: "center",
                      variant: "h5",
                      id: "login-totp-manual-code",
                      children: totp.totpSecretEncoded,
                    })}
                    {getLink({
                      href: totp.qrUrl,
                      id: "mode-barcode",
                      children: msg("loginTotpScanBarcode"),
                    })}
                  </Stack>
                </li>
                <li>
                  {getTypography({
                    color: "gray",
                    variant: "body2",
                    id: "login-totp-manual-step-3",
                    children: msg("loginTotpManualStep3"),
                  })}

                  <ul id="kc-totp-list">
                    <li id="kc-totp-type-wrapper">
                      {getTypography({
                        variant: "body2",
                        children: totpTypeMessage,
                        id: "kc-totp-type",
                      })}
                    </li>
                    <li id="kc-totp-algorithm-wrapper">
                      {getTypography({
                        variant: "body2",
                        children: `${msgStr("loginTotpAlgorithm")}: ${
                          totp?.policy?.algorithm
                        }`,
                        id: "kc-totp-algorithm",
                      })}
                    </li>
                    <li id="kc-totp-digits-wrapper">
                      {getTypography({
                        variant: "body2",
                        children: `${msgStr("loginTotpDigits")}: ${
                          totp?.policy?.digits
                        }`,
                        id: "kc-totp-digits",
                      })}
                    </li>
                    {totp.policy.type === "totp" && (
                      <li id="kc-totp-period-wrapper">
                        {getTypography({
                          variant: "body2",
                          children: `${msgStr("loginTotpInterval")}: ${
                            totp?.policy?.period
                          }`,
                          id: "kc-totp-period",
                        })}
                      </li>
                    )}
                    {totp.policy.type === "hotp" && (
                      <li id="kc-totp-counter-wrapper">
                        {getTypography({
                          variant: "body2",
                          children: `${msgStr("loginTotpCounter")}: ${
                            totp?.policy?.initialCounter
                          }`,
                          id: "kc-totp-counter",
                        })}
                      </li>
                    )}
                  </ul>
                </li>
              </>
            ) : (
              <li>
                {getTypography({
                  color: "gray",
                  variant: "body2",
                  id: "login-totp-step-2",
                  children: msg("loginTotpStep2"),
                })}
                <Box
                  id="totp-image-container"
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: "-44px" }}
                >
                  <img
                    id="kc-totp-secret-qr-code"
                    src={`data:image/png;base64, ${totp.totpSecretQrCode}`}
                    alt="Figure: Barcode"
                    width={"240px"}
                  />
                  <Box id="totp-manual-container">
                    {getLink({
                      href: totp.manualUrl,
                      id: "mode-manual",
                      children: msg("loginTotpUnableToScan"),
                    })}
                  </Box>
                </Box>
              </li>
            )}
            <li>
              {getTypography({
                color: "gray",
                variant: "body2",
                id: "login-totp-step-3",
                children: msg("loginTotpStep3"),
              })}
              {getTypography({
                color: "gray",
                variant: "body2",
                id: "login-totp-step-3-device-name",
                children: msg("loginTotpStep3DeviceName"),
              })}
            </li>
          </Stack>
        </ol>
        <Box className={getClassName("kcFormGroupClass")}>
          {getTextField({
            name: "totp",
            id: "totp",
            control: control,
            rules: GetRules("totp", totp?.policy?.digits),
            displayname: msgStr("authenticatorCode"),
            autoComplete: "off",
          })}
        </Box>
        <input
          type="hidden"
          id="totpSecret"
          name="totpSecret"
          value={totp.totpSecret}
        />
        {mode && <input type="hidden" id="mode" name="mode" value={mode} />}
        <Box className={getClassName("kcFormGroupClass")}>
          {getTextField({
            name: "userLabel",
            id: "totp",
            control: control,
            rules: GetRules("requiredField"),
            displayname: msgStr("loginTotpDeviceName"),
          })}
        </Box>
        {isAppInitiatedAction ? (
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormGroupClass")}
            display="flex"
            flexDirection="column"
            sx={{ gridGap: 16 }}
          >
            {getSubmitButton({
              id: "saveTOTPBtn",
              className: `${getClassName("kcButtonClass")} ${getClassName(
                "kcButtonPrimaryClass"
              )} ${getClassName("kcButtonLargeClass")}`,
            })}
            {getSecondaryButton({
              kcProps: kcProps,
              message: msg("doCancel"),
              name: "cancel-aia",
              id: "cancelTOTPBnt",
              type: "submit",
              value: "true",
            })}
          </Box>
        ) : (
          <>
            {getSubmitButton({
              id: "saveTOTPBtn",
            })}
          </>
        )}
      </Stack>
    </form>
  );
});

export default LoginConfigTotp;
