import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { DaOtpMailPageProps } from "./DaOtpMailPage.d";
import { stringFromDateTime } from "../../scripts/common";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const DaOtpMailPage = memo(({ kcContext, kcProps }: DaOtpMailPageProps) => {
  const { url, TTL, da_otp_mail_cant_be_sent_before } = kcContext;
  const { msgStr, advancedMsgStr } = useKcMessagesContext();
  const { getSubmitButton, getTextField, getTypography, getSecondaryButton } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const nextEmailTime: number = Date.parse(
    stringFromDateTime(da_otp_mail_cant_be_sent_before)
  );
  const [timer, setTimer] = React.useState<number>(
    (nextEmailTime - Date.now()) / 1000
  );

  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  const resendCodeByEmail = () => {
    window.location.reload();
  };
  const timerInterval = React.useRef<NodeJS.Timer>();
  function startTimer() {
    timerInterval.current = setInterval(
      () => setTimer((timer) => timer - 1),
      1000
    );
  }

  React.useEffect(() => {
    if (!timerInterval.current) startTimer();
    if (timer <= 0) clearInterval(timerInterval.current);
  }, [timer]);

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-otp-login-form"
      className={getClassName("kcFormClass")}
    >
      <Stack spacing={2} id="da-otp-mail-container">
        <Box>
          {getTypography({
            id: "da_otp_mail_page_text1",
            children: advancedMsgStr("da-otp-mail.display.message"),
          })}
          {getTypography({
            id: "da_otp_mail_page_text2",
            children: advancedMsgStr("da-otp-mail.display.info", TTL || "0"),
          })}
        </Box>
        <Box className={getClassName("kcFormGroupClass")}>
          {getTextField({
            name: "otp",
            id: "otp",
            control: control,
            autoComplete: "off",
            inputRef: (input) => input && input.focus(),
            rules: GetRules("requiredField"),
            displayname: advancedMsgStr("da-otp-mail.code"),
            placeholder: "",
            errorname: "totp",
          })}
        </Box>

        <Box
          className={getClassName("kcFormButtonsClass")}
          id="kc-form-buttons"
        >
          {getSecondaryButton({
            id: "kc-resend",
            name: "resend",
            kcProps: kcProps,
            message:
              advancedMsgStr("da-otp-mail.resend-button") +
              (timer > 0
                ? " : " +
                  timer.toFixed(0) +
                  advancedMsgStr("da-otp-mail.timer-unit")
                : ""),
            onClick: () => resendCodeByEmail(),
            disabled: timer > 0,
          })}
          {getSubmitButton({
            id: "kc-login",
            name: "login",
            kcProps: kcProps,
            message: msgStr("doLogIn"),
            sx: { mt: 2 },
          })}
        </Box>
      </Stack>
    </form>
  );
});

export default DaOtpMailPage;
