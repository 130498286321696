import {
  Error,
  Info,
  Login,
  LoginConfigTotp,
  LoginPassword,
  LoginResetPassword,
  LoginUpdatePassword,
  LoginMagicLink,
  LoginMagicLinkInfo,
  LoginUpdateProfile,
  LoginUsername,
  LoginVerifyEmail,
  PageExpired,
  Register,
  RegisterUserProfile,
  LoginOtp,
  LoginIdpLinkConfirm,
  LoginIdpLinkEmail,
  OtpSms,
  WebAuthnAuthenticate,
  WebAuthnError,
  WebAuthnRegister,
  UpdateUserProfile,
  DeleteAccountConfirm,
  IdpReviewUserProfile,
  SecretQuestion,
  SecretQuestionConfig,
  AcceptTermsAndConditions,
  LogoutConfirm,
  DaOtpMailPage,
  SamlPostForm,
  SelectAuthenticator,
  LoginDynamicUsername,
  RestrictedProfileReview,
  RegisterDynamicUsername,
} from "../../pages";
import { KcDateTime, isStringProps, onSubmitType } from "./common.d";
import { KcContext } from "../../kcContext";
import { GenericI18n } from "keycloakify/login/i18n/i18n";
import { DaMessageKey } from "../../messages";

// CONSTANTS

export const rEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

// FUNCTIONS

export const onSubmit: onSubmitType = (
  event,
  isValid,
  trigger,
  isAppInitiatedAction
) => {
  let result = true;
  if (
    isAppInitiatedAction &&
    event.nativeEvent?.submitter.name === "cancel-aia"
  ) {
    return result;
  }
  trigger().then((res: boolean) => {
    if (!res) {
      event.preventDefault();
      result = false || isValid;
    }
  });
  return result;
};

export const getPage = (
  kcContext: KcContext,
  i18n: GenericI18n<DaMessageKey>
) => {
  const kcProps = { i18n, doUseDefaultCss: true };

  switch (kcContext.pageId) {
    case "login.ftl":
      return <Login {...{ kcContext, ...kcProps }} />;
    case "login-config-totp.ftl":
      return <LoginConfigTotp {...{ kcContext, ...kcProps }} />;
    case "login-idp-link-confirm.ftl":
      return <LoginIdpLinkConfirm {...{ kcContext, ...kcProps }} />;
    case "login-idp-link-email.ftl":
      return <LoginIdpLinkEmail {...{ kcContext, ...kcProps }} />;
    case "login-otp.ftl":
      return <LoginOtp {...{ kcContext, ...kcProps }} />;
    case "register.ftl":
      return <Register {...{ kcContext, ...kcProps }} />;
    case "login-reset-password.ftl":
      return <LoginResetPassword {...{ kcContext, ...kcProps }} />;
    case "login-update-password.ftl":
      return <LoginUpdatePassword {...{ kcContext, ...kcProps }} />;
    case "error.ftl":
      return <Error {...{ kcContext, ...kcProps }} />;
    case "info.ftl":
      return <Info {...{ kcContext, ...kcProps }} />;
    case "login-page-expired.ftl":
      return <PageExpired {...{ kcContext, ...kcProps }} />;
    case "login-verify-email.ftl":
      return <LoginVerifyEmail {...{ kcContext, ...kcProps }} />;
    case "login-username.ftl":
      return <LoginUsername {...{ kcContext, ...kcProps }} />;
    case "login-password.ftl":
      return <LoginPassword {...{ kcContext, ...kcProps }} />;
    case "login-update-profile.ftl":
      return <LoginUpdateProfile {...{ kcContext, ...kcProps }} />;
    case "restricted-profile-review.ftl":
      return <RestrictedProfileReview {...{ kcContext, ...kcProps }} />;
    case "register-user-profile.ftl":
      return <RegisterUserProfile {...{ kcContext, ...kcProps }} />;
    case "otp-sms.ftl":
      return <OtpSms {...{ kcContext, ...kcProps }} />;
    case "webauthn-authenticate.ftl":
      return <WebAuthnAuthenticate {...{ kcContext, ...kcProps }} />;
    case "webauthn-error.ftl":
      return <WebAuthnError {...{ kcContext, ...kcProps }} />;
    case "webauthn-register.ftl":
      return <WebAuthnRegister {...{ kcContext, ...kcProps }} />;
    case "update-user-profile.ftl":
      return <UpdateUserProfile {...{ kcContext, ...kcProps }} />;
    case "delete-account-confirm.ftl":
      return <DeleteAccountConfirm {...{ kcContext, ...kcProps }} />;
    case "idp-review-user-profile.ftl":
      return <IdpReviewUserProfile {...{ kcContext, ...kcProps }} />;
    case "secret-question.ftl":
      return <SecretQuestion {...{ kcContext, ...kcProps }} />;
    case "secret-question-config.ftl":
      return <SecretQuestionConfig {...{ kcContext, ...kcProps }} />;
    case "select-authenticator.ftl":
      return <SelectAuthenticator {...{ kcContext, ...kcProps }} />;
    case "accept-terms-and-conditions.ftl":
      return <AcceptTermsAndConditions {...{ kcContext, ...kcProps }} />;
    case "logout-confirm.ftl":
      return <LogoutConfirm {...{ kcContext, ...kcProps }} />;
    case "da-otp-mail-page.ftl":
      return <DaOtpMailPage {...{ kcContext, ...kcProps }} />;
    case "da-magic-link-page.ftl":
      return <LoginMagicLink {...{ kcContext, ...kcProps }} />;
    case "da-magic-link-view-email.ftl":
      return <LoginMagicLinkInfo {...{ kcContext, ...kcProps }} />;
    case "saml-post-form.ftl":
      return <SamlPostForm {...{ kcContext, ...kcProps }} />;
    case "login-dynamic-username.ftl":
      return <LoginDynamicUsername {...{ kcContext, ...kcProps }} />;
    case "register-dynamic-username.ftl":
      return <RegisterDynamicUsername {...{ kcContext, ...kcProps }} />;
    default:
      return undefined;
  }
};

export const isString = (value: isStringProps) =>
  typeof value === "string" || value instanceof String;

export const strip = (str: string): string => {
  return str.replace(/\s/g, "");
};

export const isBlankString = (value: string): boolean => {
  return value === null || value === undefined || strip(value) === "";
};

export const isNotBlankString = (value: string): boolean => {
  return !isBlankString(value);
};

export const stringFromDateTime = (datetime: KcDateTime): string => {
  return getKcDate(datetime) + " " + getKcTime(datetime);
};

const formatDateComponent = (attr: number): string => {
  return attr < 10 ? "0" + attr : String(attr);
};

const getKcDate = (datetime: KcDateTime): string => {
  const { dayOfMonth, month, year } = datetime;
  return (
    formatDateComponent(month.value) +
    " " +
    formatDateComponent(dayOfMonth) +
    " " +
    strip(String(year).replace(",", ""))
  );
};

const getKcTime = (datetime: KcDateTime): string => {
  const { second, minute, hour } = datetime;
  return (
    formatDateComponent(hour) +
    ":" +
    formatDateComponent(minute) +
    ":" +
    formatDateComponent(second)
  );
};
