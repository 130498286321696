import axios from "axios";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useJsonContext } from "..";
import {
  ApplyImageProps,
  DynamicContextProps,
  DynamicContextProviderProps,
} from "./DynamicContext.d";

const DynamicContext = createContext<DynamicContextProps>({
  getHorizontalCommonLogoUrl: () => "",
  getDefaultFavIconUrl: () => "",
  getCustomDesktopLogoUrl: () => "",
  getCustomMobileLogoUrl: () => "",
  getFavIconUrl: () => "",
  getCssFileUrl: () => "",
  getDefaultLogo: () => "",
  applyImage: () => "",
  applyFavicon: () => "",
  applyCssFile: () => "",
  applyTitle: () => "",
});

export function DynamicContextProvider({
  children,
}: DynamicContextProviderProps) {
  const { json } = useJsonContext();
  const {
    serverUrl,
    serverCommonUrl,
    logoFallBack,
    desktopLogoName,
    mobileLogoName,
    faviconName,
    commonFaviconName,
    horizontalCommonLogoName,
    cssFileName,
    commonLogo,
    cssTagId,
    defaultLogoAlt,
    defaultTitle,
  } = json;

  // DYNAMIC IMAGES

  function checkIfImageExists(url: string, callback: (exist: boolean) => void) {
    const img = new Image();
    img.src = url;

    if (img.complete && img.width > 0) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }

  const applyImage = useCallback(
    (params: ApplyImageProps) => {
      const {
        firstUrl,
        kcContext,
        secondUrl,
        fallback,
        existing,
        setExisting,
      } = params;

      if (existing?.src) {
        return existing;
      } else {
        checkIfImageExists(firstUrl, (exist: boolean) => {
          if (exist) {
            setExisting({
              src: firstUrl,
              alt: `Logo ${kcContext?.client?.name}`,
            });
          } else {
            if (secondUrl) {
              checkIfImageExists(secondUrl, (exist: boolean) => {
                if (exist) {
                  setExisting({
                    src: secondUrl,
                    alt: `Logo ${kcContext?.realm?.name}`,
                  });
                } else {
                  setExisting({ src: fallback, alt: defaultLogoAlt });
                }
              });
            } else {
              setExisting({ src: fallback, alt: defaultLogoAlt });
            }
          }
        });
      }
    },
    [defaultLogoAlt]
  );

  const applyFavicon = useCallback(
    (firstUrl: string, secondUrl: string, fallback: string) => {
      let favicon = document.getElementById("favicon") as HTMLLinkElement;
      const defaultFavIcon = favicon !== undefined && favicon !== null;
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.rel = "icon";
      }

      checkIfImageExists(firstUrl, (exist: boolean) => {
        if (exist) {
          favicon.href = firstUrl;
        } else if (secondUrl) {
          checkIfImageExists(secondUrl, (exist: boolean) => {
            if (exist) {
              favicon.href = secondUrl;
            } else {
              favicon.href = fallback;
            }
          });
        }
        if (!defaultFavIcon) {
          document.head?.appendChild(favicon);
        }
      });
    },
    []
  );

  const applyCssFile = useCallback(
    (cssUrl: string) => {
      let cssFile = document.getElementById(cssTagId) as HTMLLinkElement;
      if (!cssFile) {
        axios
          .get(cssUrl)
          .then(() => {
            cssFile = document.createElement("link");
            cssFile.rel = "stylesheet";
            cssFile.href = cssUrl;
            cssFile.id = cssTagId;
            document.head.appendChild(cssFile);
          })
          .catch(() => {});
      }
    },
    [cssTagId]
  );

  const applyTitle = useCallback(
    (realmDisplayName: string | undefined, newTitle: string) => {
      if (newTitle && realmDisplayName) {
        const title = document.getElementsByTagName("title");
        if (title) {
          document.title = newTitle;
        } else {
          const newTitle = document.createElement("title");
          newTitle.text = realmDisplayName || defaultTitle;
          document.head.appendChild(newTitle);
        }
      }
    },
    [defaultTitle]
  );

  const getLogoUrl = useCallback(
    (name: string, realm?: string, client?: string): string => {
      if (client && realm) {
        return `${serverUrl}/${realm}/${client}/${name}`;
      } else if (realm) {
        return `${serverUrl}/${realm}/${name}`;
      }
      return `${serverUrl}/${logoFallBack}/${name}`;
    },
    [logoFallBack, serverUrl]
  );

  const getCommonLogoUrl = useCallback(
    (name: string) => `${serverCommonUrl}/${name}`,
    [serverCommonUrl]
  );

  // Exported functions
  const getHorizontalCommonLogoUrl = useCallback(
    () => getCommonLogoUrl(horizontalCommonLogoName),
    [getCommonLogoUrl, horizontalCommonLogoName]
  );

  const getCustomDesktopLogoUrl = useCallback(
    (realm: string, client?: string): string =>
      getLogoUrl(desktopLogoName, realm, client),
    [desktopLogoName, getLogoUrl]
  );

  const getCustomMobileLogoUrl = useCallback(
    (realm: string, client?: string): string =>
      getLogoUrl(mobileLogoName, realm, client),
    [mobileLogoName, getLogoUrl]
  );

  const getDefaultLogo = useCallback(
    (): string => getCommonLogoUrl(commonLogo),
    [commonLogo, getCommonLogoUrl]
  );

  const getFavIconUrl = useCallback(
    (realm: string, client?: string) => getLogoUrl(faviconName, realm, client),
    [faviconName, getLogoUrl]
  );

  const getDefaultFavIconUrl = useCallback(
    () => getCommonLogoUrl(commonFaviconName),
    [commonFaviconName, getCommonLogoUrl]
  );

  const getCssFileUrl = useCallback(
    (realm: string, client?: string) => getLogoUrl(cssFileName, realm, client),
    [cssFileName, getLogoUrl]
  );

  const value = useMemo(
    () => ({
      getHorizontalCommonLogoUrl,
      getCustomDesktopLogoUrl,
      getCustomMobileLogoUrl,
      getFavIconUrl,
      getDefaultFavIconUrl,
      getCssFileUrl,
      getDefaultLogo,
      applyImage,
      applyFavicon,
      applyCssFile,
      applyTitle,
    }),
    [
      getHorizontalCommonLogoUrl,
      getCustomDesktopLogoUrl,
      getCustomMobileLogoUrl,
      getFavIconUrl,
      getDefaultFavIconUrl,
      getCssFileUrl,
      getDefaultLogo,
      applyImage,
      applyFavicon,
      applyCssFile,
      applyTitle,
    ]
  );

  return (
    <DynamicContext.Provider value={value}>{children}</DynamicContext.Provider>
  );
}

export const useDynamicContext = () => useContext(DynamicContext);

export default DynamicContext;
