import { CssBaseline } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { WaitingScreen, kcContext } from "src/login";
import DaKcApp from "src/login/components/DaKcApp/DaKcApp";
import "./index.css";
import "./login/assets/css/login.css";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    {kcContext !== undefined ? (
      <>
        <CssBaseline />
        <DaKcApp kcContext={kcContext} />
      </>
    ) : (
      <WaitingScreen />
    )}
  </React.StrictMode>
);
