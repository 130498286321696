import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { RegisterProps } from "./Register.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const Register = memo(({ kcContext, kcProps }: RegisterProps) => {
  const { url, realm, passwordRequired, recaptchaRequired, recaptchaSiteKey } =
    kcContext;
  const { msg, msgStr } = useKcMessagesContext();
  const { getTextField, getPasswordTextField, getSubmitButton, getLink } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-register"
    >
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        <Box id="kc-form-register-firstname-wrapper">
          {getTextField({
            name: "firstName",
            id: "kc-form-register-firstname",
            control: control,
            rules: GetRules("firstName"),
          })}
        </Box>
        <Box id="kc-form-register-lastname-wrapper">
          {getTextField({
            name: "lastName",
            id: "kc-form-register-lastname",
            control: control,
            rules: GetRules("lastName"),
          })}
        </Box>
        <Box id="kc-form-register-email-wrapper">
          {getTextField({
            name: "email",
            id: "kc-form-register-email",
            control: control,
            rules: GetRules("email"),
          })}
        </Box>
        {!realm?.registrationEmailAsUsername && (
          <Box id="kc-form-register-username-wrapper">
            {getTextField({
              name: "username",
              id: "kc-form-username-email",
              control: control,
              rules: GetRules("username"),
            })}
          </Box>
        )}
        {passwordRequired && (
          <Box id="kc-form-register-password-wrapper">
            {getPasswordTextField({
              name: "password",
              id: "kc-form-register-password",
              control: control,
              rules: GetRules("password"),
            })}
          </Box>
        )}
        {passwordRequired && (
          <Box id="kc-form-register-password-confirm-wrapper">
            {getPasswordTextField({
              name: "password-confirm",
              id: "kc-form-register-password-confirm",
              control: control,
              rules: GetRules("password"),
              displayname: msgStr("passwordConfirm"),
            })}
          </Box>
        )}
        {recaptchaRequired && (
          <div className="form-group">
            <div className={getClassName("kcInputWrapperClass")}>
              <div
                className="g-recaptcha"
                data-size="compact"
                data-sitekey={recaptchaSiteKey}
              ></div>
            </div>
          </div>
        )}
        <Box id="kc-form-register-return-link-wrapper">
          {getLink({
            href: url.loginUrl,
            id: "kc-back-to-login",
            children: msg("backToLogin"),
          })}
        </Box>
        <Box className={getClassName("kcFormGroupClass")}>
          {getSubmitButton({
            kcProps: kcProps,
            message: msgStr("doRegister"),
          })}
        </Box>
      </Stack>
    </form>
  );
});

export default Register;
