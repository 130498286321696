import { Attribute } from "keycloakify/login/kcContext/KcContext";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  RegisterOptions,
  UseControllerProps,
  UseFormProps,
} from "react-hook-form";
import { rEmail } from "../../scripts";
import { useKcMessagesContext } from "../KcMessagesContext";
import {
  KcUseFormContextProps,
  KcUseFormContextProviderProps,
  RulesParams,
} from "./KcUseFormContext.d";

export const defaultFormProps: UseFormProps = {
  mode: "onSubmit",
};

const KcUseFormContext = createContext<KcUseFormContextProps>({
  getFormProps: () => defaultFormProps,
  setFormProps: () => {},
  GetRules: () => null,
  setCustomRules: () => {},
  getProfileRules: () => null,
});

export function KcUseFormContextProvider({
  children,
  customForm,
}: KcUseFormContextProviderProps) {
  const [allFormProps, setFormProps] = useState(customForm || defaultFormProps);
  const [customRules, setCustomRules] = useState(
    () => (param: RulesParams) => null
  );

  const getFormProps = useCallback(() => {
    return allFormProps;
  }, [allFormProps]);

  const { msgStr, advancedMsgStr } = useKcMessagesContext();
  const GetRules = useCallback(
    (
      param: RulesParams,
      args?: string | number
    ): UseControllerProps["rules"] | null => {
      const customFound = customRules(param);
      if (customFound === null) {
        switch (param) {
          case "totp":
            const exp = new RegExp("^[0-9]{" + args + "}$");
            return {
              required: msgStr("requiredFields"),
              pattern: {
                value: exp,
                message: advancedMsgStr(
                  "totpRequiredDigitsNumber",
                  args as string
                ),
              },
            };
          case "username":
          case "password":
          case "password-confirm":
          case "firstName":
          case "lastName":
          case "requiredField":
            return { required: msgStr("requiredFields") };
          case "email":
            return {
              required: msgStr("requiredFields"),
              pattern: {
                value: rEmail,
                message: advancedMsgStr("requiredCorrectEmail"),
              },
            };
          case "mobile_number":
            return {
              required: msgStr("requiredFields"),
              pattern: {
                value: /^0[6|7]([\s.-]*\d{2}){4}$/,
                message: advancedMsgStr("requiredCorrectPhoneNumber"),
              },
            };
        }
        return customFound;
      }
    },
    [advancedMsgStr, customRules, msgStr]
  );

  const getProfileRules = useCallback(
    (param: Attribute) => {
      const { required, validators } = param;
      const { email, length, pattern, integer, double } = validators;
      const result: RegisterOptions = {};
      if (required) {
        result.required = advancedMsgStr("requiredFields");
      }
      if (email) {
        result.pattern = {
          value: rEmail,
          message: advancedMsgStr("error-invalid-email"),
        };
      }
      if (length) {
        if (length.min && length.max) {
          result.minLength = {
            value: parseInt(length.min) || 0,
            message: advancedMsgStr(
              "error-invalid-length",
              length.min,
              length.max
            ),
          };
          result.maxLength = {
            value: parseInt(length.max) || 0,
            message: advancedMsgStr(
              "error-invalid-length",
              length.min,
              length.max
            ),
          };
        } else if (length.min) {
          result.minLength = {
            value: parseInt(length.min) || 0,
            message: advancedMsgStr(
              "error-invalid-length-too-short",
              length.min
            ),
          };
        } else if (length.max) {
          result.maxLength = {
            value: parseInt(length.max) || 0,
            message: advancedMsgStr(
              "error-invalid-length-too-long",
              length.max
            ),
          };
        }
      }
      if (pattern && !email) {
        result.pattern = {
          value: new RegExp(pattern.pattern),
          message: advancedMsgStr("error-pattern-no-match"),
        };
      }
      if (integer) {
        if (integer.max && integer.min) {
          result.max = {
            value: integer.max,
            message: advancedMsgStr(
              "error-number-out-of-range",
              integer.min,
              integer.max
            ),
          };
          result.min = {
            value: integer.min,
            message: advancedMsgStr(
              "error-number-out-of-range",
              integer.min,
              integer.max
            ),
          };
        } else if (integer.max) {
          result.max = {
            value: integer.max,
            message: advancedMsgStr(
              "error-number-out-of-range-too-big",
              integer.max
            ),
          };
        } else if (integer.min) {
          result.min = {
            value: integer.min,
            message: advancedMsgStr(
              "error-number-out-of-range-too-small",
              integer.min
            ),
          };
        }
      }
      if (double) {
        if (double.max && double.min) {
          result.max = {
            value: double.max,
            message: advancedMsgStr(
              "error-number-out-of-range",
              double.min,
              double.max
            ),
          };
          result.min = {
            value: double.min,
            message: advancedMsgStr(
              "error-number-out-of-range",
              double.min,
              double.max
            ),
          };
        } else if (double.max) {
          result.max = {
            value: double.max,
            message: advancedMsgStr(
              "error-number-out-of-range-too-big",
              double.max
            ),
          };
        } else if (double.min) {
          result.min = {
            value: double.min,
            message: advancedMsgStr(
              "error-number-out-of-range-too-small",
              double.min
            ),
          };
        }
      }
      if (validators["local-date"]) {
        result.valueAsDate = true;
      }
      return result;
    },
    [advancedMsgStr]
  );

  const value = useMemo(
    () => ({
      getFormProps,
      setFormProps,
      GetRules,
      setCustomRules,
      getProfileRules,
    }),
    [getFormProps, setFormProps, GetRules, setCustomRules, getProfileRules]
  );

  return (
    <KcUseFormContext.Provider value={value}>
      {children}
    </KcUseFormContext.Provider>
  );
}

export const useFormContext = () => useContext(KcUseFormContext);

export default KcUseFormContext;
