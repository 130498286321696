import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { WebAuthnAuthenticateProps } from "./WebAuthnAuthenticate.d";
import { webAuthnAuthenticate } from "../../scripts/webAuthn";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const WebAuthnAuthenticate = memo(
  ({ kcContext, kcProps }: WebAuthnAuthenticateProps) => {
    const { authenticators, url, shouldDisplayAuthenticators } = kcContext;
    const { advancedMsg, msgStr } = useKcMessagesContext();
    const { getTypography, getSubmitButton, getWebAuthnImage } =
      useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <Box id="kc-form-webauth" className={getClassName("kcFormClass")}>
        <form
          id="webauth"
          className={getClassName("kcFormClass")}
          action={url.loginAction}
          method="post"
        >
          <input type="hidden" id="clientDataJSON" name="clientDataJSON" />
          <input
            type="hidden"
            id="authenticatorData"
            name="authenticatorData"
          />
          <input type="hidden" id="signature" name="signature" />
          <input type="hidden" id="credentialId" name="credentialId" />
          <input type="hidden" id="userHandle" name="userHandle" />
          <input type="hidden" id="error" name="error" />
        </form>
        <Stack
          spacing={5}
          className={`${getClassName("kcFormGroupClass")} no-bottom-margin`}
        >
          {authenticators && (
            <>
              <form id="authn_select" className={getClassName("kcFormClass")}>
                {authenticators?.authenticators?.map((authenticator) => (
                  <input
                    type="hidden"
                    name="authn_use_chk"
                    value={authenticator?.credentialId}
                  />
                ))}
              </form>
              {shouldDisplayAuthenticators && (
                <>
                  {authenticators?.authenticators?.length > 1 &&
                    getTypography({
                      children: advancedMsg(
                        "webauthn-available-authenticators"
                      ),
                    })}
                  <Box className={getClassName("kcFormClass")}>
                    {authenticators?.authenticators?.map((authenticator) => (
                      <Stack
                        className={getClassName("kcSelectAuthListClass")}
                        direction={"row"}
                        spacing={3}
                      >
                        <Box
                          className={getClassName(
                            "kcSelectAuthListItemIconClass"
                          )}
                        >
                          {getWebAuthnImage({
                            kcProps: kcContext.properties,
                            authenticator: authenticator,
                          })}
                        </Box>
                        <Stack
                          className={getClassName(
                            "kcSelectAuthListItemBodyClass"
                          )}
                        >
                          <Box
                            id="kc-webauthn-authenticator-label"
                            className={getClassName(
                              "kcSelectAuthListItemHeadingClass"
                            )}
                          >
                            {getTypography({
                              children:
                                advancedMsg(authenticator.label) ||
                                authenticator.label,
                              variant: "h4",
                            })}
                          </Box>
                          {authenticator?.transports?.displayNameProperties && (
                            <Box
                              id="kc-webauthn-authenticator-transport"
                              className={getClassName(
                                "kcSelectAuthListItemDescriptionClass"
                              )}
                            >
                              {authenticator?.transports?.displayNameProperties?.map(
                                (nameProperty, index, array) => (
                                  <>
                                    <span>{advancedMsg(nameProperty)}</span>
                                    {index !== array.length - 1 && (
                                      <span>, </span>
                                    )}
                                  </>
                                )
                              )}
                              <Box
                                className={getClassName(
                                  "kcSelectAuthListItemDescriptionClass"
                                )}
                              >
                                <span id="kc-webauthn-authenticator-created-label">
                                  {advancedMsg("webauthn-createdAt-label")}
                                </span>
                                <span id="kc-webauthn-authenticator-created">
                                  {" " + authenticator?.createdAt}
                                </span>
                              </Box>
                              <Box
                                className={getClassName(
                                  "kcSelectAuthListItemFillClass"
                                )}
                              ></Box>
                            </Box>
                          )}
                        </Stack>
                      </Stack>
                    ))}
                  </Box>
                </>
              )}
            </>
          )}
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormButtonsClass")}
          >
            {getSubmitButton({
              type: "button",
              autoFocus: true,
              value: msgStr("webauthn-doAuthenticate"),
              id: "authenticateWebAuthnButton",
              onClick: () => {
                webAuthnAuthenticate(kcContext, advancedMsg);
              },
              message: msgStr("webauthn-doAuthenticate"),
              kcProps: kcProps,
            })}
          </Box>
        </Stack>
      </Box>
    );
  }
);

export default WebAuthnAuthenticate;
