import React, { memo, useRef } from "react";
import { Box, Stack } from "@mui/material";
import {
  useComponentsContext,
  useKcMessagesContext,
  useFormContext,
} from "../../contexts";
import { OtpSmsProps } from "./OtpSms.d";
import { useForm } from "react-hook-form";
import { onSubmit, stringFromDateTime } from "../../scripts";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const OtpSms = memo(({ kcContext, kcProps }: OtpSmsProps) => {
  const { client, auth, realm, url, mobile_number, cant_be_sent_before } =
    kcContext;
  const { msg, msgStr, advancedMsgStr, advancedMsg } = useKcMessagesContext();
  const {
    getTextField,
    getLink,
    getSubmitButton,
    getSecondaryButton,
    getTypography,
  } = useComponentsContext();

  const nextSmsTime: number = Date.parse(
    stringFromDateTime(cant_be_sent_before)
  );

  const [timer, setTimer] = React.useState<number>(
    (nextSmsTime - Date.now()) / 1000
  );

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const { getFormProps, GetRules } = useFormContext();
  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  const resendSms = () => {
    window.location.reload();
  };

  const timerInterval = useRef<NodeJS.Timer>();
  function startTimer() {
    timerInterval.current = setInterval(
      () => setTimer((timer) => timer - 1),
      1000
    );
  }

  React.useEffect(() => {
    if (!timerInterval.current) startTimer();
    if (timer <= 0) clearInterval(timerInterval.current);
  }, [timer]);

  return (
    <form
      id="kc-totp-login-form"
      className={getClassName("kcFormClass")}
      action={url.loginAction}
      method="post"
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
    >
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        {auth?.attemptedUsername && (
          <Box justifyContent="center" display="flex">
            {getTypography({
              children: auth.attemptedUsername,
              variant: "subtitle1",
            })}
          </Box>
        )}
        <Box className={getClassName("kcLabelWrapperClass")}>
          {advancedMsg("otp-sms-authentication.text.send-code", mobile_number)}
        </Box>
        {getTextField({
          name: "sms_code",
          id: "totp",
          control: control,
          rules: GetRules("totp", 6),
          displayname: advancedMsgStr(
            "otp-sms-authentication.text.otp-sms.prompt"
          ),
          autoComplete: "off",
          disableKCErrors: true,
        })}
        <Box className={getClassName("kcFormGroupClass")}>
          {client?.baseUrl && (
            <Box
              id="kc-form-options"
              className={getClassName("kcFormOptionsClass")}
            >
              <Box className={getClassName("kcFormOptionsWrapperClass")}>
                {getLink({
                  href: client?.baseUrl,
                  children: msg("backToLogin"),
                })}
              </Box>
              <Box className={getClassName("kcFormOptionsWrapperClass")}>
                {realm?.resetPasswordAllowed &&
                  getLink({
                    href: url.loginResetCredentialsUrl,
                    children: msg("doForgotPassword"),
                  })}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          id="kc-form-buttons"
          className={getClassName("kcFormButtonsClass")}
        >
          {getSecondaryButton({
            id: "kc-resend",
            name: "resend",
            kcProps: kcProps,
            message:
              advancedMsgStr("otp-sms-authentication.text.resend-code") +
              (timer > 0 ? ` : ${timer.toFixed(0)}s` : ""),
            onClick: resendSms,
            disabled: timer > 0,
            sx: { marginBottom: "12px" },
          })}
          <Box id="doLoginButton">
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doSubmit"),
            })}
          </Box>
        </Box>
      </Stack>
    </form>
  );
});

export default OtpSms;
