import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { LoginPasswordProps } from "./LoginPassword.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginPassword = memo(({ kcContext, kcProps }: LoginPasswordProps) => {
  const { url, realm } = kcContext;
  const { msg, msgStr } = useKcMessagesContext();
  const { getLink, getSubmitButton, getPasswordTextField } =
    useComponentsContext();
  const { getFormProps, GetRules } = useFormContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const {
    trigger,
    control,
    formState: { isValid },
  } = useForm(getFormProps());

  return (
    <form
      action={url.loginAction}
      onSubmit={(event) => onSubmit(event, isValid, trigger)}
      method="post"
      id="kc-form-login"
    >
      <Stack spacing={3} className={getClassName("kcFormGroupClass")}>
        <Box id="kc-form-login-password-wrapper">
          {getPasswordTextField({
            name: "password",
            id: "kc-form-login-password",
            control: control,
            rules: GetRules("password"),
          })}
        </Box>
        {realm?.resetPasswordAllowed && (
          <Box className={getClassName("kcFormOptionsWrapperClass")}>
            {getLink({
              href: url.loginResetCredentialsUrl,
              id: "kc-forgot-password-link",
              children: msg("doForgotPassword"),
            })}
          </Box>
        )}
        <Box
          sx={{ mt: 1, width: "100%" }}
          className={getClassName("kcFormGroupClass")}
        >
          <Box
            display="flex"
            justifyContent="center"
            id="kc-form-group-class-box"
          >
            {getSubmitButton({
              kcProps: kcProps,
              message: msgStr("doLogIn"),
            })}
          </Box>
        </Box>
      </Stack>
    </form>
  );
});

export default LoginPassword;
