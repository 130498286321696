import { KcProviderDividerProps } from "./KcProviderDivider.d";
import KcDivider from "../KcDivider";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext/KcMessagesContext";

export default function KcProviderDivider(props: KcProviderDividerProps) {
  const { advancedMsgStr } = useKcMessagesContext();

  return (
    <KcDivider
      message={advancedMsgStr("identity-provider-login-label").toUpperCase()}
      {...props}
      dividerProps={{
        id: "kc-login-provider-divider",
        ...props.dividerProps,
      }}
    />
  );
}
