import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { WebAuthnImageProps } from "./WebAuthnImage.d";

export default function WebAuthnImage(props: WebAuthnImageProps) {
  const { authenticator } = props;

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  const getIconClasses = (iconClass: string): string => {
    switch (iconClass) {
      case "kcWebAuthnDefaultIcon":
        return "ic-key";
      case "kcWebAuthnInternal":
      case "kcWebAuthnBLE":
      case "kcWebAuthnKeyIcon":
      case "kcWebAuthnUnknownIcon":
        return "ic-bluetooth";
      case "kcWebAuthnNFC":
        return "ic-wifi";
      case "kcWebAuthnUSB":
        return "lni lni-link";
      default:
        return `lni lni-${iconClass}`;
    }
  };

  return (
    <>
      <i
        className={`${getClassName("kcWebAuthnDefaultIcon")} ${getClassName(
          "kcSelectAuthListItemIconPropertyClass"
        )} ${getIconClasses(authenticator?.transports?.iconClass || "")} `}
        aria-hidden={true}
      ></i>
    </>
  );
}
