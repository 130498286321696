import React, { memo, useEffect, useState } from "react";
import { Stack, Box, Typography, Paper, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useComponentsContext,
  useJsonContext,
  useKcAttributesContext,
  useKcLanguagesContext,
  useKcMessagesContext,
} from "../../contexts";
import { KcDrawerLanguage } from "../../components/common";
import { AcceptTermsAndConditionsTemplateProps } from "./AcceptTermsAndConditionsTemplate.d";
import { CustomHeader } from "../../components";
import "../../assets/css/login.css";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import Footer from "../../components/footers/BasicFooter/BasicFooter";
import { footerKeys } from "../../components/footers/util";
import { theme } from "@docaposte-agility/da-design-system";

const AcceptTermsAndConditionsTemplate = memo(
  ({
    children,
    kcContext,
    i18n,
    ...props
  }: AcceptTermsAndConditionsTemplateProps) => {
    const { locale, realm, client } = kcContext;
    const { advancedMsgStr } = useKcMessagesContext();
    const { changeLocale, getLabelFromTag, getCurrentLanguageTag } =
      useKcLanguagesContext();
    const { getAlert } = useComponentsContext();
    const { json } = useJsonContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      applyCssFromAttribute,
      applyCss,
      applyFaviconFromAttribute,
      applyFavicon,
      applyImageFromAttribute,
      applyImage,
      keys,
      getAttributeValue,
      applyTitleFromAttribute,
      applyTitle,
      getJsonFromAttribute,
    } = useKcAttributesContext();

    const matchesSmDown = useMediaQuery("(max-width:920px)");
    const largeScreen = useMediaQuery("(min-width:1050px)");
    const matchesXsDown = useMediaQuery("(max-width:400px)");

    const [isLoading, setIsLoading] = useState(true);
    const [cssIsLoading, setCssIsLoading] = useState(true);
    const [showLanguage, setShowLanguage] = useState(false);
    const [showFooter, setShowFooter] = useState(false);

    const [mainLogo, setMainLogo] = useState<string | null>(null);
    const [backgroundImg, setBackgroundImg] = useState<string | null>(null);

    useEffect(() => {
      const attributeJson = getJsonFromAttribute();
      // CSS dynamique
      attributeJson[keys.cssUrl] && !getAttributeValue(keys.cssUrl)
        ? applyCss(attributeJson[keys.cssUrl], json.cssTagId, () =>
            setCssIsLoading(false)
          )
        : applyCssFromAttribute(json.cssTagId, () => setCssIsLoading(false));
      // Favicon dynamique
      attributeJson[keys.faviconUrl] && !getAttributeValue(keys.faviconUrl)
        ? applyFavicon(attributeJson[keys.faviconUrl])
        : applyFaviconFromAttribute();
      // Titre dynamique
      attributeJson[keys.title] && !getAttributeValue(keys.title)
        ? applyTitle(attributeJson[keys.title])
        : applyTitleFromAttribute(
            advancedMsgStr(
              "title",
              advancedMsgStr(client?.name || "") ||
                advancedMsgStr(realm?.displayName || "") ||
                "Docaposte"
            )
          );
      if (!cssIsLoading) setIsLoading(false);
      else setTimeout(() => setIsLoading(false), 3000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [json, cssIsLoading]);

    useEffect(() => {
      if (!isLoading) {
        const attributeJson = getJsonFromAttribute();
        // Logo dynamique
        attributeJson[keys.logoUrl] && !getAttributeValue(keys.logoUrl)
          ? applyImage(
              attributeJson[keys.logoUrl],
              keys.defaultLogoUrl,
              mainLogo,
              setMainLogo
            )
          : applyImageFromAttribute(keys.defaultLogoUrl, mainLogo, setMainLogo);
        // Fond d'écran dynamique
        attributeJson[keys.backgroundUrl] &&
        !getAttributeValue(keys.backgroundUrl)
          ? applyImage(
              attributeJson[keys.backgroundUrl],
              keys.defaultBackgroundUrl,
              backgroundImg,
              setBackgroundImg
            )
          : applyImageFromAttribute(
              keys.defaultBackgroundUrl,
              backgroundImg,
              setBackgroundImg,
              keys.backgroundUrl
            );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, matchesSmDown, json]);

    useEffect(() => {
      setShowFooter(footerKeys.some((key) => getAttributeValue(key.url)));
    }, [isLoading, getAttributeValue]);

    const onChangeLanguageClick = (languageTag: string) =>
      changeLocale(languageTag);

    const onLanguageClick = (language: {
      url: string;
      label: string;
      languageTag: string;
    }) => {
      onChangeLanguageClick(language.languageTag);
    };

    if (isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100vh" }}
          id="waiting-container"
        >
          <CircularProgress id="waiting-progress" />
        </Box>
      );
    }
    if (matchesSmDown) {
      return (
        <Box
          sx={{
            backgroundColor: "cobalt.grey20",
            height: "100vh",
          }}
          id="mb-container"
        >
          {locale !== undefined &&
            realm?.internationalizationEnabled &&
            locale.supported?.length > 1 && (
              <KcDrawerLanguage
                supported={locale?.supported || []}
                onLanguageClick={onLanguageClick}
                open={showLanguage}
                setOpen={setShowLanguage}
              />
            )}
          <Stack
            alignItems="center"
            justifyContent="center"
            id="mb-stack-container"
          >
            <Box
              sx={{
                m: 4,
                px: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="mb-kc-logo-wrapper"
            >
              {mainLogo && (
                <img
                  id="mb-kc-logo"
                  src={mainLogo}
                  alt={getAttributeValue("logo.alt") || "Logo Docaposte"}
                  width="200"
                />
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                px: matchesXsDown ? 0 : 4,
                pb: matchesXsDown ? 0 : 4,
                backgroundColor: "cobalt.grey20",
                height: "100%",
              }}
              id="mb-paper-container"
            >
              <Paper
                elevation={1}
                sx={{
                  position: "relative",
                  p: 2,
                }}
                id="mb-paper"
              >
                {locale !== undefined &&
                  realm?.internationalizationEnabled &&
                  locale.supported?.length > 1 && (
                    <Typography
                      align="right"
                      onClick={() => setShowLanguage(true)}
                      sx={{ cursor: "pointer" }}
                      id="mb-kc-locale"
                      variant="subtitle2"
                      color={"cobalt.lynch"}
                    >
                      {getLabelFromTag(getCurrentLanguageTag())}
                    </Typography>
                  )}
                <Box sx={{ m: 2 }} id="mb-header-container">
                  <Box mb={3} id="mb-title-container">
                    <CustomHeader
                      kcContext={kcContext}
                      basicHeaderProps={{ mainTitleProps: { id: "mb-title" } }}
                      advancedHeaderProps={{
                        mainTitleProps: { id: "mb-main-title" },
                        subTitleProps: { id: "mb-sub-title" },
                      }}
                    />
                  </Box>
                  {kcContext.message?.summary !== undefined &&
                    kcContext.pageId !== "info.ftl" && (
                      <Box mb={1} id="mb-alert-container">
                        {getAlert({ message: kcContext.message, kcContext })}
                      </Box>
                    )}
                  <Box id="mb-form-container">{children}</Box>
                </Box>
              </Paper>
            </Box>
          </Stack>
          {showFooter && <Footer />}
        </Box>
      );
    }

    return (
      <Box className={getClassName("kcLoginClass")} id="desktop-container">
        {locale !== undefined &&
          realm?.internationalizationEnabled &&
          locale.supported?.length > 1 && (
            <KcDrawerLanguage
              supported={locale?.supported || []}
              onLanguageClick={onLanguageClick}
              open={showLanguage}
              setOpen={setShowLanguage}
            />
          )}
        <Box
          className={getClassName("kcHeaderClass")}
          id="kc-header"
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: showFooter ? "calc(100vh - 48px)" : "100vh",
            minWidth: "100vw",
            background: backgroundImg
              ? `url(${backgroundImg}) center center no-repeat fixed`
              : theme.palette?.cobalt.grey20,
            backgroundSize: "cover",
            margin: 0,
            padding: 4,
            outline: 0,
          }}
        >
          <Box
            sx={{
              flex: "1 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={getClassName("kcFormCardClass")}
            id="kc-form-wrapper"
          >
            <Paper
              elevation={1}
              sx={{
                position: "relative",
                flex: "0 auto",
                p: 2,
                minWidth: "785px",
              }}
            >
              {locale !== undefined &&
                realm?.internationalizationEnabled &&
                locale.supported?.length > 1 && (
                  <Typography
                    align="right"
                    onClick={() => setShowLanguage(true)}
                    sx={{ cursor: "pointer" }}
                    id="kc-locale"
                    variant="subtitle2"
                    color={"cobalt.lynch"}
                  >
                    {getLabelFromTag(getCurrentLanguageTag())}
                  </Typography>
                )}
              <Box sx={{ m: 2 }}>
                <Box mb={3}>
                  <CustomHeader
                    kcContext={kcContext}
                    basicHeaderProps={{
                      mainTitleProps: { id: "desktop-title" },
                    }}
                    advancedHeaderProps={{
                      mainTitleProps: { id: "desktop-main-title" },
                      subTitleProps: { id: "desktop-sub-title" },
                    }}
                  />
                </Box>
                {kcContext.message?.summary !== undefined && (
                  <Box mb={1}>
                    {getAlert({
                      message: kcContext.message,
                      kcContext,
                    })}
                  </Box>
                )}
                {children}
              </Box>
            </Paper>
          </Box>
          {largeScreen && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                mb: 8,
                mr: 8,
                ml: 4,
              }}
              id="kc-logo-wrapper"
            >
              {mainLogo && (
                <img
                  id="kc-logo"
                  src={mainLogo}
                  alt={getAttributeValue("logo.alt") || "Logo Docaposte"}
                  width="200"
                />
              )}
            </Box>
          )}
        </Box>
        {showFooter && <Footer />}
      </Box>
    );
  }
);

export default AcceptTermsAndConditionsTemplate;
