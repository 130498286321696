import { Controller } from "react-hook-form";
import { useComponentsContext, useKcMessagesContext } from "../../../contexts";
import { UserProfileRadiosProps } from "./UserProfileRadios.d";
import {
  Box,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { kcContext } from "../../../kcContext";

const UserProfileRadios = (props: UserProfileRadiosProps) => {
  const {
    attribute,
    control,
    formGroupProps,
    labelProps,
    containerProps,
    errorLabelProps,
    disableKCErrors,
    errorname,
    requiredChar,
    ...others
  } = props;

  const { messagesPerField } = kcContext || {};
  const { getProfileFieldLabel, getTypography } = useComponentsContext();
  const { advancedMsgStr } = useKcMessagesContext();
  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <FormControl id={`radio-group-${attribute.name}-form`}>
      {getTypography({
        variant: "subtitle2",
        children: `${
          advancedMsgStr(attribute.displayName || "") || attribute.displayName
        }${attribute.required ? requiredChar || " *" : ""}`,
        id: `radio-group-${attribute.name}-title`,
        className: getClassName("kcLabelClass"),
        color:
          !disableKCErrors &&
          messagesPerField?.existsError(errorname || attribute.name)
            ? "error"
            : undefined,
      })}
      <Controller
        name={attribute.name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            id={`radio-group-${attribute.name}`}
            defaultValue={attribute.validators?.options?.options[0]}
            {...formGroupProps}
            {...field}
          >
            <Box id={`radio-group-${attribute.name}-container`}>
              {attribute.validators?.options?.options?.map((option: string) => (
                <Box
                  key={option}
                  id={`radio-group-${attribute.name}-option-container`}
                  className={"kcInputClassRadio"}
                  {...containerProps}
                >
                  <FormControlLabel
                    id={`radio-group-option-${attribute.name}`}
                    value={option}
                    key={option}
                    control={<Radio {...others} />}
                    label={getProfileFieldLabel({
                      attribute: attribute,
                      option: option,
                    })}
                    {...labelProps}
                  />
                </Box>
              ))}
            </Box>
            {!disableKCErrors &&
              messagesPerField?.existsError(errorname || attribute.name) && (
                <Box mb={1} id={`error-label-${attribute.name}-container`}>
                  <Typography
                    id={`error-label-${attribute.name}`}
                    variant="caption"
                    color="error"
                    children={messagesPerField?.get(
                      errorname || attribute.name
                    )}
                    {...errorLabelProps}
                  />
                </Box>
              )}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default UserProfileRadios;
