import React, { useCallback, useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

import Box from "@mui/material/Box";
import { Document, Page, pdfjs } from "react-pdf";
import { Theme, useMediaQuery } from "@mui/material";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext/KcMessagesContext";
import { useComponentsContext } from "../../../contexts";
import { PDFViewerProps } from "./PDFViewer.d";

pdfjs.GlobalWorkerOptions.workerSrc = `//${window.location.hostname}/static/common/pdfjs/${pdfjs.version}/pdf.worker.js`;
window.Buffer = window.Buffer || require("buffer").Buffer;

const PDFViewer = React.memo(
  ({ file, scale, pageProps, documentProps }: PDFViewerProps): JSX.Element => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [buffer, setBuffer] = useState<File | null>(null);

    const { advancedMsgStr } = useKcMessagesContext();
    const largeScreen = useMediaQuery("(min-width:1350px)");
    const mediumScreen = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm")
    );
    const smallScreen = useMediaQuery("(max-width:520px)");

    const { getTypography, getPrimaryButton } = useComponentsContext();

    useEffect(() => {
      setBuffer(new File([Buffer.from(file, "base64")], "pdf.pdf"));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDocumentLoadSuccess = useCallback(
      ({ numPages }: { numPages: number }): void => {
        setPageNumber(1);
        setNumPages(numPages);
      },
      []
    );

    const canPrevious = useMemo(() => pageNumber !== 1, [pageNumber]);

    const handleGoPreviousPage = useCallback(() => {
      setPageNumber((_page) => _page - 1);
    }, [setPageNumber]);

    const canNext = useMemo(
      () => pageNumber < (numPages || 1),
      [pageNumber, numPages]
    );

    const handleGoNextPage = useCallback(() => {
      setPageNumber((_page) => _page + 1);
    }, [setPageNumber]);

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
        id="pdf-page-container"
      >
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          id="pdf-board-container"
        >
          {mediumScreen ? (
            getPrimaryButton({
              onClick: handleGoPreviousPage,
              disabled: !canPrevious,
              message: advancedMsgStr(
                "accept_terms_and_conditions.page.previous"
              ),
            })
          ) : (
            <IconButton
              aria-label="previous-page"
              disabled={!canPrevious}
              onClick={handleGoPreviousPage}
              sx={{ color: "cobalt.ultramarine" }}
            >
              <CobaltIcon name="chevron-left" />
            </IconButton>
          )}
          {getTypography({
            variant: "caption",
            sx: {
              flex: 2,
              textAlign: "center",
              px: 3,
              py: 2,
              minWidth: "fit-content",
            },
            children: (
              <>
                {advancedMsgStr("accept_terms_and_conditions.page")}
                <br />
                {pageNumber} / {numPages}
              </>
            ),
          })}
          {mediumScreen ? (
            getPrimaryButton({
              onClick: handleGoNextPage,
              disabled: !canNext,
              message: advancedMsgStr("accept_terms_and_conditions.page.next"),
            })
          ) : (
            <IconButton
              aria-label="next-page"
              onClick={handleGoNextPage}
              disabled={!canNext}
              sx={{ color: "cobalt.ultramarine" }}
            >
              <CobaltIcon name="chevron-right" />
            </IconButton>
          )}
        </Box>
        <Box
          height={smallScreen ? "400px" : "500px"}
          sx={numPages !== null ? { overflowY: "scroll" } : {}}
          id="pdf-container"
        >
          <Document
            file={
              buffer ||
              advancedMsgStr("accept_terms_and_conditions.loading_page") ||
              ""
            }
            onLoadSuccess={onDocumentLoadSuccess}
            error={advancedMsgStr("accept_terms_and_conditions.error_file")}
            loading={<CircularProgress />}
            noData={advancedMsgStr("accept_terms_and_conditions.no_file")}
            {...documentProps}
          >
            <Page
              scale={
                scale || mediumScreen
                  ? largeScreen
                    ? 1.3
                    : 1.1
                  : smallScreen
                  ? 0.5
                  : 0.7
              }
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              loading={advancedMsgStr(
                "accept_terms_and_conditions.loading_page"
              )}
              {...pageProps}
            />
          </Document>
        </Box>
      </Box>
    );
  }
);

export default PDFViewer;
