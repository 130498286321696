import React, { ReactElement, memo } from "react";
import { SelectAuthenticatorProps } from "./SelectAuthenticator.d";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { colors, theme } from "@docaposte-agility/da-design-system";
import {
  useComponentsContext,
  useFormContext,
  useKcMessagesContext,
} from "../../contexts";
import { ClassKey } from "keycloakify/login/TemplateProps";
import { onSubmit } from "../../scripts";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const SelectAuthenticator = memo(
  ({ kcContext, ...props }: SelectAuthenticatorProps) => {
    const { auth, url } = kcContext;
    const { getFormProps } = useFormContext();
    const { advancedMsgStr } = useKcMessagesContext();
    const { getTypography } = useComponentsContext();

    const {
      trigger,
      formState: { isValid },
    } = useForm(getFormProps());

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    function getIconFromClass(cssClass: ClassKey | undefined): ReactElement {
      let className = "";

      switch (cssClass) {
        case "kcAuthenticatorPasswordClass":
          className = "lni lni-unlock";
          break;
        case "kcAuthenticatorOTPClass":
          className = "lni lni-laptop-phone";
          break;
        case "kcAuthenticatorDefaultClass":
        default:
          className = "lni lni-list";
      }

      return (
        <Box
          component="i"
          className={className}
          color={theme.palette?.common?.black}
        />
      );
    }

    return (
      <Grid container direction="column">
        <form
          id="kc-select-credential-form"
          className={getClassName("kcFormClass")}
          action={url.loginAction}
          onSubmit={(event) => onSubmit(event, isValid, trigger)}
          method="post"
        >
          {auth?.authenticationSelections?.map((option) => (
            <>
              <Grid
                item
                key={option.authExecId}
                display="flex"
                direction="row"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  document
                    .getElementById("authexec-hidden-input")
                    ?.setAttribute("value", option.authExecId);
                  document.forms
                    .namedItem("kc-select-credential-form")
                    ?.submit();
                }}
              >
                <Grid item>{getIconFromClass(option.iconCssClass)}</Grid>
                <Grid
                  container
                  direction="column"
                  marginLeft={1}
                  alignItems="flex-start"
                >
                  <Typography
                    fontSize="1.25em"
                    color={colors.bleuDigital100}
                    maxWidth="fit-content"
                  >
                    {advancedMsgStr(option.displayName)}
                  </Typography>
                  {getTypography({
                    fontSize: "0.75em",
                    color: theme.palette?.text?.primary,
                    children: option.helpText,
                  })}
                </Grid>
                <Grid item>
                  <Box component="i" className="lni lni-chevron-right" />
                </Grid>
              </Grid>
              {auth.authenticationSelections![
                auth.authenticationSelections!.length - 1
              ].authExecId !== option.authExecId && (
                <Divider
                  sx={{
                    margin: (theme.spacing as any)(1, 0, 1, 0),
                  }}
                />
              )}
            </>
          ))}
          <Box
            component="input"
            type="hidden"
            id="authexec-hidden-input"
            name="authenticationExecution"
          />
        </form>
      </Grid>
    );
  }
);

export default SelectAuthenticator;
