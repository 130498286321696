import React, { memo } from "react";
import { RestrictedProfileReviewProps } from "./RestrictedProfileReview.d";
import {
  useComponentsContext,
  useFormContext,
  useKcMessagesContext,
} from "../../contexts";
import { useGetCustomClassName } from "../../hooks";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { Box, InputLabel, MenuItem, Select, Stack } from "@mui/material";

const RestrictedProfileReview = memo(
  ({ kcContext, i18n, kcProps }: RestrictedProfileReviewProps) => {
    const { restrictedAttributes, departmentOptions, idpUser, url } = kcContext;

    const { getSubmitButton, getTextField } = useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();
    const { advancedMsgStr } = useKcMessagesContext();

    const { getClassName } = useGetCustomClassName({});

    const {
      trigger,
      control,
      formState: { isValid },
    } = useForm(getFormProps());

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) => onSubmit(event, isValid, trigger)}
        method="post"
        id="kc-form-login"
      >
        <Stack spacing={3} className={getClassName("kcFormGroupClass")}>
          <Box id="kc-form-update-username-wrapper">
            {getTextField({
              name: "username",
              id: "kc-form-update-username",
              control: control,
              rules: GetRules("username"),
              defaultValue: idpUser?.username,
              placeholder: "",
              disabled: restrictedAttributes.includes("username"),
            })}
          </Box>
          <Box id="kc-form-update-email-wrapper">
            {getTextField({
              name: "email",
              id: "kc-form-update-email",
              control: control,
              rules: GetRules("email"),
              defaultValue: idpUser?.email,
              placeholder: "",
              disabled: restrictedAttributes.includes("email"),
            })}
          </Box>
          <Box id="kc-form-update-firstname-wrapper">
            {getTextField({
              name: "firstName",
              id: "kc-form-update-firstName",
              control: control,
              rules: GetRules("firstName"),
              defaultValue: idpUser?.firstName,
              placeholder: "",
              disabled: restrictedAttributes.includes("firstName"),
            })}
          </Box>
          <Box id="kc-form-update-lastname-wrapper">
            {getTextField({
              name: "lastName",
              id: "kc-form-update-lastName",
              control: control,
              rules: GetRules("lastName"),
              defaultValue: idpUser?.lastName,
              placeholder: "",
              disabled: restrictedAttributes.includes("lastName"),
            })}
          </Box>
          {departmentOptions.length > 0 && (
            <Box id="kc-form-update-department-wrapper">
              <InputLabel sx={{ marginBottom: "4px" }}>
                {`${advancedMsgStr(
                  "da.restricted-profile.department.label"
                )} *`}
              </InputLabel>
              <Select
                id="department"
                name="department"
                className={getClassName("kcInputClass")}
                defaultValue={departmentOptions[0]}
                fullWidth
              >
                {departmentOptions.map((dept) => (
                  <MenuItem key={dept} value={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box sx={{ mt: 1 }} className={getClassName("kcFormGroupClass")}>
            {getSubmitButton({
              kcProps: kcProps,
            })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default RestrictedProfileReview;
