import React, { memo } from "react";
import { Stack } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { LoginIdpLinkConfirmProps } from "./LoginIdpLinkConfirm.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginIdpLinkConfirm = memo(
  ({ kcContext, kcProps }: LoginIdpLinkConfirmProps) => {
    const { url, idpAlias } = kcContext;
    const { msg } = useKcMessagesContext();
    const { getSubmitButton } = useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <form id="kc-register-form" action={url.loginAction} method="post">
        <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
          {getSubmitButton({
            kcProps: kcProps,
            message: msg("confirmLinkIdpReviewProfile"),
            name: "submitAction",
            id: "updateProfile",
            value: "updateProfile",
          })}
          {getSubmitButton({
            kcProps: kcProps,
            message: msg("confirmLinkIdpContinue", idpAlias),
            name: "submitAction",
            id: "linkAccount",
            value: "linkAccount",
          })}
        </Stack>
      </form>
    );
  }
);

export default LoginIdpLinkConfirm;
