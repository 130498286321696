import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useKcMessagesContext } from "../../../contexts";
import { UserProfileCheckboxsProps } from "./UserProfileCheckboxs.d";
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography,
} from "@mui/material";
import { useComponentsContext } from "../../../contexts/ComponentsContext/ComponentsContext";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";
import { kcContext } from "../../../kcContext";

const UserProfileCheckboxs = (props: UserProfileCheckboxsProps) => {
  const {
    attribute,
    control,
    formGroupProps,
    labelProps,
    containerProps,
    errorLabelProps,
    disableKCErrors,
    errorname,
    requiredChar,
    ...others
  } = props;
  const [values, setValues] = useState<string[]>([]);

  const { getCheckbox, getProfileFieldLabel, getTypography } =
    useComponentsContext();
  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });
  const { advancedMsgStr } = useKcMessagesContext();

  const { messagesPerField } = kcContext || {};

  return (
    <FormGroup id={`checkbox-group-${attribute.name}`} {...formGroupProps}>
      {getTypography({
        variant: "subtitle2",
        children: `${
          advancedMsgStr(attribute.displayName || "") || attribute.displayName
        }${attribute.required ? requiredChar || " *" : ""}`,
        id: `checkbox-group-${attribute.name}-title`,
        className: getClassName("kcLabelClass"),
        color:
          !disableKCErrors &&
          messagesPerField?.existsError(errorname || attribute.name)
            ? "error"
            : undefined,
      })}
      {attribute.validators?.options?.options?.map(
        (option: string, index: number) => (
          <Controller
            key={option}
            name={attribute.name}
            control={control}
            defaultValue={option}
            render={() => (
              <>
                <Box
                  id={`checkbox-group-${attribute.name}-container`}
                  className="kcInputClassCheckbox"
                  {...containerProps}
                >
                  {values.includes(option) && (
                    <input
                      hidden
                      value={option}
                      name={attribute.name}
                      onChange={() => {}}
                    />
                  )}
                  <FormControlLabel
                    id={`radio-group-option-${attribute.name}`}
                    value={option}
                    key={option}
                    control={
                      getCheckbox({
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            const newValue = [...values];
                            newValue.push(option);
                            setValues(newValue);
                          } else {
                            const newValue = values.filter(
                              (val) => val !== option
                            );
                            setValues(newValue);
                          }
                        },
                        className: "kcInputClassCheckboxInput",
                        ...others,
                      }) || (
                        <Checkbox
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.checked) {
                              const newValue = [...values];
                              newValue.push(option);
                              setValues(newValue);
                            } else {
                              const newValue = values.filter(
                                (val) => val !== option
                              );
                              setValues(newValue);
                            }
                          }}
                          className={"kcInputClassCheckboxInput"}
                          {...others}
                        />
                      )
                    }
                    label={getProfileFieldLabel({
                      attribute: attribute,
                      option: option,
                    })}
                    {...labelProps}
                  />
                </Box>
                {!disableKCErrors &&
                  messagesPerField?.existsError(errorname || attribute.name) &&
                  index ===
                    (attribute?.validators?.options?.options?.length || 0) -
                      1 && (
                    <Box mb={1} id={`error-label-${attribute.name}-container`}>
                      <Typography
                        id={`error-label-${attribute.name}`}
                        variant="caption"
                        color="error"
                        children={messagesPerField?.get(
                          errorname || attribute.name
                        )}
                        {...errorLabelProps}
                      />
                    </Box>
                  )}
              </>
            )}
          />
        )
      )}
    </FormGroup>
  );
};

export default UserProfileCheckboxs;
