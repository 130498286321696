import React, { memo } from "react";
import { Stack } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { WebAuthnErrorProps } from "./WebAuthnError.d";
import { refreshPage } from "../../scripts/webAuthn";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const WebAuthnError = memo(({ kcContext, kcProps }: WebAuthnErrorProps) => {
  const { url, isAppInitiatedAction, execution } = kcContext;
  const { msgStr, kcSanitize } = useKcMessagesContext();
  const { getSubmitButton, getSecondaryButton } = useComponentsContext();

  const { getClassName } = useGetClassName({
    doUseDefaultCss: true,
    classes: undefined,
  });

  return (
    <>
      <form
        action={url.loginAction}
        method="post"
        id="kc-error-credential-form"
        className={getClassName("kcFormClass")}
      >
        <input
          type="hidden"
          id="executionValue"
          name="authenticationExecution"
        />
        <input type="hidden" id="isSetRetry" name="isSetRetry" />
      </form>
      <Stack mt={4} spacing={2}>
        {getSubmitButton({
          onClick: () => refreshPage(execution),
          type: "button",
          name: "try-again",
          id: "kc-try-again",
          value: `${kcSanitize(msgStr("doTryAgain"))}`,
          message: msgStr("doTryAgain"),
          kcProps: kcProps,
        })}
        {isAppInitiatedAction && (
          <form
            action={url.loginAction}
            method="post"
            id="kc-webauthn-settings-form"
            className={getClassName("kcFormClass")}
          >
            {getSecondaryButton({
              type: "submit",
              id: "cancelWebAuthnAIA",
              name: "cancel-aia",
              value: "true",
              message: msgStr("doCancel"),
            })}
          </form>
        )}
      </Stack>
    </>
  );
});

export default WebAuthnError;
