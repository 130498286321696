import {
  Checkbox,
  CheckboxProps,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  Switch,
  SwitchProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { createContext, useContext, useMemo, useState } from "react";
import {
  KcAlert,
  KcDivider,
  MainSubmitButton,
  MainTextField,
  MainTitle,
  PasswordTextField,
  SecondaryButton,
  KcProviderDivider,
  MainProfileTextField,
  ProviderButton,
  ProviderImage,
  RememberMe,
  MainProfileOptions,
  PasswordProfileTextField,
  OtpSelector,
  OtpSelectorItem,
  UserProfileField,
  UserProfileCheckboxs,
  UserProfileRadios,
  ProfileFieldLabel,
  PrimaryButton,
  PDFViewer,
  WebAuthnImage,
  FlowResetButton,
} from "../../components";
import { KcAlertProps } from "../../components/common/KcAlert/KcAlert.d";
import { KcDividerProps } from "../../components/common/KcDivider/KcDivider.d";
import { KcProviderDividerProps } from "../../components/common/KcProviderDivider/KcProviderDivider.d";
import { MainProfileOptionsProps } from "../../components/common/MainProfileOptions/MainProfileOptions.d";
import { MainProfileTextFieldProps } from "../../components/common/MainProfileTextField/MainProfileTextField.d";
import { MainSubmitButtonProps } from "../../components/common/MainSubmitButton/MainSubmitButton.d";
import { MainTextFieldProps } from "../../components/common/MainTextField/MainTextField.d";
import { MainTitleProps } from "../../components/common/MainTitle/MainTitle.d";
import { OtpSelectorProps } from "../../components/common/OtpSelector/OtpSelector.d";
import { OtpSelectorItemProps } from "../../components/common/OtpSelectorItem/OtpSelectorItem.d";
import { PasswordProfileTextFieldProps } from "../../components/common/PasswordProfileTextField/PasswordProfileTextField.d";
import { PasswordTextFieldProps } from "../../components/common/PasswordTextField/PasswordTextField.d";
import { PDFViewerProps } from "../../components/common/PDFViewer/PDFViewer.d";
import { PrimaryButtonProps } from "../../components/common/PrimaryButton/PrimaryButton.d";
import { ProfileFieldLabelProps } from "../../components/common/ProfileFieldLabel/ProfileFieldLabel.d";
import { ProviderButtonProps } from "../../components/common/ProviderButton/ProviderButton.d";
import { ProviderImageProps } from "../../components/common/ProviderImage/ProviderImage.d";
import { RememberMeProps } from "../../components/common/RememberMe/RememberMe.d";
import { SecondaryButtonProps } from "../../components/common/SecondaryButton/SecondaryButton.d";
import { UserProfileCheckboxsProps } from "../../components/common/UserProfileCheckboxs/UserProfileCheckboxs.d";
import { UserProfileFieldProps } from "../../components/common/UserProfileField/UserProfileField.d";
import { UserProfileRadiosProps } from "../../components/common/UserProfileRadios/UserProfileRadios.d";
import { WebAuthnImageProps } from "../../components/common/WebAuthnImage/WebAuthnImage.d";
import { FlowResetButtonProps } from "../../components/common/FlowResetButton/FlowResetButton.d";
import {
  AdvancedHeader,
  BasicHeader,
  LoginPasswordHeader,
  CustomHeader,
} from "../../components/headers";
import { AdvancedHeaderProps } from "../../components/headers/AdvancedHeader/AdvancedHeader.d";
import { BasicHeaderProps } from "../../components/headers/BasicHeader/BasicHeader.d";
import { CustomHeaderProps } from "../../components/headers/CustomHeader/CustomHeader.d";
import { LoginPasswordProps } from "../../components/headers/LoginPassword/LoginPassword.d";
import { useKcMessagesContext } from "../KcMessagesContext/KcMessagesContext";
import {
  ComponentsContextProps,
  ComponentsContextProviderProps,
  useStateComponentsProps,
} from "./ComponentsContext.d";

const defaultReturn = () => <></>;
const defaultSetterReturn = () => {};

const ComponentsContext = createContext<ComponentsContextProps>({
  // Inputs
  getTextField: defaultReturn,
  setCustomTextField: defaultSetterReturn,
  getProfileTextField: defaultReturn,
  setCustomProfileTextField: defaultSetterReturn,
  getPasswordTextField: defaultReturn,
  setCustomPasswordTextField: defaultSetterReturn,
  getPasswordProfileTextField: defaultReturn,
  setCustomPasswordProfileTextField: defaultSetterReturn,
  getSwitch: defaultReturn,
  setCustomSwitch: defaultSetterReturn,
  getCheckbox: defaultReturn,
  setCustomCheckbox: defaultSetterReturn,
  getProfileOptions: defaultReturn,
  setCustomProfileOptions: defaultSetterReturn,
  getUserProfileField: defaultReturn,
  setCustomUserProfileField: defaultSetterReturn,
  getUserProfileCheckboxs: defaultReturn,
  setCustomUserProfileCheckboxs: defaultSetterReturn,
  getUserProfileRadios: defaultReturn,
  setCustomUserProfileRadios: defaultSetterReturn,
  // Buttons
  getSubmitButton: defaultReturn,
  setCustomSubmitButton: defaultSetterReturn,
  getPrimaryButton: defaultReturn,
  setCustomPrimaryButton: defaultSetterReturn,
  getSecondaryButton: defaultReturn,
  setCustomSecondaryButton: defaultSetterReturn,
  getProviderButton: defaultReturn,
  setCustomProviderButton: defaultSetterReturn,
  getFlowResetButton: defaultReturn,
  setCustomFlowResetButton: defaultSetterReturn,
  // Floating elements
  getAlert: defaultReturn,
  setCustomAlert: defaultSetterReturn,
  // Text
  getLink: defaultReturn,
  setCustomLink: defaultSetterReturn,
  getTypography: defaultReturn,
  setCustomTypography: defaultSetterReturn,
  getMainTitle: defaultReturn,
  setCustomMainTitle: defaultSetterReturn,
  getProfileFieldLabel: defaultReturn,
  setCustomProfileFieldLabel: defaultSetterReturn,
  // Layout
  getList: defaultReturn,
  setCustomList: defaultSetterReturn,
  getListItem: defaultReturn,
  setCustomListItem: defaultSetterReturn,
  // Others
  getDivider: defaultReturn,
  setCustomDivider: defaultSetterReturn,
  getProviderDivider: defaultReturn,
  setCustomProviderDivider: defaultSetterReturn,
  getInfoDivider: defaultReturn,
  setCustomInfoDivider: defaultSetterReturn,
  getRememberMe: defaultReturn,
  setCustomRememberMe: defaultSetterReturn,
  getProviderImage: defaultReturn,
  setCustomProviderImage: defaultSetterReturn,
  getWebAuthnImage: defaultReturn,
  setCustomWebAuthnImage: defaultSetterReturn,
  getOtpSelector: defaultReturn,
  setCustomOtpSelector: defaultSetterReturn,
  getOtpSelectorItem: defaultReturn,
  setCustomOtpSelectorItem: defaultSetterReturn,
  getPDFViewer: defaultReturn,
  setCustomPDFViewer: defaultSetterReturn,
  // Headers
  getBasicHeader: defaultReturn,
  setCustomBasicHeader: defaultSetterReturn,
  getAdvancedHeader: defaultReturn,
  setCustomAdvancedHeader: defaultSetterReturn,
  getLoginPasswordHeader: defaultReturn,
  setCustomLoginPasswordHeader: defaultSetterReturn,
  getCustomHeader: defaultReturn,
  setCustomHeader: defaultSetterReturn,
});

export function ComponentsContextProvider({
  children,
}: ComponentsContextProviderProps) {
  const { advancedMsgStr } = useKcMessagesContext();
  // --- INPUTS ---
  // TEXTFIELD
  const [getTextField, setCustomTextField] = useState<
    useStateComponentsProps<MainTextFieldProps>
  >(() => (props: MainTextFieldProps) => <MainTextField {...props} />);
  // PROFILE TEXTFIELD
  const [getProfileTextField, setCustomProfileTextField] = useState<
    useStateComponentsProps<MainProfileTextFieldProps>
  >(() => (props: MainProfileTextFieldProps) => (
    <MainProfileTextField {...props} />
  ));

  // PASSWORD TEXTFIELD
  const [getPasswordTextField, setCustomPasswordTextField] = useState<
    useStateComponentsProps<PasswordTextFieldProps>
  >(() => (props: PasswordTextFieldProps) => <PasswordTextField {...props} />);

  // PROFILE PASSWORD TEXTFIELD
  const [getPasswordProfileTextField, setCustomPasswordProfileTextField] =
    useState<useStateComponentsProps<PasswordProfileTextFieldProps>>(
      () => (props: PasswordProfileTextFieldProps) =>
        <PasswordProfileTextField {...props} />
    );

  // PROFILE OPTIONS SELECTION
  const [getProfileOptions, setCustomProfileOptions] = useState<
    useStateComponentsProps<MainProfileOptionsProps>
  >(() => (props: MainProfileOptionsProps) => (
    <MainProfileOptions {...props} />
  ));

  // PROFILE FIELD
  const [getUserProfileField, setCustomUserProfileField] = useState<
    useStateComponentsProps<UserProfileFieldProps>
  >(() => (props: UserProfileFieldProps) => <UserProfileField {...props} />);

  // PROFILE CHECKBOXS
  const [getUserProfileCheckboxs, setCustomUserProfileCheckboxs] = useState<
    useStateComponentsProps<UserProfileCheckboxsProps>
  >(() => (props: UserProfileCheckboxsProps) => (
    <UserProfileCheckboxs {...props} />
  ));

  // PROFILE RADIO BUTTONS
  const [getUserProfileRadios, setCustomUserProfileRadios] = useState<
    useStateComponentsProps<UserProfileRadiosProps>
  >(() => (props: UserProfileRadiosProps) => <UserProfileRadios {...props} />);

  // --- BUTTONS ---
  // SUBMIT BUTTON
  const [getSubmitButton, setCustomSubmitButton] = useState<
    useStateComponentsProps<MainSubmitButtonProps>
  >(() => (props: MainSubmitButtonProps) => <MainSubmitButton {...props} />);

  // SUBMIT BUTTON
  const [getPrimaryButton, setCustomPrimaryButton] = useState<
    useStateComponentsProps<PrimaryButtonProps>
  >(() => (props: PrimaryButtonProps) => <PrimaryButton {...props} />);

  // SECONDARY BUTTON
  const [getSecondaryButton, setCustomSecondaryButton] = useState<
    useStateComponentsProps<SecondaryButtonProps>
  >(() => (props: SecondaryButtonProps) => <SecondaryButton {...props} />);

  // PROVIDER BUTTON
  const [getProviderButton, setCustomProviderButton] = useState<
    useStateComponentsProps<ProviderButtonProps>
  >(() => (props: ProviderButtonProps) => <ProviderButton {...props} />);

  // FLOW RESET BUTTON
  const [getFlowResetButton, setCustomFlowResetButton] = useState<
    useStateComponentsProps<FlowResetButtonProps>
  >(() => (props: FlowResetButtonProps) => <FlowResetButton {...props} />);

  // SWITCH
  const [getSwitch, setCustomSwitch] = useState<
    useStateComponentsProps<SwitchProps>
  >(() => (props: SwitchProps) => <Switch {...props} />);

  // CHECKBOX
  const [getCheckbox, setCustomCheckbox] = useState<
    useStateComponentsProps<CheckboxProps>
  >(() => (props: CheckboxProps) => <Checkbox {...props} />);

  // --- FLOATING ELEMENTS ---
  // ALERT
  const [getAlert, setCustomAlert] = useState<
    useStateComponentsProps<KcAlertProps>
  >(() => (props: KcAlertProps) => <KcAlert {...props} />);

  // --- TEXT ---
  // LINK
  const [getLink, setCustomLink] = useState<useStateComponentsProps<LinkProps>>(
    () => (props: LinkProps) => <Link {...props} />
  );

  // TYPOGRAPHY
  const [getTypography, setCustomTypography] = useState<
    useStateComponentsProps<TypographyProps>
  >(() => (props: TypographyProps) => {
    const { children, ...others } = props;
    if (typeof children === "string") {
      return (
        <Typography
          {...others}
          children={advancedMsgStr(children) || children}
        />
      );
    }
    return <Typography {...props} />;
  });

  // MAIN TITLE
  const [getMainTitle, setCustomMainTitle] = useState<
    useStateComponentsProps<MainTitleProps>
  >(() => (props: MainTitleProps) => <MainTitle {...props} />);

  // PROFILE FIELD LABEL
  const [getProfileFieldLabel, setCustomProfileFieldLabel] = useState<
    useStateComponentsProps<ProfileFieldLabelProps>
  >(() => (props: ProfileFieldLabelProps) => <ProfileFieldLabel {...props} />);

  // --- LAYOUT ---
  // LIST
  const [getList, setCustomList] = useState<useStateComponentsProps<ListProps>>(
    () => (props: ListProps) => <List {...props} />
  );

  // LIST ITEM
  const [getListItem, setCustomListItem] = useState<
    useStateComponentsProps<ListItemProps>
  >(() => (props: ListItemProps) => <ListItem {...props} />);

  // --- OTHERS
  // DIVIDER
  const [getDivider, setCustomDivider] = useState<
    useStateComponentsProps<KcDividerProps>
  >(() => (props: KcDividerProps) => <KcDivider {...props} />);
  // PROVIDER DIVIDER
  const [getProviderDivider, setCustomProviderDivider] = useState<
    useStateComponentsProps<KcProviderDividerProps>
  >(() => (props: KcProviderDividerProps) => <KcProviderDivider {...props} />);
  // INFO DIVIDER
  const [getInfoDivider, setCustomInfoDivider] = useState<
    useStateComponentsProps<KcDividerProps>
  >(() => (props: KcDividerProps) => <></>);
  // REMEMBER ME
  const [getRememberMe, setCustomRememberMe] = useState<
    useStateComponentsProps<RememberMeProps>
  >(() => (props: RememberMeProps) => <RememberMe {...props} />);
  // IMAGE PROVIDER
  const [getProviderImage, setCustomProviderImage] = useState<
    useStateComponentsProps<ProviderImageProps>
  >(() => (props: ProviderImageProps) => <ProviderImage {...props} />);
  // IMAGE WEBAUTHN
  const [getWebAuthnImage, setCustomWebAuthnImage] = useState<
    useStateComponentsProps<WebAuthnImageProps>
  >(() => (props: WebAuthnImageProps) => <WebAuthnImage {...props} />);
  // OTP MOBILE SELECTOR
  const [getOtpSelector, setCustomOtpSelector] = useState<
    useStateComponentsProps<OtpSelectorProps>
  >(() => (props: OtpSelectorProps) => <OtpSelector {...props} />);
  // OTP MOBILE SELECTOR ITEM
  const [getOtpSelectorItem, setCustomOtpSelectorItem] = useState<
    useStateComponentsProps<OtpSelectorItemProps>
  >(() => (props: OtpSelectorItemProps) => <OtpSelectorItem {...props} />);
  // PDFVIEWER
  const [getPDFViewer, setCustomPDFViewer] = useState<
    useStateComponentsProps<PDFViewerProps>
  >(() => (props: PDFViewerProps) => <PDFViewer {...props} />);

  // --- HEADERS
  // BASIC HEADER
  const [getBasicHeader, setCustomBasicHeader] = useState<
    useStateComponentsProps<BasicHeaderProps>
  >(() => (props: BasicHeaderProps) => <BasicHeader {...props} />);
  // ADVANCED HEADER
  const [getAdvancedHeader, setCustomAdvancedHeader] = useState<
    useStateComponentsProps<AdvancedHeaderProps>
  >(() => (props: AdvancedHeaderProps) => <AdvancedHeader {...props} />);
  // LOGIN PASSWORD HEADER
  const [getLoginPasswordHeader, setCustomLoginPasswordHeader] = useState<
    useStateComponentsProps<LoginPasswordProps>
  >(() => (props: LoginPasswordProps) => <LoginPasswordHeader {...props} />);
  // LOGIN PASSWORD HEADER
  const [getCustomHeader, setCustomHeader] = useState<
    useStateComponentsProps<CustomHeaderProps>
  >(() => (props: CustomHeaderProps) => <CustomHeader {...props} />);

  const value = useMemo(
    () => ({
      getTextField,
      setCustomTextField,
      getProfileTextField,
      setCustomProfileTextField,
      getPasswordTextField,
      setCustomPasswordTextField,
      getPasswordProfileTextField,
      setCustomPasswordProfileTextField,
      getSwitch,
      setCustomSwitch,
      getCheckbox,
      setCustomCheckbox,
      getUserProfileCheckboxs,
      setCustomUserProfileCheckboxs,
      getProfileOptions,
      setCustomProfileOptions,
      getUserProfileField,
      setCustomUserProfileField,
      getUserProfileRadios,
      setCustomUserProfileRadios,
      getSubmitButton,
      setCustomSubmitButton,
      getPrimaryButton,
      setCustomPrimaryButton,
      getSecondaryButton,
      setCustomSecondaryButton,
      getProviderButton,
      setCustomProviderButton,
      getFlowResetButton,
      setCustomFlowResetButton,
      getAlert,
      setCustomAlert,
      getLink,
      setCustomLink,
      getTypography,
      setCustomTypography,
      getMainTitle,
      setCustomMainTitle,
      getProfileFieldLabel,
      setCustomProfileFieldLabel,
      getList,
      setCustomList,
      getListItem,
      setCustomListItem,
      getDivider,
      setCustomDivider,
      getProviderDivider,
      setCustomProviderDivider,
      getInfoDivider,
      setCustomInfoDivider,
      getRememberMe,
      setCustomRememberMe,
      getProviderImage,
      setCustomProviderImage,
      getOtpSelector,
      setCustomOtpSelector,
      getOtpSelectorItem,
      setCustomOtpSelectorItem,
      getPDFViewer,
      setCustomPDFViewer,
      getBasicHeader,
      setCustomBasicHeader,
      getAdvancedHeader,
      setCustomAdvancedHeader,
      getLoginPasswordHeader,
      setCustomLoginPasswordHeader,
      getCustomHeader,
      setCustomHeader,
      getWebAuthnImage,
      setCustomWebAuthnImage,
    }),
    [
      getTextField,
      setCustomTextField,
      getProfileTextField,
      setCustomProfileTextField,
      getPasswordTextField,
      setCustomPasswordTextField,
      getPasswordProfileTextField,
      setCustomPasswordProfileTextField,
      getSwitch,
      setCustomSwitch,
      getCheckbox,
      setCustomCheckbox,
      getUserProfileCheckboxs,
      setCustomUserProfileCheckboxs,
      getProfileOptions,
      setCustomProfileOptions,
      getUserProfileField,
      setCustomUserProfileField,
      getUserProfileRadios,
      setCustomUserProfileRadios,
      getSubmitButton,
      setCustomSubmitButton,
      getPrimaryButton,
      setCustomPrimaryButton,
      getSecondaryButton,
      setCustomSecondaryButton,
      getProviderButton,
      setCustomProviderButton,
      getFlowResetButton,
      setCustomFlowResetButton,
      getAlert,
      setCustomAlert,
      getLink,
      setCustomLink,
      getTypography,
      setCustomTypography,
      getMainTitle,
      setCustomMainTitle,
      getProfileFieldLabel,
      setCustomProfileFieldLabel,
      getList,
      setCustomList,
      getListItem,
      setCustomListItem,
      getDivider,
      setCustomDivider,
      getProviderDivider,
      setCustomProviderDivider,
      getInfoDivider,
      setCustomInfoDivider,
      getRememberMe,
      setCustomRememberMe,
      getProviderImage,
      setCustomProviderImage,
      getOtpSelector,
      setCustomOtpSelector,
      getOtpSelectorItem,
      setCustomOtpSelectorItem,
      getPDFViewer,
      setCustomPDFViewer,
      getBasicHeader,
      setCustomBasicHeader,
      getAdvancedHeader,
      setCustomAdvancedHeader,
      getLoginPasswordHeader,
      setCustomLoginPasswordHeader,
      getCustomHeader,
      setCustomHeader,
      getWebAuthnImage,
      setCustomWebAuthnImage,
    ]
  );

  return (
    <ComponentsContext.Provider value={value}>
      {children}
    </ComponentsContext.Provider>
  );
}

export const useComponentsContext = () => useContext(ComponentsContext);

export default ComponentsContext;
