import React, { createContext, useContext, useMemo, useState } from "react";
import {
  JsonContextProps,
  JsonContextProviderProps,
  JsonCustomConfigProps,
  JsonDefaultConfigProps,
} from "./JsonContext.d";

const defaultUrl = "";

const defaultConfig: JsonDefaultConfigProps = {
  logoFallBack: "docaposte",
  serverUrl: defaultUrl,
  desktopLogoName: "logo1.png",
  mobileLogoName: "logo2.png",
  faviconName: "favicon.ico",
  cssFileName: "login.css",
  commonFaviconName: "favicon.ico",
  commonLogo: "logo-docaposte.svg",
  horizontalCommonLogoName: "Logo_Docaposte_RVB_horizontale.png",
  cssTagId: "customCss",
  defaultLogoAlt: "Logo Docaposte",
  defaultTitle: "Keycloak",
  defaultBackgroundName: "background-docaposte.png",
  flagsExtension: ".svg",
};

const JsonContext = createContext<JsonContextProps>({
  json: defaultConfig,
  setJson: () => {},
  addJsonProps: () => {},
});

export function JsonContextProvider({
  children,
  customJson,
  newJson,
  kcContext,
}: JsonContextProviderProps) {
  const staticResourcesServerUrl = `${window.origin}/static`;

  const defaultConfigWithEnv: JsonDefaultConfigProps = !staticResourcesServerUrl
    ? defaultConfig
    : {
        ...defaultConfig,
        serverUrl: staticResourcesServerUrl,
      };

  const urls = {
    serverCommonUrl: `${
      (customJson || defaultConfigWithEnv)?.serverUrl
    }/common`,
  };
  const [json, setJson] = useState(
    newJson ||
      Object.assign({ ...urls, ...defaultConfigWithEnv }, customJson || {})
  );

  const addJsonProps = React.useCallback(
    (newProps: Partial<JsonCustomConfigProps>) => {
      setJson(Object.assign(json, newProps || {}));
    },
    [json]
  );

  const value = useMemo(
    () => ({
      json,
      setJson,
      addJsonProps,
    }),
    [json, setJson, addJsonProps]
  );

  return <JsonContext.Provider value={value}>{children}</JsonContext.Provider>;
}

export const useJsonContext = () => useContext(JsonContext);

export default JsonContext;
