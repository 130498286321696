import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { WebAuthnRegisterProps } from "./WebAuthnRegister.d";
import { registerSecurityKey } from "../../scripts/webAuthn";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const WebAuthnRegister = memo(
  ({ kcContext, kcProps }: WebAuthnRegisterProps) => {
    const { url, isAppInitiatedAction } = kcContext;
    const { isSetRetry } = kcContext;
    const { msg, msgStr } = useKcMessagesContext();
    const { getSubmitButton, getSecondaryButton } = useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <Box
        id="kc-form-register-webauth"
        className={getClassName("kcFormClass")}
      >
        <form
          id="register"
          className={getClassName("kcFormClass")}
          action={url.loginAction}
          method="post"
        >
          <Box className={getClassName("kcFormGroupClass")}></Box>

          <input type="hidden" id="clientDataJSON" name="clientDataJSON" />
          <input
            type="hidden"
            id="attestationObject"
            name="attestationObject"
          />
          <input
            type="hidden"
            id="publicKeyCredentialId"
            name="publicKeyCredentialId"
          />
          <input
            type="hidden"
            id="authenticatorLabel"
            name="authenticatorLabel"
          />
          <input type="hidden" id="transports" name="transports" />
          <input type="hidden" id="error" name="error" />
        </form>
        <Stack
          spacing={3}
          className={`${getClassName("kcFormGroupClass")} no-bottom-margin`}
        >
          <Box
            id="kc-form-buttons"
            className={getClassName("kcFormButtonsClass")}
          >
            {getSubmitButton({
              autoFocus: true,
              value: msgStr("webauthn-doAuthenticate"),
              id: "registerWebAuthn",
              onClick: () => {
                registerSecurityKey(kcContext, msg);
              },
              message: msgStr("doRegister"),
              kcProps: kcProps,
            })}
          </Box>
          {!isSetRetry && isAppInitiatedAction && (
            <form
              action={url.loginAction}
              className={getClassName("kcFormClass")}
              id="kc-webauthn-settings-form"
              method="post"
            >
              {getSecondaryButton({
                type: "submit",
                value: "true",
                id: "cancelWebAuthnAIA",
                message: msgStr("doCancel"),
                name: "cancel-aia",
              })}
            </form>
          )}
        </Stack>
      </Box>
    );
  }
);

export default WebAuthnRegister;
