import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useKcLanguagesContext } from "../../../contexts";
import { KcDrawerLanguageProps } from "./KcDrawerLanguage.d";

export default function KcDrawerLanguage(props: KcDrawerLanguageProps) {
  const {
    supported,
    onLanguageClick,
    open,
    setOpen,
    drawerProps,
    listProps,
    listItemProps,
    listItemTextProps,
  } = props;

  const { getLabelFromTag } = useKcLanguagesContext();

  return (
    <Drawer
      id="kc-drawer"
      open={open}
      onClose={() => setOpen(false)}
      anchor="right"
      {...drawerProps}
    >
      <List id="kc-drawer-list" {...listProps}>
        {supported?.map((lang) => (
          <ListItemButton
            key={lang.languageTag}
            id="kc-drawer-list-item"
            {...listItemProps}
            onClick={() => {
              onLanguageClick(lang);
              window.location.href = lang.url;
            }}
          >
            <ListItemText
              primary={
                <Box display="flex" alignItems={"center"}>
                  <Typography ml={1}>
                    {getLabelFromTag(lang.languageTag)}
                  </Typography>
                </Box>
              }
              {...listItemTextProps}
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
}
