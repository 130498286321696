import React, { memo } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { SecretQuestionConfigProps } from "./SecretQuestionConfig.d";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const SecretQuestionConfig = memo(
  ({ kcContext, kcProps }: SecretQuestionConfigProps) => {
    const { url, isAppInitiatedAction } = kcContext;
    const { advancedMsgStr, kcSanitize, msg } = useKcMessagesContext();
    const { getSubmitButton, getTextField, getTypography, getSecondaryButton } =
      useComponentsContext();
    const { getFormProps, GetRules } = useFormContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    const {
      control,
      watch,
      trigger,
      formState: { isValid },
    } = useForm(getFormProps());

    const secretAnswer = watch("secret_answer");
    const secretAnswerConfirm = watch("secret_answer_confirm");

    return (
      <form
        action={url.loginAction}
        onSubmit={(event) =>
          onSubmit(event, isValid, trigger, isAppInitiatedAction)
        }
        method="post"
        id="kc-totp-login-form"
        className={getClassName("kcFormClass")}
      >
        <Stack spacing={2} id="secret-question-container">
          {getTypography({
            id: "secret_question_text1",
            children: kcSanitize(
              advancedMsgStr(
                "secret_question.secret_question.setup_explanation"
              )
            ),
          })}
          <Box className={getClassName("kcFormGroupClass")}>
            {getTextField({
              name: "secret_question",
              id: "secret_question",
              control: control,
              autoComplete: "off",
              rules: GetRules("requiredField"),
              displayname: advancedMsgStr(
                "secret_question.secret_question.label"
              ),
              disableKCErrors: true,
              placeholder:
                advancedMsgStr("secret_question.secret_question.placeholder") ||
                advancedMsgStr("secret_question_value"),
            })}
          </Box>
          <Box className={getClassName("kcFormGroupClass")}>
            {getTextField({
              name: "secret_answer",
              id: "secret_answer",
              control: control,
              autoComplete: "off",
              rules: {
                ...GetRules("requiredField"),
                validate: (value: string) =>
                  value === secretAnswerConfirm ||
                  advancedMsgStr("secret_question.secret_answer_match"),
              },
              displayname: advancedMsgStr(
                "secret_question.secret_answer.label"
              ),
              disableKCErrors: true,
              placeholder:
                advancedMsgStr("secret_question.secret_answer.placeholder") ||
                advancedMsgStr("secret_question"),
            })}
          </Box>

          <Box className={getClassName("kcFormGroupClass")}>
            {getTextField({
              name: "secret_answer_confirm",
              id: "secret_answer_confirm",
              control: control,
              autoComplete: "off",
              rules: {
                ...GetRules("requiredField"),
                validate: (value: string) =>
                  value === secretAnswer ||
                  advancedMsgStr("secret_question.secret_answer_match"),
              },
              displayname: advancedMsgStr(
                "secret_question.secret_answer_confirm.label"
              ),
              disableKCErrors: true,
              placeholder:
                advancedMsgStr(
                  "secret_question.secret_answer_confirm.placeholder"
                ) || advancedMsgStr("secret_question"),
            })}
          </Box>

          <Box className={getClassName("kcFormGroupClass")}>
            <Box
              className={getClassName("kcFormOptionsClass")}
              id="kc-form-options"
            >
              <Box className={getClassName("kcFormOptionsWrapperClass")} />
            </Box>
          </Box>

          <Box
            className={getClassName("kcFormButtonsClass")}
            id="kc-form-buttons"
            display="flex"
            flexDirection="column"
            sx={{ gridGap: 16 }}
          >
            {getSubmitButton({
              id: "doLoginButton",
              name: "login",
              kcProps: kcProps,
              message: advancedMsgStr("secret_question.do_submit"),
            })}
            {isAppInitiatedAction &&
              getSecondaryButton({
                kcProps: kcProps,
                message: msg("doCancel"),
                name: "cancel-aia",
                id: "cancelTOTPBnt",
                type: "submit",
                value: "true",
              })}
          </Box>
        </Stack>
      </form>
    );
  }
);

export default SecretQuestionConfig;
