import React, { memo, useRef, useEffect } from "react";
import { Stack, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { onSubmit } from "../../scripts";
import { useKcMessagesContext } from "../../contexts";
import { useFormContext } from "../../contexts/KcUseFormContext/KcUseFormContext";
import { SamlPostFormProps } from "./SamlPostForm.d";

const SamlPostForm = memo(({ kcContext }: SamlPostFormProps) => {
  const { samlPost } = kcContext;

  const { msg } = useKcMessagesContext();
  const { getFormProps } = useFormContext();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    trigger,
    formState: { isValid },
  } = useForm(getFormProps());

  useEffect(() => {
    formRef?.current?.submit();
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100vw", height: "100vh" }}
    >
      <form
        ref={formRef}
        action={samlPost?.url}
        onSubmit={(event) => {
          onSubmit(event, isValid, trigger);
        }}
        method="post"
        name="saml-post-binding"
      >
        {samlPost?.SAMLRequest && (
          <input
            type="hidden"
            name="SAMLRequest"
            value={`${samlPost.SAMLRequest}`}
          />
        )}
        {samlPost?.SAMLResponse && (
          <input
            type="hidden"
            name="SAMLResponse"
            value={`${samlPost.SAMLResponse}`}
          />
        )}
        {samlPost?.relayState && (
          <input
            type="hidden"
            name="RelayState"
            value={`${samlPost.relayState}`}
          />
        )}
        <noscript>
          <Typography>${msg("saml.post-form.js-disabled")}</Typography>
          <Button type="submit">{msg("doContinue")}</Button>
        </noscript>
      </form>
    </Stack>
  );
});

export default SamlPostForm;
