import React, { memo } from "react";
import { Stack } from "@mui/material";
import { useComponentsContext, useKcMessagesContext } from "../../contexts";
import { LoginIdpLinkEmailProps } from "./LoginIdpLinkEmail.d";
import clsx from "clsx";
import { useGetClassName } from "keycloakify/login/lib/useGetClassName";

const LoginIdpLinkEmail = memo(
  ({ kcContext, ...props }: LoginIdpLinkEmailProps) => {
    const { url, idpAlias, brokerContext, realm, client } = kcContext;
    const { msg } = useKcMessagesContext();
    const { getTypography, getLink } = useComponentsContext();

    const { getClassName } = useGetClassName({
      doUseDefaultCss: true,
      classes: undefined,
    });

    return (
      <Stack spacing={2} className={getClassName("kcFormGroupClass")}>
        {getTypography({
          variant: "subtitle2",
          id: "instruction1",
          className: clsx("instruction"),
          children: msg(
            "emailLinkIdp1",
            idpAlias,
            brokerContext.username,
            client?.name || realm?.displayName
          ),
        })}
        {getTypography({
          variant: "subtitle2",
          id: "instruction2",
          className: clsx("instruction"),
          children: (
            <>
              {msg("emailLinkIdp2")}&nbsp;
              {getLink({
                href: url.loginAction,
                children: msg("doClickHere"),
              })}
              &nbsp;
              {msg("emailLinkIdp3")}
            </>
          ),
        })}
        {getTypography({
          variant: "subtitle2",
          id: "instruction3",
          className: clsx("instruction"),
          children: (
            <>
              {msg("emailLinkIdp4")}&nbsp;
              {getLink({
                href: url.loginAction,
                children: msg("doClickHere"),
              })}
              &nbsp;
              {msg("emailLinkIdp5")}
            </>
          ),
        })}
      </Stack>
    );
  }
);

export default LoginIdpLinkEmail;
