import { ProviderImageProps } from "./ProviderImage.d";

export default function ProviderImage(props: ProviderImageProps) {
  const { provider } = props;

  const getIconClasses = (providerId: string): string => {
    switch (providerId) {
      case "activity":
      case "airplay":
      case "alert-circle":
      case "alert-fill":
      case "alert-octagon":
      case "alert-triangle":
      case "align-center":
      case "align-justify":
      case "align-left":
      case "align-right":
      case "anchor":
      case "aperture":
      case "archive":
      case "arrow-down":
      case "arrow-down-circle":
      case "arrow-down-left":
      case "arrow-down-right":
      case "arrow-left":
      case "arrow-left-circle":
      case "arrow-right":
      case "arrow-right-circle":
      case "arrow-up":
      case "arrow-up-circle":
      case "arrow-up-left":
      case "arrow-up-right":
      case "at-sign":
      case "award":
      case "bar-chart":
      case "bar-chart-2":
      case "battery":
      case "battery-charging":
      case "bell":
      case "bell-off":
      case "bluetooth":
      case "bold":
      case "book":
      case "book-open":
      case "bookmark":
      case "box":
      case "briefcase":
      case "bullet-point":
      case "calendar":
      case "camera":
      case "camera-off":
      case "cast":
      case "check":
      case "check-circle":
      case "check-fill":
      case "check-square":
      case "checkbox-check":
      case "checkbox-minus":
      case "checkbox-x":
      case "chevron-down":
      case "chevron-left":
      case "chevron-right":
      case "chevron-up":
      case "chevrons-down":
      case "chevrons-left":
      case "chevrons-right":
      case "chevrons-up":
      case "chrome":
      case "circle":
      case "circle-fill":
      case "clipboard":
      case "clock":
      case "clock-history":
      case "cloud":
      case "cloud-drizzle":
      case "cloud-lightning":
      case "cloud-off":
      case "cloud-rain":
      case "cloud-snow":
      case "code":
      case "codepen":
      case "codesandbox":
      case "coffee":
      case "columns":
      case "command":
      case "compass":
      case "complete":
      case "copy":
      case "corner-down-left":
      case "corner-down-right":
      case "corner-left-down":
      case "corner-left-up":
      case "corner-right-down":
      case "corner-right-up":
      case "corner-up-left":
      case "corner-up-right":
      case "cpu":
      case "credit-card":
      case "crop":
      case "crosshair":
      case "database":
      case "delete":
      case "disc":
      case "divide":
      case "docaposte":
      case "dollar-sign":
      case "download":
      case "download-cloud":
      case "drag":
      case "drag-2":
      case "dribbble":
      case "droplet":
      case "edit":
      case "edit-2":
      case "edit-3":
      case "euro-sign":
      case "external-link":
      case "eye":
      case "eye-off":
      case "facebook":
      case "fast-forward":
      case "feather":
      case "figma":
      case "file":
      case "file-csv":
      case "file-jpg":
      case "file-minus":
      case "file-pdf":
      case "file-plus":
      case "file-text":
      case "file-xls":
      case "film":
      case "filter":
      case "filter-2":
      case "flag":
      case "folder":
      case "folder-minus":
      case "folder-plus":
      case "framer":
      case "frown":
      case "git-branch":
      case "git-commit":
      case "git-merge":
      case "git-pull-request":
      case "github":
      case "gitlab":
      case "globe":
      case "grid":
      case "hard-drive":
      case "hash":
      case "headphones":
      case "heart":
      case "help-circle":
      case "hexagon":
      case "home":
      case "home-2":
      case "idea":
      case "image":
      case "inbox":
      case "info":
      case "info-fill":
      case "inspect":
      case "instagram":
      case "italic":
      case "key":
      case "layers":
      case "layout":
      case "life-buoy":
      case "link":
      case "link-2":
      case "linkedin":
      case "list":
      case "loader":
      case "lock":
      case "log-in":
      case "log-out":
      case "mail":
      case "mail-cc":
      case "mail-cle":
      case "mail-lre":
      case "map":
      case "map-pin":
      case "maximize":
      case "maximize-2":
      case "meh":
      case "menu":
      case "message-circle":
      case "message-circle-dots":
      case "message-square":
      case "message-square-dots":
      case "mic":
      case "moff":
      case "minimize":
      case "minus":
      case "minus-circle":
      case "minus-square":
      case "monitor":
      case "moon":
      case "more-fill":
      case "more-horizontal":
      case "more-vertical":
      case "mouse-pointer":
      case "move":
      case "music":
      case "navigation":
      case "navigation-2":
      case "octagon":
      case "package":
      case "paperclip":
      case "pause":
      case "pause-circle":
      case "pen-tool":
      case "percent":
      case "phone":
      case "phone-call":
      case "phone-forwarded":
      case "phone-incoming":
      case "phone-missed":
      case "phone-off":
      case "phone-outgoing":
      case "pie-chart":
      case "play":
      case "play-circle":
      case "plus":
      case "plus-circle":
      case "plus-square":
      case "pocket":
      case "power":
      case "printer":
      case "radio":
      case "refresh-ccw":
      case "refresh-cw":
      case "relaunch":
      case "repeat":
      case "reverse":
      case "rewind":
      case "rocket":
      case "rotate-ccw":
      case "rotate-cw":
      case "rss":
      case "save":
      case "scissors":
      case "search":
      case "send":
      case "server":
      case "settings":
      case "share":
      case "shield":
      case "shield-off":
      case "shopping-bag":
      case "shopping-cart":
      case "shuffle":
      case "sidebar":
      case "sign":
      case "sign-2":
      case "skip-back":
      case "skip-forward":
      case "slack":
      case "slash":
      case "sliders":
      case "sliders-2":
      case "smartphone":
      case "smile":
      case "speaker":
      case "square":
      case "square-fill":
      case "star":
      case "star-fill":
      case "star-half":
      case "steps":
      case "stop-circle":
      case "sun":
      case "sunrise":
      case "sunset":
      case "tablet":
      case "tag":
      case "target":
      case "terminal":
      case "thermometer":
      case "thumbs-down":
      case "thumbs-up":
      case "toggle-left":
      case "toggle-right":
      case "tool":
      case "trash":
      case "trash-2":
      case "travel":
      case "trello":
      case "trending-down":
      case "trending-up":
      case "trending-up-2":
      case "triangle":
      case "truck":
      case "tv":
      case "twitch":
      case "twitter":
      case "type":
      case "umbrella":
      case "underline":
      case "unlock":
      case "upload":
      case "upload-cloud":
      case "user":
      case "user-card":
      case "user-check":
      case "user-minus":
      case "user-plus":
      case "user-profile":
      case "user-x":
      case "users":
      case "video":
      case "video-off":
      case "voicemail":
      case "volume":
      case "volume-1":
      case "volume-2":
      case "volume-x":
      case "watch":
      case "wifi":
      case "wifi-off":
      case "wind":
      case "x":
      case "x-circle":
      case "x-fill":
      case "x-octagon":
      case "x-square":
      case "youtube":
      case "zap":
      case "zap-off":
      case "zone":
      case "zoom-in":
      case "zoom-out":
        return `ic-${providerId}`;
      // case "instagram":
      //   case "github":
      //     case "linkedin":
      //       case "facebook":
      //         case "twitter":
      case "paypal":
      case "amazon":
        return `lni lni-${providerId}-original`;
      default:
        return `lni lni-${providerId}`;
    }
  };

  switch (provider.providerId) {
    default:
      return (
        <>
          <i
            className={`kc-social-provider-logo kc-social-gray ${getIconClasses(
              provider.providerId
            )} `}
            aria-hidden={true}
          ></i>
        </>
      );
  }
}
