import { createContext, useCallback, useContext, useMemo } from "react";
import {
  KcLanguagesContextProps,
  KcLanguagesContextProviderProps,
  KcLanguageTag,
} from "./KcLanguagesContext.d";
import { KcContext as KcContextBase } from "keycloakify/login/kcContext";

const getFlag = (tag: KcLanguageTag) => {
  switch (tag) {
    case "pt-BR":
      return "BR";
    case "en":
      return "US";
    case "zh-CN":
      return "CN";
    case "cs":
      return "CZ";
    case "ja":
      return "JP";
    case "da":
      return "DK";
    default:
      return tag.toUpperCase();
  }
};

const KcLanguagesContext = createContext<KcLanguagesContextProps>({
  flags: {},
  changeLocale: () => {},
  getKcLanguageTagFromContext: () => "",
  getCurrentLanguageTag: () => "en",
  getLabelFromTag: () => "",
  getFlagFromTag: () => "",
});

export function KcLanguagesContextProvider({
  children,
  i18n,
}: KcLanguagesContextProviderProps) {
  function importAll(r: __WebpackModuleApi.RequireContext) {
    let images: KcLanguagesContextProps["flags"] = {};
    r?.keys()?.map((item: string, index: number) => {
      images[item.replace("./", "")] = r(item) as string;
      return r(item);
    });
    return images;
  }

  const flags = importAll(
    require.context("../../assets/img/flags", false, /\.(svg)$/)
  );

  const changeLocale = useCallback(
    (newLanguageTag: string) => {
      i18n?.changeLocale(newLanguageTag);
    },
    [i18n]
  );

  const getLabelFromTag = useCallback(
    (tag: string) => i18n?.labelBySupportedLanguageTag[tag] || "English",
    [i18n]
  );

  const getKcLanguageTagFromContext = useCallback((context: KcContextBase) => {
    return context.locale?.currentLanguageTag;
  }, []);

  const getCurrentLanguageTag = useCallback(() => {
    return i18n?.currentLanguageTag || "en";
  }, [i18n]);

  const getFlagFromTag = useCallback((tag: KcLanguageTag) => getFlag(tag), []);

  const value = useMemo(
    () => ({
      flags,
      changeLocale,
      getKcLanguageTagFromContext,
      getCurrentLanguageTag,
      getLabelFromTag,
      getFlagFromTag,
    }),
    [
      flags,
      getLabelFromTag,
      getKcLanguageTagFromContext,
      getCurrentLanguageTag,
      getFlagFromTag,
      changeLocale,
    ]
  );

  return (
    <KcLanguagesContext.Provider value={value}>
      {children}
    </KcLanguagesContext.Provider>
  );
}

export const useKcLanguagesContext = () => useContext(KcLanguagesContext);

export default KcLanguagesContext;
